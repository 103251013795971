import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

import { PopUpService } from '../pop-up/pop-up.service';
import { UserStoreService } from '../store/user-store.service';

@Injectable()
export class BrowserService {

  constructor(private userStore: UserStoreService, private popup: PopUpService,
    private translate: TranslateService) { }

  public onValidateBrowser(): void {
    if (this.userStore.user) {
      // Only show the warning if the user is actually logged into BH
      const agent = window.navigator.userAgent.toLowerCase();
      const browser =
        agent.indexOf('edge') > -1 ? 'Microsoft Edge'
          : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
            : agent.indexOf('opr') > -1 ? 'Opera'
              : agent.indexOf('chrome') > -1 ? 'Chrome'
                : agent.indexOf('trident') > -1 ? 'Internet Explorer'
                  : agent.indexOf('firefox') > -1 ? 'Firefox'
                    : agent.indexOf('safari') > -1 ? 'Safari'
                      : 'other';

      if (browser !== 'Chrome') {
        forkJoin([
          this.translate.get('browser-warning.wrong-browser'),
          this.translate.get(`popup.error`)
        ]).subscribe(([text, header]) => this.popup.openDialog(text, header));
      }
    }
  }
}