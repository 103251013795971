import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Renderer2
} from '@angular/core';
import { SiteContactType } from 'src/app/services/graphql.service';
import { AuditActions } from 'src/app/shared/models/audit-actions.constants';
import { CxContact, CxIncident, CxSite } from 'src/app/shared/models/cx-incident.model';
import { IncidentAuditService } from 'src/app/shared/services/incident-audit.service';

import {
  Incident,
  Phone,
  Site,
  SiteContactType as SiteContactTypeModel,
  SiteEmergencyContact
} from '../../../../../../../models';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactInfoComponent {
  @Input()
  public set site(site: Site) {
    if (site) {
      this._site = site;
      this.savedSite = site as CxSite;
      this.siteContacts = [];
      this.emergencyContacts = [];
      this.onConfigureContacts();
    }
  }
  public get site(): Site {
    return this._site as Site;
  }

  @Input()
  public set cxSite(cxSite: CxSite) {
    if (cxSite) {
      this._cxSite = cxSite;
      this.siteContacts = [];
      this.emergencyContacts = [];
      this.onConfigureContacts();
      this.endUser = true;
      this.savedSite = cxSite;
    }
  }
  public get cxSite(): CxSite {
    return this._cxSite as CxSite;
  }
  @Input()
  public incident!: Incident | CxIncident;

  public siteContacts: SiteEmergencyContact[] = [];
  public emergencyContacts: SiteEmergencyContact[] = [];
  public endUser = false;
  public savedSite!: CxSite;

  private _site?: Site;
  private _cxSite?: CxSite;

  constructor(private renderer: Renderer2, private incidentAudit: IncidentAuditService,
    private ref: ChangeDetectorRef) { }

  public onConfigureContacts(): void {
    if (this._site) {
      const contacts = (this._site.contacts as any).items;
      for (const contact of contacts) {
        if (contact.type && !contact.archived) {
          if (contact.type.includes(SiteContactTypeModel.SITE_CONTACT)) {
            this.siteContacts.push(contact);
          } else if (contact.type.includes(SiteContactTypeModel.EMERGENCY_CONTACT)) {
            this.emergencyContacts.push(contact);
          }
        }
      }
      this.emergencyContacts.sort((a, b) => a.priority - b.priority);
      this.ref.markForCheck();
    } else if (this._cxSite) {
      const contacts = this._cxSite.contacts;
      for (const contact of contacts) {
        if (contact.type && !contact.archived) {
          if (contact.type.includes(SiteContactType.SiteContact)) {
            this.siteContacts.push(contact as SiteEmergencyContact);
          } else if (contact.type.includes(SiteContactType.EmergencyContact)) {
            this.emergencyContacts.push(contact as SiteEmergencyContact);
          }
        }
      }
      this.emergencyContacts.sort((a, b) => a.priority - b.priority);
      this.ref.markForCheck();
    }

  }

  public getPhones(contact: any): Phone[] {
    if (this._site) {
      return contact.phones.items as Phone[];
    } else if (this._cxSite) {
      return contact.phones as Phone[];
    }
    return [];
  }

  public onShowContactPasscode(event$: any, contact: SiteEmergencyContact): void {
    const hasClass = event$.target.classList.contains('verbal-passcode-hide');
    if (hasClass) {
      this.incidentAudit.logAction(`${AuditActions.VIEW} ${contact.name} verbal passcode`, this.incident.id);
    }

    this.addRemovePasscodeClasses(event$, hasClass);
  }

  public onShowAlarmPasscode(event$: any): void {
    const hasClass = event$.target.classList.contains('verbal-passcode-hide');
    if (hasClass) {
      this.incidentAudit.logAction(`${AuditActions.VIEW} alarm station verbal passcode`, this.incident.id);
    }

    this.addRemovePasscodeClasses(event$, hasClass);
  }

  private addRemovePasscodeClasses(event$: any, hasClass: boolean): void {
    if (hasClass) {
      this.renderer.removeClass(event$.target, 'verbal-passcode-hide');
      this.renderer.addClass(event$.target, 'verbal-passcode-show');
    } else {
      this.renderer.addClass(event$.target, 'verbal-passcode-hide');
      this.renderer.removeClass(event$.target, 'verbal-passcode-show');
    }
    this.ref.markForCheck();
  }

}
