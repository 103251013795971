export enum ErrorCodes {
  EmailNotVerified = 'EmailNotVerified',
  UnableToResend = 'UnableToResend',
  WrongCustomerType = 'WrongCustomerType',
  ErrorOccurred = 'ErrorOccurred',
  InvalidCredentialsSiteVms = 'InvalidCredentialsSiteVms',
  UnableToRetrieve = 'UnableToRetrieve',
  FunctionInvocationError = 'FunctionInvocationError',
  UserEnabled = 'UserEnabled',
  Unauthorized = 'Unauthorized',
  UnauthorizedVMS = 'UnauthorizedVMS',
  UnableToResetMFA = 'UnableToResetMFA',
  UnauthorizedMFAReset = 'UnauthorizedMFAReset',
  UnableToLoadRequestedItem = 'UnableToLoadRequestedItem',
  UnableToGenInitRecurringInvoice = 'UnableToGenInitRecurringInvoice',
  UnableToGenInitInvoice = 'UnableToGenInitInvoice',
  PlanPriceMismatchOpMin = 'PlanPriceMismatchOpMin',
  PlanPriceMismatchLivePatrol = 'PlanPriceMismatchLivePatrol',
  CreditNoteToVoid = 'CreditNoteToVoid',
  PaymentMethodInvalidStatus = 'PaymentMethodInvalidStatus',
  ChargeDeclined = 'ChargeDeclined',
  ChargeSucceededNoTransfers = 'ChargeSucceededNoTransfers',
  ChargeSucceededNoSubUpdate = 'ChargeSucceededNoSubUpdate',
  NoRemoveDefaultPaymentMethod = 'NoRemoveDefaultPaymentMethod',
  IncidentAlreadyClosed = 'IncidentAlreadyClosed',
  IncidentNotAssigned = 'IncidentNotAssigned',
  OneActionItemRequired = 'OneActionItemRequired',
  NoEditClosedIncident = 'NoEditClosedIncident',
  MissingRequiredFields = 'MissingRequiredFields',
  SMSNotConfigured = 'SMSNotConfigured',
  NoCoverageCheck = 'NoCoverageCheck',
  UnableToCancel = 'UnableToCancel',
  ConfigurationError = 'ConfigurationError',
  DateRangeTooLarge = 'DateRangeTooLarge',
  DateRangeRequired = 'DateRangeRequired',
  AlreadyOpenDispatch = 'AlreadyOpenDispatch',
  NoSeparateForClosed = 'NoSeparateForClosed',
  MustBeRequestedToBeCanceled = 'MustBeRequestedToBeCanceled',
  UnableToFindCredentials = 'UnableToFindCredentials',
  UnableToAddNoteTrackTik = 'UnableToAddNoteTrackTik',
  UnableToGetTwilioToken = 'UnableToGetTwilioToken',
  CameraResetTooSoon = 'CameraResetTooSoon',
  SubMustBeDeactivated = 'SubMustBeDeactivated',
  ActiveInvoices = 'ActiveInvoices',
  CXPermissionsAlreadyExist = 'CXPermissionsAlreadyExist',
  InvalidDeviceType = 'InvalidDeviceType',
  InvalidInput = 'InvalidInput',
  GroupAssociatedWithAutomation = 'GroupAssociatedWithAutomation',
  VMSCustomError = 'VMSCustomError',
  InvalidSnoozeTime = 'InvalidSnoozeTime',
  CustomError = 'CustomError'
}