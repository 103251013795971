import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import DescopeSdk from '@descope/web-js-sdk';
import { CookieService } from 'ngx-cookie-service';

import { GlobalStrings } from '../models/global-strings.constants';

import { AuthDescopeService } from './auth-descope.service';
import { AuthGenericService } from './auth-generic.service';

@Injectable()
export class AuthGenericGuard implements CanActivate, CanActivateChild {
  private descopeSdk;

  constructor(private router: Router, authDescopeService: AuthDescopeService,
    private authGenericService: AuthGenericService, private cookieService: CookieService) {
    const descopeInfo = authDescopeService.getDescopeInfo();
    try {
      this.descopeSdk = DescopeSdk({
        projectId: descopeInfo.projectId,
        persistTokens: true,
        autoRefresh: true,
        baseUrl: descopeInfo.authUrl,
      });
    } catch (e) {
      // We were unable to configure the descope sdk, that's okay, not a fatal error
      // this will happen most commonly if descopeInfo.authUrl is not configured within cloudflare
    }

  }

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.descopeSdk) {
      let descopeSessionToken = this.descopeSdk.getSessionToken();

      if (descopeSessionToken) {
        // If there is a descope token at all, we need to end up in this statement
        if (descopeSessionToken && !this.descopeSdk.isJwtExpired(descopeSessionToken)) {
          return true;
        }

        try {
          await this.descopeSdk.refresh();
          descopeSessionToken = this.descopeSdk.getSessionToken();

          if (descopeSessionToken && !this.descopeSdk.isJwtExpired(descopeSessionToken)) {
            return true;
          }
        } catch (error) {
          // Error occurred while refreshing the token
        }

        void this.router.navigate(['/login'], { queryParams: { dReturnUrl: state.url } });
        return false;
      }
    }

    const oauthProfileId = this.cookieService.get(GlobalStrings.AUTH_LAST_OAUTH);
    if (oauthProfileId) {
      // The user has accessed the app using a cognito hosted flow
      const cognitoSessionToken = await this.authGenericService.onGetCognitoOauthToken();

      if (!cognitoSessionToken) {
        void this.router.navigate(['/login'], { queryParams: { dReturnUrl: state.url } });
        return false;
      }

      // If not verified return false and redirect to login
      return true;
    }

    // If we end up here we don't have a descope token or a cognito oauth token
    void this.router.navigate(['/login'], { queryParams: { dReturnUrl: state.url } });
    return false;
  }

  public async canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.canActivate(next, state);
  }
}


