/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://1ey9udcx7i.execute-api.us-east-1.amazonaws.com/simar",
      region: "us-east-1",
    },
    {
      name: "localSecurityPublicAPI",
      endpoint: "https://99boi8qcok.execute-api.us-east-1.amazonaws.com/simar",
      region: "us-east-1",
    },
    {
      name: "localguard",
      endpoint: "https://36tbxj3n33.execute-api.us-east-1.amazonaws.com/simar",
      region: "us-east-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://jmsipe2ylrenzbmwbqotckxooa.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_identity_pool_id:
    "us-east-1:5a5315d6-0e59-486d-a55a-ee3172c77412",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_6w6UmCfdW",
  aws_user_pools_web_client_id: "4bj5lkqotg4c6favilbd5mauub",
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: "8",
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "ls-storage84954-simar",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_cognito_login_mechanisms: ["PREFERRED_USERNAME"],
  aws_cognito_login_mechanism: [],
};

export default awsmobile;
