import { GridBehavior } from 'src/app/services/graphql.service';

import { UserGroup } from './user-groups.model';

export class User {
  public username!: string;
  public name!: string;
  public monitoringCenterId!: string;
  public email!: string;
  public phone!: string;
  public enabled!: boolean;
  public group!: UserGroup;
  public clientList: string[] = [];
  public integratorId?: string;
  public autoAssign?: boolean;
  public preferredLanguage!: Language;
  public gridEnabled = false;
  public gridBehavior?: GridBehavior;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}

export enum Language {
  ENGLISH = 'English',
  SPANISH = 'Spanish',
  PORTUGUESE = 'Portuguese',
  FRENCH = 'French',
  ITALIAN = 'Italian',
  CHINESE = 'Chinese',
  DUTCH = 'Dutch'
}