export const schema = {
    "models": {
        "Incident": {
            "name": "Incident",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "IncidentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "site": {
                    "name": "site",
                    "isArray": false,
                    "type": {
                        "model": "Site"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "incidentSiteId"
                    }
                },
                "incidentCustomerId": {
                    "name": "incidentCustomerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dispositionLevel": {
                    "name": "dispositionLevel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "primaryDisposition": {
                    "name": "primaryDisposition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dispositionList": {
                    "name": "dispositionList",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "closedTime": {
                    "name": "closedTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "resolvedTime": {
                    "name": "resolvedTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "expectedResolvedTime": {
                    "name": "expectedResolvedTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "billableTime": {
                    "name": "billableTime",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "assignedUsername": {
                    "name": "assignedUsername",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "assignmentHistory": {
                    "name": "assignmentHistory",
                    "isArray": true,
                    "type": {
                        "nonModel": "AssignmentHistoryItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "events": {
                    "name": "events",
                    "isArray": true,
                    "type": {
                        "model": "CameraEvent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "incidentId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "hasShareExternal": {
                    "name": "hasShareExternal",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "resolution": {
                    "name": "resolution",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "resolutionTranslated": {
                    "name": "resolutionTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "resolutionSanitized": {
                    "name": "resolutionSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "externalPartyResponse": {
                    "name": "externalPartyResponse",
                    "isArray": false,
                    "type": {
                        "nonModel": "ExternalPartyResponse"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "invoiceId": {
                    "name": "invoiceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Incidents",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "expectedResolvedTime"
                        ],
                        "queryField": "incidentByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentByMonitoringCenterAndStatus",
                        "fields": [
                            "monitoringCenterId",
                            "status",
                            "expectedResolvedTime"
                        ],
                        "queryField": "incidentByMonitoringCenterAndStatus"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentBySite",
                        "fields": [
                            "incidentSiteId",
                            "createdAt"
                        ],
                        "queryField": "incidentBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentBySiteStatus",
                        "fields": [
                            "incidentSiteId",
                            "status"
                        ],
                        "queryField": "incidentBySiteStatus"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentBySiteAndClosedTime",
                        "fields": [
                            "incidentSiteId",
                            "closedTime"
                        ],
                        "queryField": "incidentBySiteAndClosedTime"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentByStatusAndCreatedAt",
                        "fields": [
                            "status",
                            "createdAt"
                        ],
                        "queryField": "incidentByStatusAndCreatedAt"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentByMCStatusCreatedAt",
                        "fields": [
                            "monitoringCenterId",
                            "status",
                            "createdAt"
                        ],
                        "queryField": "incidentByMCStatusCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentNote": {
            "name": "IncidentNote",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "noteTranslated": {
                    "name": "noteTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "noteSanitized": {
                    "name": "noteSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "writtenBy": {
                    "name": "writtenBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "shareExternal": {
                    "name": "shareExternal",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentNotes",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "notesByIncident",
                        "fields": [
                            "incidentId",
                            "createdAt"
                        ],
                        "queryField": "notesByIncident"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentActionList": {
            "name": "IncidentActionList",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "priority": {
                    "name": "priority",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "eventType": {
                    "name": "eventType",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "disposition": {
                    "name": "disposition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tasks": {
                    "name": "tasks",
                    "isArray": true,
                    "type": {
                        "model": "ProcedureTask"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "incidentActionItemId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentActionLists",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "actionListByIncident",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "actionListByIncident"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ProcedureTask": {
            "name": "ProcedureTask",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentActionItemId": {
                    "name": "incidentActionItemId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "completed": {
                    "name": "completed",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ProcedureTasks",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "procedureTaskByIncident",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "procedureTaskByIncident"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "procedureTaskByIncidentAction",
                        "fields": [
                            "incidentActionItemId"
                        ],
                        "queryField": "procedureTaskByIncidentAction"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentBundleTime": {
            "name": "IncidentBundleTime",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "applyToAssigned": {
                    "name": "applyToAssigned",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "bundleTime": {
                    "name": "bundleTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentBundleTimes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bundleTimeByMC",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "bundleTimeByMC"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraEvent": {
            "name": "CameraEvent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsEventId": {
                    "name": "vmsEventId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsNotes": {
                    "name": "vmsNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsNotesTranslated": {
                    "name": "vmsNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsNotesSanitized": {
                    "name": "vmsNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "disposition": {
                    "name": "disposition",
                    "isArray": false,
                    "type": {
                        "enum": "Disposition"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "eventType": {
                    "name": "eventType",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "thumbnailFilename": {
                    "name": "thumbnailFilename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "triggerType": {
                    "name": "triggerType",
                    "isArray": false,
                    "type": {
                        "enum": "CameraEventTriggerType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "objectsFound": {
                    "name": "objectsFound",
                    "isArray": true,
                    "type": {
                        "enum": "CameraEventObjectType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentPriority": {
                    "name": "incidentPriority",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "camera": {
                    "name": "camera",
                    "isArray": false,
                    "type": {
                        "model": "Camera"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "cameraEventCameraId"
                    }
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "startTime": {
                    "name": "startTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "endTime": {
                    "name": "endTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "resolvedTime": {
                    "name": "resolvedTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "instantaneous": {
                    "name": "instantaneous",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "cameraEventUrl": {
                    "name": "cameraEventUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraEventUrlPublic": {
                    "name": "cameraEventUrlPublic",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsUserId": {
                    "name": "vmsUserId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ackTimestamp": {
                    "name": "ackTimestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsSourceType": {
                    "name": "vmsSourceType",
                    "isArray": false,
                    "type": {
                        "enum": "VmsSourceType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "secondaryEvents": {
                    "name": "secondaryEvents",
                    "isArray": true,
                    "type": {
                        "model": "SecondaryCameraEvent"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "cameraEventId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "CameraEvents",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraEventByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "createdAt"
                        ],
                        "queryField": "cameraEventByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraEventByVmsEventId",
                        "fields": [
                            "vmsEventId"
                        ],
                        "queryField": "cameraEventByVmsEventId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraEventByIncidentId",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "cameraEventByIncidentId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraEventBySiteId",
                        "fields": [
                            "siteId",
                            "createdAt"
                        ],
                        "queryField": "cameraEventBySiteId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraEventByEventTypeAndCreatedAt",
                        "fields": [
                            "eventType",
                            "createdAt"
                        ],
                        "queryField": "cameraEventByEventTypeAndCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SecondaryCameraEvent": {
            "name": "SecondaryCameraEvent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraEventId": {
                    "name": "cameraEventId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsNotes": {
                    "name": "vmsNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsNotesTranslated": {
                    "name": "vmsNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsNotesSanitized": {
                    "name": "vmsNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "disposition": {
                    "name": "disposition",
                    "isArray": false,
                    "type": {
                        "enum": "Disposition"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "eventType": {
                    "name": "eventType",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "vmsSourceType": {
                    "name": "vmsSourceType",
                    "isArray": false,
                    "type": {
                        "enum": "VmsSourceType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "ackTimestamp": {
                    "name": "ackTimestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsUserId": {
                    "name": "vmsUserId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SecondaryCameraEvents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "additionalEvents",
                        "fields": [
                            "cameraEventId",
                            "ackTimestamp"
                        ],
                        "queryField": "additionalEvents"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Customer": {
            "name": "Customer",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "CustomerType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": {
                        "model": "Address"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "customerAddressId"
                    }
                },
                "contact": {
                    "name": "contact",
                    "isArray": false,
                    "type": {
                        "model": "Contact"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "customerContactId"
                    }
                },
                "sites": {
                    "name": "sites",
                    "isArray": true,
                    "type": {
                        "model": "Site"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "customerId"
                    }
                },
                "cameras": {
                    "name": "cameras",
                    "isArray": true,
                    "type": {
                        "model": "Camera"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "customerId"
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DataEntryStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "criticalNotes": {
                    "name": "criticalNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "criticalNotesTranslated": {
                    "name": "criticalNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "criticalNotesSanitized": {
                    "name": "criticalNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "externalCriticalNotes": {
                    "name": "externalCriticalNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "externalCriticalNotesTranslated": {
                    "name": "externalCriticalNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "externalCriticalNotesSanitized": {
                    "name": "externalCriticalNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsCustomerId": {
                    "name": "vmsCustomerId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsMCWebhookId": {
                    "name": "vmsMCWebhookId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsMonitoringCenterRoleId": {
                    "name": "vmsMonitoringCenterRoleId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "slaL1": {
                    "name": "slaL1",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "slaL2": {
                    "name": "slaL2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "slaL3": {
                    "name": "slaL3",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "slaL4": {
                    "name": "slaL4",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sortName": {
                    "name": "sortName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "softphoneEnabled": {
                    "name": "softphoneEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "softphoneNumber": {
                    "name": "softphoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "callbackNumber": {
                    "name": "callbackNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "callbackNumberExt": {
                    "name": "callbackNumberExt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "zendeskWidgetScript": {
                    "name": "zendeskWidgetScript",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "rapidSOSIntegration": {
                    "name": "rapidSOSIntegration",
                    "isArray": false,
                    "type": {
                        "model": "RapidSOSDetails"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "customerRapidSOSIntegrationId"
                    }
                },
                "boldBIIntegration": {
                    "name": "boldBIIntegration",
                    "isArray": false,
                    "type": {
                        "model": "BoldBIDetails"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "customerBoldBIIntegrationId"
                    }
                },
                "hubspotId": {
                    "name": "hubspotId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "hasLogo": {
                    "name": "hasLogo",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "mobileLogo": {
                    "name": "mobileLogo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "invoicePrefix": {
                    "name": "invoicePrefix",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "invoiceNumber": {
                    "name": "invoiceNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "invoiceFooter": {
                    "name": "invoiceFooter",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "errorEmail": {
                    "name": "errorEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsUrl": {
                    "name": "vmsUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsAlarmUrl": {
                    "name": "vmsAlarmUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsAdminUrl": {
                    "name": "vmsAdminUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "orgDomainUrl": {
                    "name": "orgDomainUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fromEmail": {
                    "name": "fromEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Customers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "customerByIntegratorId",
                        "fields": [
                            "integratorId"
                        ],
                        "queryField": "customerByIntegrator"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "customersByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "sortName"
                        ],
                        "queryField": "customerByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "customerByVmsCustomerId",
                        "fields": [
                            "vmsCustomerId"
                        ],
                        "queryField": "customerByVmsCustomerId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "customerByInvoicePrefix",
                        "fields": [
                            "invoicePrefix"
                        ],
                        "queryField": "customerByInvoicePrefix"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "customerBySoftphone",
                        "fields": [
                            "softphoneNumber"
                        ],
                        "queryField": "customerBySoftphone"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "customersByStatusAndCreatedAt",
                        "fields": [
                            "status",
                            "createdAt"
                        ],
                        "queryField": "customersByStatusAndCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "id",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groups": [
                                    "local-security"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "VMSDetails": {
            "name": "VMSDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "apiKey": {
                    "name": "apiKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "password": {
                    "name": "password",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accessToken": {
                    "name": "accessToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshToken": {
                    "name": "refreshToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "accessTokenExpires": {
                    "name": "accessTokenExpires",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshTokenExpires": {
                    "name": "refreshTokenExpires",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "VMSDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "vmsDetailsByIntegrator",
                        "fields": [
                            "integratorId"
                        ],
                        "queryField": "vmsDetailsByIntegrator"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "vmsDetailsByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "vsmDetailsByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "DashboardDetail": {
            "name": "DashboardDetail",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "awsId": {
                    "name": "awsId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "allowedGroups": {
                    "name": "allowedGroups",
                    "isArray": true,
                    "type": {
                        "enum": "UserGroup"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DashboardDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "dashboardDetailsByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "dashboardDetailsByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groups": [
                                    "local-security"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            }
                        ]
                    }
                }
            ]
        },
        "BoldBIDetails": {
            "name": "BoldBIDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteUsername": {
                    "name": "siteUsername",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sitePassword": {
                    "name": "sitePassword",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "siteUrl": {
                    "name": "siteUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "siteDefaultDashboardId": {
                    "name": "siteDefaultDashboardId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "BoldBIDetails",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "boldBIByCustomer",
                        "fields": [
                            "customerId"
                        ],
                        "queryField": "boldBIByCustomer"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "customerId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSDetails": {
            "name": "RapidSOSDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "clientSecret": {
                    "name": "clientSecret",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "webhookEnabled": {
                    "name": "webhookEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "agencyId": {
                    "name": "agencyId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "webhookId": {
                    "name": "webhookId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSDetails",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidSOSByCustomer",
                        "fields": [
                            "customerId"
                        ],
                        "queryField": "rapidSOSByCustomer"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "customerId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Address": {
            "name": "Address",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "lineOne": {
                    "name": "lineOne",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "lineTwo": {
                    "name": "lineTwo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "zipCode": {
                    "name": "zipCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Addresses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "addressByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "addressByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groups": [
                                    "IntegratorAdmin"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Contact": {
            "name": "Contact",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phone": {
                    "name": "phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ext": {
                    "name": "ext",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customerCanShareSecurables": {
                    "name": "customerCanShareSecurables",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "customerCanLoginAdminPortal": {
                    "name": "customerCanLoginAdminPortal",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Contacts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "contactByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "contactByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Site": {
            "name": "Site",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": {
                        "model": "Address"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "siteAddressId"
                    }
                },
                "contacts": {
                    "name": "contacts",
                    "isArray": true,
                    "type": {
                        "model": "SiteEmergencyContact"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "siteId"
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DataEntryStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameras": {
                    "name": "cameras",
                    "isArray": true,
                    "type": {
                        "model": "Camera"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "siteId"
                    }
                },
                "criticalNotes": {
                    "name": "criticalNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "criticalNotesTranslated": {
                    "name": "criticalNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "criticalNotesSanitized": {
                    "name": "criticalNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "externalCriticalNotes": {
                    "name": "externalCriticalNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "externalCriticalNotesTranslated": {
                    "name": "externalCriticalNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "externalCriticalNotesSanitized": {
                    "name": "externalCriticalNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sops": {
                    "name": "sops",
                    "isArray": true,
                    "type": {
                        "model": "SOP"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "siteId"
                    }
                },
                "timezone": {
                    "name": "timezone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsSharedMonitoringRoleId": {
                    "name": "vmsSharedMonitoringRoleId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringStatus": {
                    "name": "monitoringStatus",
                    "isArray": false,
                    "type": {
                        "enum": "MonitoringStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "maintainCamerasOnMonitoringRole": {
                    "name": "maintainCamerasOnMonitoringRole",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "armingRulesConfig": {
                    "name": "armingRulesConfig",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringState": {
                    "name": "monitoringState",
                    "isArray": false,
                    "type": {
                        "enum": "MonitoringStates"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "armedState": {
                    "name": "armedState",
                    "isArray": false,
                    "type": {
                        "enum": "ArmedStates"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "alarmState": {
                    "name": "alarmState",
                    "isArray": false,
                    "type": {
                        "enum": "AlarmStates"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "supervisionEnabled": {
                    "name": "supervisionEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "triggersEnabled": {
                    "name": "triggersEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "muteAfterInteractionCount": {
                    "name": "muteAfterInteractionCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "SiteType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsCentralStation": {
                    "name": "alarmsCentralStation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsCentralStationPhone": {
                    "name": "alarmsCentralStationPhone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsCentralStationPhoneExt": {
                    "name": "alarmsCentralStationPhoneExt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsVerbalPasscode": {
                    "name": "alarmsVerbalPasscode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsCSID": {
                    "name": "alarmsCSID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsAR": {
                    "name": "alarmsAR",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsNotes": {
                    "name": "alarmsNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsNotesTranslated": {
                    "name": "alarmsNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsNotesSanitized": {
                    "name": "alarmsNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsPermitNum": {
                    "name": "alarmsPermitNum",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsPermitExpires": {
                    "name": "alarmsPermitExpires",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "integrations": {
                    "name": "integrations",
                    "isArray": true,
                    "type": {
                        "model": "SiteIntegration"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "siteId"
                    }
                },
                "autoSendToTrackTik": {
                    "name": "autoSendToTrackTik",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "hasSiteMap": {
                    "name": "hasSiteMap",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "condensedAddress": {
                    "name": "condensedAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Sites",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "siteByCustomer",
                        "fields": [
                            "customerId"
                        ],
                        "queryField": "siteByCustomer"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "siteByIntegrator",
                        "fields": [
                            "integratorId"
                        ],
                        "queryField": "siteByIntegrator"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "siteByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "siteByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "sitesByStatusAndCreatedAt",
                        "fields": [
                            "status",
                            "createdAt"
                        ],
                        "queryField": "sitesByStatusAndCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SiteSupvisdSetting": {
            "name": "SiteSupvisdSetting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "rules": {
                    "name": "rules",
                    "isArray": true,
                    "type": {
                        "model": "SupvisdEventRule"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "siteSupvisdSettingId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "SiteSupvisdSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "supvisdSettingBySiteId",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "supvisdSettingBySiteId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SupvisdEventRule": {
            "name": "SupvisdEventRule",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "muteSitePersonRule": {
                    "name": "muteSitePersonRule",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteSupvisdSettingId": {
                    "name": "siteSupvisdSettingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "ruleType": {
                    "name": "ruleType",
                    "isArray": false,
                    "type": {
                        "enum": "RuleType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "cameraEventType": {
                    "name": "cameraEventType",
                    "isArray": false,
                    "type": {
                        "enum": "CameraEventSupervisionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "disposition": {
                    "name": "disposition",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "confidence": {
                    "name": "confidence",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SupvisdEventRules",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rulesBySiteSupvisdSetting",
                        "fields": [
                            "siteSupvisdSettingId"
                        ],
                        "queryField": "rulesBySiteSupvisdSetting"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SiteDeviceStatusConfig": {
            "name": "SiteDeviceStatusConfig",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "deviceStatusEnabled": {
                    "name": "deviceStatusEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "statusFrequency": {
                    "name": "statusFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "DeviceStatusCheckSla"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "statusVerifiedTolerance": {
                    "name": "statusVerifiedTolerance",
                    "isArray": false,
                    "type": {
                        "enum": "DeviceStatusCheckSla"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "statusProperties": {
                    "name": "statusProperties",
                    "isArray": true,
                    "type": {
                        "enum": "DeviceStatusProperties"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            },
            "syncable": true,
            "pluralName": "SiteDeviceStatusConfigs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "configBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "configBySite"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Trigger": {
            "name": "Trigger",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "TriggerType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "filters": {
                    "name": "filters",
                    "isArray": true,
                    "type": {
                        "nonModel": "TriggerFilter"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "frequencyLimit": {
                    "name": "frequencyLimit",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "lastTriggered": {
                    "name": "lastTriggered",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Triggers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "triggersBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "triggersBySite"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SiteIntegration": {
            "name": "SiteIntegration",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "subType": {
                    "name": "subType",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationSubType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "subTypeList": {
                    "name": "subTypeList",
                    "isArray": true,
                    "type": {
                        "enum": "IntegrationSubType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "trackTickDetails": {
                    "name": "trackTickDetails",
                    "isArray": false,
                    "type": {
                        "model": "TrackTikDetails"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "siteIntegrationTrackTickDetailsId"
                    }
                },
                "agency": {
                    "name": "agency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "phone": {
                    "name": "phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "phoneExt": {
                    "name": "phoneExt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notesTranslated": {
                    "name": "notesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "notesSanitized": {
                    "name": "notesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SiteIntegrations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "integrationsBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "integrationsBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "integrationsByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "integrationsByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "integrationsByTypeAndSubType",
                        "fields": [
                            "type",
                            "subType"
                        ],
                        "queryField": "integrationsByTypeAndSubType"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groups": [
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SiteVMSDetails": {
            "name": "SiteVMSDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "password": {
                    "name": "password",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "apiKey": {
                    "name": "apiKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accessToken": {
                    "name": "accessToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshToken": {
                    "name": "refreshToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "accessTokenExpires": {
                    "name": "accessTokenExpires",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshTokenExpires": {
                    "name": "refreshTokenExpires",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteUserId": {
                    "name": "siteUserId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "siteUserRoleId": {
                    "name": "siteUserRoleId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SiteVMSDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "siteVMSDetailsBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "siteVMSDetailsBySite"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TrackTikDetails": {
            "name": "TrackTikDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteIntegrationId": {
                    "name": "siteIntegrationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "apiUrl": {
                    "name": "apiUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "apiUsername": {
                    "name": "apiUsername",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "apiPassword": {
                    "name": "apiPassword",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accountId": {
                    "name": "accountId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshToken": {
                    "name": "refreshToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tokenExpiry": {
                    "name": "tokenExpiry",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshTokenExpiry": {
                    "name": "refreshTokenExpiry",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "taskList": {
                    "name": "taskList",
                    "isArray": true,
                    "type": {
                        "model": "TrackTikTask"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "trackTikDetailsId"
                    }
                },
                "autoSend": {
                    "name": "autoSend",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "autoSendErrorEmail": {
                    "name": "autoSendErrorEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "configured": {
                    "name": "configured",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TrackTikDetails",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "trackTikDetailsBySiteId",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "trackTikDetailsBySiteId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TrackTikTask": {
            "name": "TrackTikTask",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "trackTikDetailsId": {
                    "name": "trackTikDetailsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "eventType": {
                    "name": "eventType",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "trackTikName": {
                    "name": "trackTikName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "trackTikId": {
                    "name": "trackTikId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "trackTikReportTemplateId": {
                    "name": "trackTikReportTemplateId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifedBy": {
                    "name": "modifedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TrackTikTasks",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "taskByTrackTikDetailsId",
                        "fields": [
                            "trackTikDetailsId"
                        ],
                        "queryField": "taskByTrackTikDetailsId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SiteEmergencyContact": {
            "name": "SiteEmergencyContact",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": true,
                    "type": {
                        "enum": "SiteContactType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "phones": {
                    "name": "phones",
                    "isArray": true,
                    "type": {
                        "model": "Phone"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "contactId"
                    }
                },
                "verbalPasscode": {
                    "name": "verbalPasscode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "priority": {
                    "name": "priority",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notesTranslated": {
                    "name": "notesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "notesSanitized": {
                    "name": "notesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SiteEmergencyContacts",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "contactsBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "contactsBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "siteContactByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "siteContactByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Phone": {
            "name": "Phone",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contactId": {
                    "name": "contactId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "PhoneType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ext": {
                    "name": "ext",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Phones",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "phonesByContact",
                        "fields": [
                            "contactId"
                        ],
                        "queryField": "phonesByContact"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "phonesByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "phonesByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "phonesByNumber",
                        "fields": [
                            "monitoringCenterId",
                            "number"
                        ],
                        "queryField": "phonesByNumber"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Camera": {
            "name": "Camera",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deviceType": {
                    "name": "deviceType",
                    "isArray": false,
                    "type": {
                        "enum": "DeviceType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DataEntryStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "vmsCameraName": {
                    "name": "vmsCameraName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "descriptionTranslated": {
                    "name": "descriptionTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "descriptionSanitized": {
                    "name": "descriptionSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsDescription": {
                    "name": "vmsDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "criticalNotes": {
                    "name": "criticalNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "criticalNotesTranslated": {
                    "name": "criticalNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "criticalNotesSanitized": {
                    "name": "criticalNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "talkdownInstructions": {
                    "name": "talkdownInstructions",
                    "isArray": false,
                    "type": {
                        "nonModel": "TalkdownInstructions"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "latitude": {
                    "name": "latitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "longitude": {
                    "name": "longitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsCameraId": {
                    "name": "vmsCameraId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsCustomerId": {
                    "name": "vmsCustomerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "testingExpiry": {
                    "name": "testingExpiry",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "armedState": {
                    "name": "armedState",
                    "isArray": false,
                    "type": {
                        "enum": "ArmedStates"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "snoozeReason": {
                    "name": "snoozeReason",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "snoozeReasonTranslated": {
                    "name": "snoozeReasonTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "snoozeReasonSanitized": {
                    "name": "snoozeReasonSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "snoozeExpiry": {
                    "name": "snoozeExpiry",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "zoneNumber": {
                    "name": "zoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "zoneDescription": {
                    "name": "zoneDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "zoneDescriptionTranslated": {
                    "name": "zoneDescriptionTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "zoneDescriptionSanitized": {
                    "name": "zoneDescriptionSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "isOnvif": {
                    "name": "isOnvif",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "videoStreamState": {
                    "name": "videoStreamState",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deviceUrlOnline": {
                    "name": "deviceUrlOnline",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsConnect": {
                    "name": "vmsConnect",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isP2P": {
                    "name": "isP2P",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isAnalyticsEnabled": {
                    "name": "isAnalyticsEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isMotionEnabled": {
                    "name": "isMotionEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "testEventReceived": {
                    "name": "testEventReceived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "healthCheckEnabled": {
                    "name": "healthCheckEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "healthCheckSLA": {
                    "name": "healthCheckSLA",
                    "isArray": false,
                    "type": {
                        "enum": "DeviceHealthCheckSla"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "healthCheckStatus": {
                    "name": "healthCheckStatus",
                    "isArray": false,
                    "type": {
                        "enum": "HealthCheckStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ignoreStatusChecks": {
                    "name": "ignoreStatusChecks",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "statusChecksProperties": {
                    "name": "statusChecksProperties",
                    "isArray": true,
                    "type": {
                        "enum": "DeviceStatusProperties"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "statusLastVerified": {
                    "name": "statusLastVerified",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "qualitySettings": {
                    "name": "qualitySettings",
                    "isArray": false,
                    "type": {
                        "model": "CameraQualitySettings"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "cameraQualitySettingsId"
                    }
                },
                "archiveDurationHours": {
                    "name": "archiveDurationHours",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "accessUrlsId": {
                    "name": "accessUrlsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "credentialsId": {
                    "name": "credentialsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "deviceBrand": {
                    "name": "deviceBrand",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deviceModel": {
                    "name": "deviceModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsPlanId": {
                    "name": "vmsPlanId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "phpRegistrationCode": {
                    "name": "phpRegistrationCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "serialNumber": {
                    "name": "serialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "p2pState": {
                    "name": "p2pState",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastPowerCycle": {
                    "name": "lastPowerCycle",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "baselineThumbnailFilename": {
                    "name": "baselineThumbnailFilename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "currentThumbnailFilename": {
                    "name": "currentThumbnailFilename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sceneChangeDetectionEnabled": {
                    "name": "sceneChangeDetectionEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "passingSceneDetection": {
                    "name": "passingSceneDetection",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "hubspotId": {
                    "name": "hubspotId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "immixMonitoring": {
                    "name": "immixMonitoring",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "hasMic": {
                    "name": "hasMic",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "hasSpeaker": {
                    "name": "hasSpeaker",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "audioGroups": {
                    "name": "audioGroups",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Cameras",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraByIntegrator",
                        "fields": [
                            "integratorId"
                        ],
                        "queryField": "cameraByIntegrator"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraByCustomer",
                        "fields": [
                            "customerId"
                        ],
                        "queryField": "cameraByCustomer"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "cameraBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "cameraByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraByVmsCameraId",
                        "fields": [
                            "vmsCameraId"
                        ],
                        "queryField": "cameraByVmsCameraId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraByMCHealthStatus",
                        "fields": [
                            "monitoringCenterId",
                            "healthCheckStatus"
                        ],
                        "queryField": "cameraByMCHealthStatus"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "camerasByHealthCheckStatusAndCreatedAt",
                        "fields": [
                            "healthCheckStatus",
                            "createdAt"
                        ],
                        "queryField": "camerasByHealthCheckStatusAndCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ObjectGroup": {
            "name": "ObjectGroup",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "nameTranslated": {
                    "name": "nameTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "nameSanitized": {
                    "name": "nameSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "groupType": {
                    "name": "groupType",
                    "isArray": false,
                    "type": {
                        "enum": "ObjectGroupType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ObjectGroups",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "groupsBySiteType",
                        "fields": [
                            "siteId",
                            "groupType"
                        ],
                        "queryField": "groupsBySiteType"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AudioCustomMessageType": {
            "name": "AudioCustomMessageType",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "nameTranslated": {
                    "name": "nameTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "nameSanitized": {
                    "name": "nameSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "transcript": {
                    "name": "transcript",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "transcriptTranslated": {
                    "name": "transcriptTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "transcriptSanitized": {
                    "name": "transcriptSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "noteTranslated": {
                    "name": "noteTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "noteSanitized": {
                    "name": "noteSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AudioCustomMessageTypes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "audioMessageTypesBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "audioMessageTypesBySite"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraQualitySettings": {
            "name": "CameraQualitySettings",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "height": {
                    "name": "height",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "videoBitRate": {
                    "name": "videoBitRate",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "frameRate": {
                    "name": "frameRate",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CameraQualitySettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraSceneDetectionSettings": {
            "name": "CameraSceneDetectionSettings",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "thresholds": {
                    "name": "thresholds",
                    "isArray": true,
                    "type": {
                        "model": "CameraSceneDetectionThreshold"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "cameraSceneDetectionSettingsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "CameraSceneDetectionSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "detSettingsByCamera",
                        "fields": [
                            "cameraId"
                        ],
                        "queryField": "detSettingsByCamera"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraSceneDetectionThreshold": {
            "name": "CameraSceneDetectionThreshold",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraSceneDetectionSettingsId": {
                    "name": "cameraSceneDetectionSettingsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "objectName": {
                    "name": "objectName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "baselineValue": {
                    "name": "baselineValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "thresholdValue": {
                    "name": "thresholdValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CameraSceneDetectionThresholds",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "detThresholdByDetId",
                        "fields": [
                            "cameraSceneDetectionSettingsId"
                        ],
                        "queryField": "detThresholdByDetId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraSceneDetectionResult": {
            "name": "CameraSceneDetectionResult",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "passing": {
                    "name": "passing",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "CameraSceneDetectionResultItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "cameraSceneDetectionResultId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "CameraSceneDetectionResults",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "detResultByCameraId",
                        "fields": [
                            "cameraId",
                            "createdAt"
                        ],
                        "queryField": "detResultByCameraId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraSceneDetectionResultItem": {
            "name": "CameraSceneDetectionResultItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraSceneDetectionResultId": {
                    "name": "cameraSceneDetectionResultId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "objectName": {
                    "name": "objectName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "thresholdValue": {
                    "name": "thresholdValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "actualValue": {
                    "name": "actualValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CameraSceneDetectionResultItems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "resultItemBySceneDetectionId",
                        "fields": [
                            "cameraSceneDetectionResultId"
                        ],
                        "queryField": "resultItemBySceneDetectionId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraCredentials": {
            "name": "CameraCredentials",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "password": {
                    "name": "password",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CameraCredentials",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraAccessUrls": {
            "name": "CameraAccessUrls",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dashStream": {
                    "name": "dashStream",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "hlsStream": {
                    "name": "hlsStream",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "http": {
                    "name": "http",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "rtmpStream": {
                    "name": "rtmpStream",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "rtsp": {
                    "name": "rtsp",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "snapShot": {
                    "name": "snapShot",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "webRtc": {
                    "name": "webRtc",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CameraAccessUrls",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraImmixConfig": {
            "name": "CameraImmixConfig",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsCameraId": {
                    "name": "vmsCameraId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsWebhookId": {
                    "name": "vmsWebhookId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "immixEmail": {
                    "name": "immixEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CameraImmixConfigs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "immixConfigByCamera",
                        "fields": [
                            "cameraId"
                        ],
                        "queryField": "immixConfigByCamera"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "immixConfigByVMSCamera",
                        "fields": [
                            "vmsCameraId"
                        ],
                        "queryField": "immixConfigByVMSCamera"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ActivityLog": {
            "name": "ActivityLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedOn": {
                    "name": "modifiedOn",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ActivityLogType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "subSystem": {
                    "name": "subSystem",
                    "isArray": false,
                    "type": {
                        "enum": "ActivityLogSubSystem"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "event": {
                    "name": "event",
                    "isArray": false,
                    "type": {
                        "enum": "ActivityLogEvent"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "reference": {
                    "name": "reference",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ActivityLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "modifiedOn"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "activityLogByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "modifiedOn"
                        ],
                        "queryField": "activityLogByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "activityLogByIntegratorId",
                        "fields": [
                            "integratorId",
                            "modifiedOn"
                        ],
                        "queryField": "activityLogByIntegratorId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "activityLogByCustomerId",
                        "fields": [
                            "customerId",
                            "modifiedOn"
                        ],
                        "queryField": "activityLogByCustomerId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "activityLogBySiteId",
                        "fields": [
                            "siteId",
                            "modifiedOn"
                        ],
                        "queryField": "activityLogBySiteId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "queries": [
                                    "get",
                                    "list"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SignalTestLog": {
            "name": "SignalTestLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsEventId": {
                    "name": "vmsEventId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "objectsFound": {
                    "name": "objectsFound",
                    "isArray": true,
                    "type": {
                        "nonModel": "CameraEventObject"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "starred": {
                    "name": "starred",
                    "isArray": false,
                    "type": {
                        "enum": "ActiveInactive"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "thumbnailFilename": {
                    "name": "thumbnailFilename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SignalTestLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "signalTestingBySiteId",
                        "fields": [
                            "siteId",
                            "createdAt"
                        ],
                        "queryField": "signalTestingBySiteId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "signalTestingByCameraId",
                        "fields": [
                            "cameraId",
                            "createdAt"
                        ],
                        "queryField": "signalTestingByCameraId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "signalTestingByCameraStarred",
                        "fields": [
                            "cameraId",
                            "starred"
                        ],
                        "queryField": "signalTestingByCameraStarred"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AuditLog": {
            "name": "AuditLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "partition": {
                    "name": "partition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedOn": {
                    "name": "modifiedOn",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "area": {
                    "name": "area",
                    "isArray": false,
                    "type": {
                        "enum": "AuditLogArea"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": {
                        "enum": "AuditLogAction"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "entityModel": {
                    "name": "entityModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "entityKey": {
                    "name": "entityKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "newImage": {
                    "name": "newImage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "oldImage": {
                    "name": "oldImage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AuditLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "modifiedOn"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "auditLogByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "modifiedOn"
                        ],
                        "queryField": "auditLogByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "auditLogByPartition",
                        "fields": [
                            "partition",
                            "modifiedOn"
                        ],
                        "queryField": "auditLogByPartition"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "queries": [
                                    "get",
                                    "list"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "Operators",
                                    "SuperOperators",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner",
                                    "Operator",
                                    "IntegratorAdmin",
                                    "Customer"
                                ],
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentActionDetail": {
            "name": "IncidentActionDetail",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentActionDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "actionsByIncident",
                        "fields": [
                            "incidentId",
                            "createdAt"
                        ],
                        "queryField": "actionsByIncident"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            }
                        ]
                    }
                }
            ]
        },
        "SOP": {
            "name": "SOP",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "eventType": {
                    "name": "eventType",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "eventSOPs": {
                    "name": "eventSOPs",
                    "isArray": true,
                    "type": {
                        "enum": "EventOp"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SOPS",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "sopBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "sopBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "sopByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "sopMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RspndrEvents": {
            "name": "RspndrEvents",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "request_id": {
                    "name": "request_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenant_id": {
                    "name": "tenant_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "error": {
                    "name": "error",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "payload": {
                    "name": "payload",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RspndrEvents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rspndrEventsSorted",
                        "fields": [
                            "modifiedBy",
                            "createdAt"
                        ],
                        "queryField": "rspndrEventsSorted"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rspndrEventsByRequestId",
                        "fields": [
                            "request_id",
                            "createdAt"
                        ],
                        "queryField": "rspndrEventsByRequestId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "queries": [
                                    "get",
                                    "list"
                                ],
                                "groups": [
                                    "Admin",
                                    "Operators",
                                    "SuperOperators",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RspndrIncident": {
            "name": "RspndrIncident",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incident_id": {
                    "name": "incident_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "address1": {
                    "name": "address1",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "address2": {
                    "name": "address2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "arrived_at": {
                    "name": "arrived_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "billed_to": {
                    "name": "billed_to",
                    "isArray": false,
                    "type": {
                        "enum": "RspndrBilledTo"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "check_list": {
                    "name": "check_list",
                    "isArray": true,
                    "type": {
                        "model": "RspndrIncidentCheckItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "rspndrIncidentId"
                    }
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "created_at": {
                    "name": "created_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_number": {
                    "name": "customer_number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dealer": {
                    "name": "dealer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "eta_at": {
                    "name": "eta_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "activities": {
                    "name": "activities",
                    "isArray": true,
                    "type": {
                        "model": "RspndrActivity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "rspndrIncidentId"
                    }
                },
                "ext_username": {
                    "name": "ext_username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "finished_at": {
                    "name": "finished_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounded_at": {
                    "name": "grounded_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_end_confirm_acked_at": {
                    "name": "grounding_end_confirm_acked_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_end_confirmed_at": {
                    "name": "grounding_end_confirmed_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_end_decline_acked_at": {
                    "name": "grounding_end_decline_acked_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_end_declined_at": {
                    "name": "grounding_end_declined_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_ended_at": {
                    "name": "grounding_ended_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_ending_at": {
                    "name": "grounding_ending_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_request_confirm_acked_at": {
                    "name": "grounding_request_confirm_acked_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_request_confirmed_at": {
                    "name": "grounding_request_confirmed_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_request_decline_acked_at": {
                    "name": "grounding_request_decline_acked_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_request_declined_at": {
                    "name": "grounding_request_declined_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_requested_at": {
                    "name": "grounding_requested_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_terminate_acked_at": {
                    "name": "grounding_terminate_acked_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "grounding_terminating_at": {
                    "name": "grounding_terminating_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "kind": {
                    "name": "kind",
                    "isArray": false,
                    "type": {
                        "enum": "RspndrServiceKind"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "lat": {
                    "name": "lat",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "lng": {
                    "name": "lng",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "master_account_number": {
                    "name": "master_account_number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modified_at": {
                    "name": "modified_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notesTranslated": {
                    "name": "notesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "notesSanitized": {
                    "name": "notesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "postal_code": {
                    "name": "postal_code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "rules": {
                    "name": "rules",
                    "isArray": true,
                    "type": {
                        "model": "RspndrIncidentGroundingRule"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "rspndrIncidentId"
                    }
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "RspndrIncidentStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "taken_at": {
                    "name": "taken_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tenant_id": {
                    "name": "tenant_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "timezone": {
                    "name": "timezone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "triggered_at": {
                    "name": "triggered_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "zone": {
                    "name": "zone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RspndrIncidents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rspndrByIncidentId",
                        "fields": [
                            "incident_id"
                        ],
                        "queryField": "rspndrByIncidentId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rspndrIncidentBySite",
                        "fields": [
                            "siteId",
                            "createdAt"
                        ],
                        "queryField": "rspndrIncidentBySite"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RspndrIncidentCheckItem": {
            "name": "RspndrIncidentCheckItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rspndrIncidentId": {
                    "name": "rspndrIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "descriptionTranslated": {
                    "name": "descriptionTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "descriptionSanitized": {
                    "name": "descriptionSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "image_required": {
                    "name": "image_required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "image_url": {
                    "name": "image_url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RspndrIncidentCheckItems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "checkItemByRspndrIncidentId",
                        "fields": [
                            "rspndrIncidentId"
                        ],
                        "queryField": "checkItemByRspndrIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RspndrIncidentGroundingRule": {
            "name": "RspndrIncidentGroundingRule",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rspndrIncidentId": {
                    "name": "rspndrIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "call_police": {
                    "name": "call_police",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "expires_at": {
                    "name": "expires_at",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "leave_when": {
                    "name": "leave_when",
                    "isArray": false,
                    "type": {
                        "enum": "RspndrLeaveWhen"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "max_duration": {
                    "name": "max_duration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RspndrIncidentGroundingRules",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "groundingRuleByRspndrIncidentId",
                        "fields": [
                            "rspndrIncidentId"
                        ],
                        "queryField": "groundingRuleByRspndrIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RspndrActivity": {
            "name": "RspndrActivity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenant_id": {
                    "name": "tenant_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "request_id": {
                    "name": "request_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rspndrIncidentId": {
                    "name": "rspndrIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "error": {
                    "name": "error",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "payload": {
                    "name": "payload",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "RspndrIncidentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notesTranslated": {
                    "name": "notesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "notesSanitized": {
                    "name": "notesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RspndrActivities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rspndrActivityByIncidentId",
                        "fields": [
                            "incidentId",
                            "createdAt"
                        ],
                        "queryField": "rspndrActivityByIncidentId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rspndrActivityByRspndrIncidentId",
                        "fields": [
                            "rspndrIncidentId",
                            "createdAt"
                        ],
                        "queryField": "rspndrActivityByRspndrIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TrackTikIncident": {
            "name": "TrackTikIncident",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TrackTikIncidentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "reportTemplateId": {
                    "name": "reportTemplateId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "taskTypeId": {
                    "name": "taskTypeId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "taskTypeName": {
                    "name": "taskTypeName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "priority": {
                    "name": "priority",
                    "isArray": false,
                    "type": {
                        "enum": "TrackTikPriority"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "additionalDataToSend": {
                    "name": "additionalDataToSend",
                    "isArray": false,
                    "type": {
                        "model": "IncidentDataToShare"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "trackTikIncidentAdditionalDataToSendId"
                    }
                },
                "trackTikId": {
                    "name": "trackTikId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "primaryCameraEventId": {
                    "name": "primaryCameraEventId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "activities": {
                    "name": "activities",
                    "isArray": true,
                    "type": {
                        "model": "TrackTikActivity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "trackTikIncidentId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TrackTikIncidents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "trackTikByIncidentId",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "trackTikByIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TrackTikActivity": {
            "name": "TrackTikActivity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "trackTikIncidentId": {
                    "name": "trackTikIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "payload": {
                    "name": "payload",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TrackTikActivities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "trackTikActivityByIncidentId",
                        "fields": [
                            "incidentId",
                            "createdAt"
                        ],
                        "queryField": "trackTikActivityByIncidentId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "trackTikActivityByTrackTikIncidentId",
                        "fields": [
                            "trackTikIncidentId",
                            "createdAt"
                        ],
                        "queryField": "trackTikActivityByTrackTikIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSIncident": {
            "name": "RapidSOSIncident",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "coverageType": {
                    "name": "coverageType",
                    "isArray": false,
                    "type": {
                        "enum": "RapidSosCoverageType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "alert_id": {
                    "name": "alert_id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "covering_psap": {
                    "name": "covering_psap",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "callflow": {
                    "name": "callflow",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "event": {
                    "name": "event",
                    "isArray": false,
                    "type": {
                        "model": "RapidSOSEventDetails"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "rapidSOSIncidentEventId"
                    }
                },
                "zone_description": {
                    "name": "zone_description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarm_permit_number": {
                    "name": "alarm_permit_number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "visually_verified": {
                    "name": "visually_verified",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "site_phone": {
                    "name": "site_phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "emergency_contacts": {
                    "name": "emergency_contacts",
                    "isArray": true,
                    "type": {
                        "model": "RapidSOSEmergencyContact"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "rapidSOSIncidentId"
                    }
                },
                "activities": {
                    "name": "activities",
                    "isArray": true,
                    "type": {
                        "model": "RapidSOSActivity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "rapidSOSIncidentId"
                    }
                },
                "attachments": {
                    "name": "attachments",
                    "isArray": true,
                    "type": {
                        "model": "RapidSOSAttachment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "rapidSOSIncidentId"
                    }
                },
                "additionalDataToSend": {
                    "name": "additionalDataToSend",
                    "isArray": false,
                    "type": {
                        "model": "IncidentDataToShare"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "rapidSOSIncidentAdditionalDataToSendId"
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "RapidSosIncidentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "instructions": {
                    "name": "instructions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "primaryCameraEventId": {
                    "name": "primaryCameraEventId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteIntegrationId": {
                    "name": "siteIntegrationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSIncidents",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidSOSByIncidentId",
                        "fields": [
                            "incidentId",
                            "createdAt"
                        ],
                        "queryField": "rapidSOSByIncidentId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidSOSByIncidentIdSiteIntegration",
                        "fields": [
                            "incidentId",
                            "siteIntegrationId"
                        ],
                        "queryField": "rapidSOSByIncidentIdSiteIntegration"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidSOSByAlertId",
                        "fields": [
                            "alert_id"
                        ],
                        "queryField": "rapidSOSByAlertId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSEventDetails": {
            "name": "RapidSOSEventDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rapidSOSIncidentId": {
                    "name": "rapidSOSIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "descriptionTranslated": {
                    "name": "descriptionTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "descriptionSanitized": {
                    "name": "descriptionSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "emergency_type": {
                    "name": "emergency_type",
                    "isArray": false,
                    "type": {
                        "enum": "RapidSosEmergencyType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "incident_time": {
                    "name": "incident_time",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "model": "RapidSOSLocation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "rapidSOSEventDetailsLocationId"
                    }
                },
                "service_provider_name": {
                    "name": "service_provider_name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "site_type": {
                    "name": "site_type",
                    "isArray": false,
                    "type": {
                        "enum": "SiteType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "source_id": {
                    "name": "source_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSEventDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSLocation": {
            "name": "RapidSOSLocation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rapidSOSIncidentId": {
                    "name": "rapidSOSIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "civic": {
                    "name": "civic",
                    "isArray": false,
                    "type": {
                        "model": "RapidSOSCivicAddress"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "rapidSOSLocationCivicId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSLocations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSCivicAddress": {
            "name": "RapidSOSCivicAddress",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rapidSOSIncidentId": {
                    "name": "rapidSOSIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "street_1": {
                    "name": "street_1",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "street_2": {
                    "name": "street_2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "zip_code": {
                    "name": "zip_code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSCivicAddresses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSEmergencyContact": {
            "name": "RapidSOSEmergencyContact",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rapidSOSIncidentId": {
                    "name": "rapidSOSIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phone": {
                    "name": "phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSEmergencyContacts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "contactsByRapidSOSIncidentId",
                        "fields": [
                            "rapidSOSIncidentId"
                        ],
                        "queryField": "contactsByRapidSOSIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSActivity": {
            "name": "RapidSOSActivity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteIntegrationId": {
                    "name": "siteIntegrationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rapidSOSIncidentId": {
                    "name": "rapidSOSIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "error": {
                    "name": "error",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "payload": {
                    "name": "payload",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "logMessage": {
                    "name": "logMessage",
                    "isArray": false,
                    "type": {
                        "model": "RapidSOSLogMessage"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "rapidSOSActivityLogMessageId"
                    }
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notesTranslated": {
                    "name": "notesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "notesSanitized": {
                    "name": "notesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSActivities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidSOSActivitySorted",
                        "fields": [
                            "modifiedBy",
                            "createdAt"
                        ],
                        "queryField": "rapidSOSActivitySorted"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidSOSActivityByIncidentId",
                        "fields": [
                            "incidentId",
                            "createdAt"
                        ],
                        "queryField": "rapidSOSActivityByIncidentId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidActivityByRapidSOSIncidentId",
                        "fields": [
                            "rapidSOSIncidentId",
                            "createdAt"
                        ],
                        "queryField": "rapidActivityByRapidSOSIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSLogMessage": {
            "name": "RapidSOSLogMessage",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteIntegrationId": {
                    "name": "siteIntegrationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rapidSOSIncidentId": {
                    "name": "rapidSOSIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "RapidSosLogMessageType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "writtenBy": {
                    "name": "writtenBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "messageTranslated": {
                    "name": "messageTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "messageSanitized": {
                    "name": "messageSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSLogMessages",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RapidSOSAttachment": {
            "name": "RapidSOSAttachment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteIntegrationId": {
                    "name": "siteIntegrationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rapidSOSIncidentId": {
                    "name": "rapidSOSIncidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fileName": {
                    "name": "fileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "RapidSOSAttachments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "rapidAttachByRapidSOSIncidentId",
                        "fields": [
                            "rapidSOSIncidentId"
                        ],
                        "queryField": "rapidAttachByRapidSOSIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentCodeDetail": {
            "name": "IncidentCodeDetail",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "publicCode": {
                    "name": "publicCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "shareUrl": {
                    "name": "shareUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "locationSid": {
                    "name": "locationSid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deviceSid": {
                    "name": "deviceSid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "eventDate": {
                    "name": "eventDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentDataToShare": {
                    "name": "incidentDataToShare",
                    "isArray": false,
                    "type": {
                        "model": "IncidentDataToShare"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "incidentCodeDetailIncidentDataToShareId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentCodeDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentCodeDetailByIncident",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "incidentCodeDetailByIncident"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareSettings": {
            "name": "IncidentShareSettings",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentDataToShare": {
                    "name": "incidentDataToShare",
                    "isArray": false,
                    "type": {
                        "model": "IncidentDataToShare"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "incidentShareSettingsIncidentDataToShareId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiration": {
                    "name": "expiration",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareSettingsByIncident",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "incidentShareSettingsByIncident"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentDataToShare": {
            "name": "IncidentDataToShare",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shareType": {
                    "name": "shareType",
                    "isArray": false,
                    "type": {
                        "enum": "IncidentShareType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "externalIncidentNotes": {
                    "name": "externalIncidentNotes",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "externalSiteCriticalNotes": {
                    "name": "externalSiteCriticalNotes",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "externalClientCriticalNotes": {
                    "name": "externalClientCriticalNotes",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "completedActionItems": {
                    "name": "completedActionItems",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "objectsFound": {
                    "name": "objectsFound",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmStationInfo": {
                    "name": "alarmStationInfo",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "customerContactInfo": {
                    "name": "customerContactInfo",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "videoFeedUrls": {
                    "name": "videoFeedUrls",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "attachmentsList": {
                    "name": "attachmentsList",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "IncidentDataToShares",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentDataToShareByIncident",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "incidentDataToShareByIncident"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareEntry": {
            "name": "IncidentShareEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentDataToShareId": {
                    "name": "incidentDataToShareId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shareType": {
                    "name": "shareType",
                    "isArray": false,
                    "type": {
                        "enum": "IncidentShareType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "expiration": {
                    "name": "expiration",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": true,
                    "type": {
                        "model": "IncidentShareEntyNote"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "incidentShareEntryId"
                    }
                },
                "alarmsCentralStation": {
                    "name": "alarmsCentralStation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsPermitNum": {
                    "name": "alarmsPermitNum",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alarmsCSID": {
                    "name": "alarmsCSID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "events": {
                    "name": "events",
                    "isArray": true,
                    "type": {
                        "model": "IncidentShareEvent"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "incidentShareEntryId"
                    }
                },
                "attachmentsList": {
                    "name": "attachmentsList",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "contactInformation": {
                    "name": "contactInformation",
                    "isArray": true,
                    "type": {
                        "model": "IncidentShareContactInformation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "incidentShareEntryId"
                    }
                },
                "incidentOpenTime": {
                    "name": "incidentOpenTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentClosedTime": {
                    "name": "incidentClosedTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "externalSiteNotes": {
                    "name": "externalSiteNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "externalClientNotes": {
                    "name": "externalClientNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "site": {
                    "name": "site",
                    "isArray": false,
                    "type": {
                        "model": "IncidentShareSite"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "incidentShareEntrySiteId"
                    }
                },
                "integrator": {
                    "name": "integrator",
                    "isArray": false,
                    "type": {
                        "model": "IncidentShareIntegrator"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "incidentShareEntryIntegratorId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareByCode",
                        "fields": [
                            "code"
                        ],
                        "queryField": "incidentShareByCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareByIncidentId",
                        "fields": [
                            "incidentId"
                        ],
                        "queryField": "incidentShareByIncidentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareSite": {
            "name": "IncidentShareSite",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "addressOne": {
                    "name": "addressOne",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "addressTwo": {
                    "name": "addressTwo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "zipCode": {
                    "name": "zipCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareSites",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareIntegrator": {
            "name": "IncidentShareIntegrator",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "logoPath": {
                    "name": "logoPath",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareIntegrators",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareEntryAccess": {
            "name": "IncidentShareEntryAccess",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentShareEntryId": {
                    "name": "incidentShareEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "success": {
                    "name": "success",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "accessDate": {
                    "name": "accessDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "ipAddress": {
                    "name": "ipAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareEntryAccesses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareEntyNote": {
            "name": "IncidentShareEntyNote",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentNoteId": {
                    "name": "incidentNoteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentShareEntryId": {
                    "name": "incidentShareEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "noteCreatedAt": {
                    "name": "noteCreatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareEntyNotes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareEntyNotes",
                        "fields": [
                            "incidentShareEntryId"
                        ],
                        "queryField": "incidentShareEntyNotes"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareEntryNotesByNoteId",
                        "fields": [
                            "incidentNoteId"
                        ],
                        "queryField": "incidentShareEntryNotesByNoteId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareEvent": {
            "name": "IncidentShareEvent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraEventId": {
                    "name": "cameraEventId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "startTime": {
                    "name": "startTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsCameraName": {
                    "name": "vmsCameraName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "videoFeedUrl": {
                    "name": "videoFeedUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "eventType": {
                    "name": "eventType",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "disposition": {
                    "name": "disposition",
                    "isArray": false,
                    "type": {
                        "enum": "Disposition"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "triggerType": {
                    "name": "triggerType",
                    "isArray": false,
                    "type": {
                        "enum": "CameraEventTriggerType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "objectsFound": {
                    "name": "objectsFound",
                    "isArray": true,
                    "type": {
                        "enum": "CameraEventObjectType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "actionItems": {
                    "name": "actionItems",
                    "isArray": true,
                    "type": {
                        "model": "IncidentShareActionItems"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "incidentShareEventId"
                    }
                },
                "incidentShareEntryId": {
                    "name": "incidentShareEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraLatitude": {
                    "name": "cameraLatitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "cameraLongitude": {
                    "name": "cameraLongitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareEvents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareEvents",
                        "fields": [
                            "incidentShareEntryId"
                        ],
                        "queryField": "incidentShareEvents"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareEventsByCameraEventId",
                        "fields": [
                            "cameraEventId"
                        ],
                        "queryField": "incidentShareEventsByCameraEventId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareActionItems": {
            "name": "IncidentShareActionItems",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentProcedureTaskId": {
                    "name": "incidentProcedureTaskId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentShareEventId": {
                    "name": "incidentShareEventId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "completedAt": {
                    "name": "completedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareActionItems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareActionItems",
                        "fields": [
                            "incidentShareEventId"
                        ],
                        "queryField": "incidentShareActionItems"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareActionItemsByProcId",
                        "fields": [
                            "incidentProcedureTaskId"
                        ],
                        "queryField": "incidentShareActionItemsByProcId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IncidentShareContactInformation": {
            "name": "IncidentShareContactInformation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentShareEntryId": {
                    "name": "incidentShareEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phone": {
                    "name": "phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "ext": {
                    "name": "ext",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "IncidentShareContactType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "IncidentShareContactInformations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "incidentShareContactInfo",
                        "fields": [
                            "incidentShareEntryId"
                        ],
                        "queryField": "incidentShareContactInfo"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CallNotice": {
            "name": "CallNotice",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "fromNumber": {
                    "name": "fromNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "toNumber": {
                    "name": "toNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "answeredTime": {
                    "name": "answeredTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "answeredBy": {
                    "name": "answeredBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "twilioCallSid": {
                    "name": "twilioCallSid",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CallNotices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "callNoticeByMc",
                        "fields": [
                            "monitoringCenterId",
                            "createdAt"
                        ],
                        "queryField": "callNoticeByMc"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CallLog": {
            "name": "CallLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "phoneNumber": {
                    "name": "phoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "callEnd": {
                    "name": "callEnd",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "storageAudioUrl": {
                    "name": "storageAudioUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "storageTranscriptUrl": {
                    "name": "storageTranscriptUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "callingUser": {
                    "name": "callingUser",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CallLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "callsByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "createdAt"
                        ],
                        "queryField": "callsByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SMSLog": {
            "name": "SMSLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "twilioSid": {
                    "name": "twilioSid",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "toNumber": {
                    "name": "toNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fromNumber": {
                    "name": "fromNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "textingUser": {
                    "name": "textingUser",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SMSLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "smsByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "createdAt"
                        ],
                        "queryField": "smsByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "EmailLog": {
            "name": "EmailLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "awsMessageId": {
                    "name": "awsMessageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "toEmail": {
                    "name": "toEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fromEmail": {
                    "name": "fromEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "subject": {
                    "name": "subject",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "sendingUser": {
                    "name": "sendingUser",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EmailLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "emailByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId",
                            "createdAt"
                        ],
                        "queryField": "emailByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "NotificationSetting": {
            "name": "NotificationSetting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notificationType": {
                    "name": "notificationType",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "userGroup": {
                    "name": "userGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "clientList": {
                    "name": "clientList",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "NotificationSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "notifSettingsByMCType",
                        "fields": [
                            "monitoringCenterId",
                            "notificationType"
                        ],
                        "queryField": "notifSettingsByMCType"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "notifSettingsByUser",
                        "fields": [
                            "username"
                        ],
                        "queryField": "notifSettingsByUser"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "delete"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create",
                                    "update"
                                ],
                                "groups": [
                                    "Owner"
                                ],
                                "operations": [
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Notification": {
            "name": "Notification",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notificationType": {
                    "name": "notificationType",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Notifications",
            "attributes": [
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "notifByUser",
                        "fields": [
                            "username",
                            "status",
                            "createdAt"
                        ],
                        "queryField": "notifByUser"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "username",
                                "allow": "owner",
                                "operations": [
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsOperatorUserIds": {
                    "name": "vmsOperatorUserIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "vmsOperatorUserIdAutomatedRole": {
                    "name": "vmsOperatorUserIdAutomatedRole",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "vmsAdminUserIds": {
                    "name": "vmsAdminUserIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "group": {
                    "name": "group",
                    "isArray": false,
                    "type": {
                        "enum": "UserGroup"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "clientIdList": {
                    "name": "clientIdList",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "phone": {
                    "name": "phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "autoAssign": {
                    "name": "autoAssign",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "preferredLanguage": {
                    "name": "preferredLanguage",
                    "isArray": false,
                    "type": {
                        "enum": "Language"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "gridEnabled": {
                    "name": "gridEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "gridBehavior": {
                    "name": "gridBehavior",
                    "isArray": false,
                    "type": {
                        "enum": "GridBehavior"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "username"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create",
                                    "update"
                                ],
                                "groups": [
                                    "Owner"
                                ],
                                "operations": [
                                    "create",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "update"
                                ],
                                "groups": [
                                    "local-security"
                                ],
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "username",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "cognito:username"
                            }
                        ]
                    }
                }
            ]
        },
        "UserVMSDetails": {
            "name": "UserVMSDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsUserId": {
                    "name": "vmsUserId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "profileName": {
                    "name": "profileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "cognitoUsernames": {
                    "name": "cognitoUsernames",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "authEmail": {
                    "name": "authEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsUsername": {
                    "name": "vmsUsername",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsPassword": {
                    "name": "vmsPassword",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsUserType": {
                    "name": "vmsUserType",
                    "isArray": false,
                    "type": {
                        "enum": "VmsUserType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "apiKey": {
                    "name": "apiKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accessToken": {
                    "name": "accessToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshToken": {
                    "name": "refreshToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "accessTokenExpires": {
                    "name": "accessTokenExpires",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshTokenExpires": {
                    "name": "refreshTokenExpires",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserVMSDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "userVMSDetailsByEmail",
                        "fields": [
                            "authEmail"
                        ],
                        "queryField": "userVMSDetailsByEmail"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "userVMSDetailsByVMSUserId",
                        "fields": [
                            "vmsUserId"
                        ],
                        "queryField": "userVMSDetailsByVMSUserId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create",
                                    "update"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CustomerUserPermissionSet": {
            "name": "CustomerUserPermissionSet",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "permissions": {
                    "name": "permissions",
                    "isArray": true,
                    "type": {
                        "model": "CustomerUserPermission"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "permissionSet"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "ownerCustomerId": {
                    "name": "ownerCustomerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CustomerUserPermissionSets",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "username"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create",
                                    "update"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create",
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "username",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "cognito:username"
                            }
                        ]
                    }
                }
            ]
        },
        "CustomerUserPermission": {
            "name": "CustomerUserPermission",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sitePermissions": {
                    "name": "sitePermissions",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "permissionSet": {
                    "name": "permissionSet",
                    "isArray": false,
                    "type": {
                        "model": "CustomerUserPermissionSet"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "username"
                    }
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CustomerUserPermissions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "username",
                            "customerId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "userPermissionByUsernameCustomerId",
                        "fields": [
                            "username",
                            "customerId"
                        ],
                        "queryField": "userPermissionByUsernameCustomerId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "userPermissionByCustomerId",
                        "fields": [
                            "customerId"
                        ],
                        "queryField": "userPermissionByCustomerId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "groups": [
                                    "IntegratorAdmin",
                                    "Admin",
                                    "BillingAdmin",
                                    "Controller",
                                    "Owner"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "username",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "cognito:username"
                            }
                        ]
                    }
                }
            ]
        },
        "Tax": {
            "name": "Tax",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcIntAdminAccess": {
                    "name": "mcIntAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "region": {
                    "name": "region",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "subregion": {
                    "name": "subregion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "taxGovId": {
                    "name": "taxGovId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "taxTypes": {
                    "name": "taxTypes",
                    "isArray": true,
                    "type": {
                        "model": "TaxesTaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "tax"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Taxes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "taxByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "taxByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "taxByMonitoringCenterRegion",
                        "fields": [
                            "monitoringCenterId",
                            "region"
                        ],
                        "queryField": "taxByMonitoringCenterRegion"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcIntAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:mcIntAdminAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TaxesTaxType": {
            "name": "TaxesTaxType",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tax": {
                    "name": "tax",
                    "isArray": false,
                    "type": {
                        "model": "Tax"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "taxId"
                    }
                },
                "taxType": {
                    "name": "taxType",
                    "isArray": false,
                    "type": {
                        "model": "TaxType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "taxTypeId"
                    }
                },
                "mcIntAdminAccess": {
                    "name": "mcIntAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TaxesTaxTypes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTax",
                        "fields": [
                            "taxId",
                            "taxTypeId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTaxType",
                        "fields": [
                            "taxTypeId",
                            "taxId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcIntAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:mcIntAdminAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TaxType": {
            "name": "TaxType",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcIntAdminAccess": {
                    "name": "mcIntAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "taxes": {
                    "name": "taxes",
                    "isArray": true,
                    "type": {
                        "model": "TaxesTaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "taxType"
                    }
                }
            },
            "syncable": true,
            "pluralName": "TaxTypes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "taxTypeByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "taxTypeByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "taxTypesByIntMC",
                        "fields": [
                            "integratorId",
                            "monitoringCenterId"
                        ],
                        "queryField": "taxTypesByIntMC"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcIntAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:mcIntAdminAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SetupFee": {
            "name": "SetupFee",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "taxType": {
                    "name": "taxType",
                    "isArray": false,
                    "type": {
                        "model": "TaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "setupFeeTaxTypeId"
                    }
                },
                "taxTypeId": {
                    "name": "taxTypeId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SetupFees",
            "attributes": [
                {
                    "type": "key",
                    "properties": {
                        "name": "setupFeesByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "setupFeesByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "setupFeesByIntMC",
                        "fields": [
                            "integratorId",
                            "monitoringCenterId"
                        ],
                        "queryField": "setupFeesByIntMC"
                    }
                },
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Product": {
            "name": "Product",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraPlanId": {
                    "name": "cameraPlanId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "nameForInvoice": {
                    "name": "nameForInvoice",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "taxType": {
                    "name": "taxType",
                    "isArray": false,
                    "type": {
                        "model": "TaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "productTaxTypeId"
                    }
                },
                "minPrice": {
                    "name": "minPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "mrp": {
                    "name": "mrp",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "cost": {
                    "name": "cost",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "fxPrices": {
                    "name": "fxPrices",
                    "isArray": true,
                    "type": {
                        "nonModel": "FXPrice"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ProductStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ProductType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "publishedFor": {
                    "name": "publishedFor",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "publishedForArchived": {
                    "name": "publishedForArchived",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "Products",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "productByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "productByMonitoringCenter"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CameraPlan": {
            "name": "CameraPlan",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "analyticsType": {
                    "name": "analyticsType",
                    "isArray": true,
                    "type": {
                        "enum": "AnalyticType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "resolution": {
                    "name": "resolution",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "bitRate": {
                    "name": "bitRate",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "operatorDuration": {
                    "name": "operatorDuration",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "supervision": {
                    "name": "supervision",
                    "isArray": false,
                    "type": {
                        "enum": "DeviceHealthCheckSla"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "storageDays": {
                    "name": "storageDays",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "product": {
                    "name": "product",
                    "isArray": false,
                    "type": {
                        "model": "Product"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "cameraPlanProductId"
                    }
                },
                "livePatrol": {
                    "name": "livePatrol",
                    "isArray": false,
                    "type": {
                        "model": "LivePatrol"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "cameraPlanLivePatrolId"
                    }
                },
                "operatorMinutes": {
                    "name": "operatorMinutes",
                    "isArray": false,
                    "type": {
                        "model": "OperatorMinutes"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "cameraPlanOperatorMinutesId"
                    }
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CameraPlans",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "cameraPlanByIntMC",
                        "fields": [
                            "integratorId",
                            "monitoringCenterId"
                        ],
                        "queryField": "cameraPlanByIntMC"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LivePatrol": {
            "name": "LivePatrol",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraPlanId": {
                    "name": "cameraPlanId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "minPrice": {
                    "name": "minPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "mrp": {
                    "name": "mrp",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "cost": {
                    "name": "cost",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "fxPrices": {
                    "name": "fxPrices",
                    "isArray": true,
                    "type": {
                        "nonModel": "FXPrice"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LivePatrols",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "OperatorMinutes": {
            "name": "OperatorMinutes",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraPlanId": {
                    "name": "cameraPlanId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "minPrice": {
                    "name": "minPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "mrp": {
                    "name": "mrp",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "cost": {
                    "name": "cost",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "fxPrices": {
                    "name": "fxPrices",
                    "isArray": true,
                    "type": {
                        "nonModel": "FXPrice"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "OperatorMinutes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SiteSubscription": {
            "name": "SiteSubscription",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ActiveInactive"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "onHold": {
                    "name": "onHold",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": true,
                    "attributes": []
                },
                "term": {
                    "name": "term",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "renewalDate": {
                    "name": "renewalDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": true,
                    "attributes": []
                },
                "renewalTerm": {
                    "name": "renewalTerm",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "merchant": {
                    "name": "merchant",
                    "isArray": false,
                    "type": {
                        "enum": "SiteSubscriptionMerchant"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "billingDay": {
                    "name": "billingDay",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "billingFrequency": {
                    "name": "billingFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "BillingFrequency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "nextBillingDay": {
                    "name": "nextBillingDay",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": true,
                    "attributes": []
                },
                "autorefillOn": {
                    "name": "autorefillOn",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "autorefillAmount": {
                    "name": "autorefillAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "minimumBalance": {
                    "name": "minimumBalance",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "currentBalance": {
                    "name": "currentBalance",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currentBalanceUsd": {
                    "name": "currentBalanceUsd",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "maxNumberRecharges": {
                    "name": "maxNumberRecharges",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "currentDayRechargeCount": {
                    "name": "currentDayRechargeCount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "lastRefilled": {
                    "name": "lastRefilled",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "usedOperatorMinutes": {
                    "name": "usedOperatorMinutes",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "totalOperatorMinutes": {
                    "name": "totalOperatorMinutes",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "transactionsLastCalculated": {
                    "name": "transactionsLastCalculated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "secondsPerInteraction": {
                    "name": "secondsPerInteraction",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "incidentBillingDisabled": {
                    "name": "incidentBillingDisabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "interactionBillingDisabled": {
                    "name": "interactionBillingDisabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "livePatrolBillingDisabled": {
                    "name": "livePatrolBillingDisabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcIntAdminAccess": {
                    "name": "mcIntAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "cameraPlans": {
                    "name": "cameraPlans",
                    "isArray": true,
                    "type": {
                        "model": "AppliedCameraPlan"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "subscriptionId"
                    }
                },
                "setupFees": {
                    "name": "setupFees",
                    "isArray": true,
                    "type": {
                        "model": "AppliedSetupFee"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "subscriptionId"
                    }
                },
                "billingEmails": {
                    "name": "billingEmails",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "billingEntityName": {
                    "name": "billingEntityName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "stripeSubscription": {
                    "name": "stripeSubscription",
                    "isArray": false,
                    "type": {
                        "model": "SubscriptionStripeDetail"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "siteSubscriptionStripeSubscriptionId"
                    }
                },
                "commissionBilling": {
                    "name": "commissionBilling",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SiteSubscriptions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "subscriptionByMonitoringCenter",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "subscriptionByMonitoringCenter"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "subscriptionBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "subscriptionBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "subscriptionsByStatus",
                        "fields": [
                            "status"
                        ],
                        "queryField": "subscriptionsByStatus"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "siteSubscriptionsByStatusAndCreatedAt",
                        "fields": [
                            "status",
                            "createdAt"
                        ],
                        "queryField": "siteSubscriptionsByStatusAndCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcIntAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:mcIntAdminAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SubscriptionStripeDetail": {
            "name": "SubscriptionStripeDetail",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "siteSubscriptionId": {
                    "name": "siteSubscriptionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "paymentMethods": {
                    "name": "paymentMethods",
                    "isArray": true,
                    "type": {
                        "model": "StripePaymentMethod"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "subscriptionStripeDetailId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "SubscriptionStripeDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "stripeDetailBySite",
                        "fields": [
                            "siteId"
                        ],
                        "queryField": "stripeDetailBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "stripeDetailBySubscription",
                        "fields": [
                            "siteSubscriptionId"
                        ],
                        "queryField": "stripeDetailBySubscription"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StripePaymentMethod": {
            "name": "StripePaymentMethod",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "subscriptionStripeDetailId": {
                    "name": "subscriptionStripeDetailId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "stripePaymentId": {
                    "name": "stripePaymentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "stripePaymentStatus": {
                    "name": "stripePaymentStatus",
                    "isArray": false,
                    "type": {
                        "enum": "StripePaymentStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "StripePaymentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "identifier": {
                    "name": "identifier",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "preferredPayment": {
                    "name": "preferredPayment",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "StripePaymentMethods",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "paymentDetailByStripeId",
                        "fields": [
                            "subscriptionStripeDetailId"
                        ],
                        "queryField": "paymentDetailByStripeId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "paymentMethodByStripePaymentId",
                        "fields": [
                            "stripePaymentId"
                        ],
                        "queryField": "paymentMethodByStripePaymentId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AppliedProduct": {
            "name": "AppliedProduct",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "appliedCameraPlanId": {
                    "name": "appliedCameraPlanId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "nameForInvoice": {
                    "name": "nameForInvoice",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "taxType": {
                    "name": "taxType",
                    "isArray": false,
                    "type": {
                        "model": "TaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "appliedProductTaxTypeId"
                    }
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AppliedProducts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AppliedCameraPlan": {
            "name": "AppliedCameraPlan",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cameraId": {
                    "name": "cameraId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "subscriptionId": {
                    "name": "subscriptionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "analyticsType": {
                    "name": "analyticsType",
                    "isArray": true,
                    "type": {
                        "enum": "AnalyticType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "resolution": {
                    "name": "resolution",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "bitRate": {
                    "name": "bitRate",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "operatorDuration": {
                    "name": "operatorDuration",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "supervision": {
                    "name": "supervision",
                    "isArray": false,
                    "type": {
                        "enum": "DeviceHealthCheckSla"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "storageDays": {
                    "name": "storageDays",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "product": {
                    "name": "product",
                    "isArray": false,
                    "type": {
                        "model": "AppliedProduct"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "appliedCameraPlanProductId"
                    }
                },
                "livePatrol": {
                    "name": "livePatrol",
                    "isArray": false,
                    "type": {
                        "model": "AppliedLivePatrol"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "appliedCameraPlanLivePatrolId"
                    }
                },
                "operatorMinutes": {
                    "name": "operatorMinutes",
                    "isArray": false,
                    "type": {
                        "model": "AppliedOperatorMinutes"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "appliedCameraPlanOperatorMinutesId"
                    }
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AppliedCameraPlans",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "appliedCameraPlanBySub",
                        "fields": [
                            "subscriptionId"
                        ],
                        "queryField": "appliedCameraPlanBySub"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "appliedCameraPlanByCamera",
                        "fields": [
                            "cameraId"
                        ],
                        "queryField": "appliedCameraPlanByCamera"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AppliedLivePatrol": {
            "name": "AppliedLivePatrol",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "appliedCameraPlanId": {
                    "name": "appliedCameraPlanId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AppliedLivePatrols",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AppliedOperatorMinutes": {
            "name": "AppliedOperatorMinutes",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "appliedCameraPlanId": {
                    "name": "appliedCameraPlanId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AppliedOperatorMinutes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AppliedSetupFee": {
            "name": "AppliedSetupFee",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "subscriptionId": {
                    "name": "subscriptionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "taxType": {
                    "name": "taxType",
                    "isArray": false,
                    "type": {
                        "model": "TaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "appliedSetupFeeTaxTypeId"
                    }
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcBillingAdminAccess": {
                    "name": "mcBillingAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AppliedSetupFees",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "appliedSetupFeeBySub",
                        "fields": [
                            "subscriptionId"
                        ],
                        "queryField": "appliedSetupFeeBySub"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcBillingAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcBillingAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TransactionLineItem": {
            "name": "TransactionLineItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "subscriptionId": {
                    "name": "subscriptionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceId": {
                    "name": "invoiceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "connectedId": {
                    "name": "connectedId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "TransactionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "pricePer": {
                    "name": "pricePer",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "totalPrice": {
                    "name": "totalPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "quantity": {
                    "name": "quantity",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "taxes": {
                    "name": "taxes",
                    "isArray": true,
                    "type": {
                        "model": "TransactionLineItemTax"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "transactionLineItemId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TransactionLineItems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "transactionLinesByInvoice",
                        "fields": [
                            "invoiceId"
                        ],
                        "queryField": "transactionLinesByInvoice"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "transactionLinesBySite",
                        "fields": [
                            "siteId",
                            "createdAt"
                        ],
                        "queryField": "transactionLinesBySite"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "customerId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "customerId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TransactionLineItemTax": {
            "name": "TransactionLineItemTax",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "transactionLineItemId": {
                    "name": "transactionLineItemId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceId": {
                    "name": "invoiceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "taxId": {
                    "name": "taxId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "taxGovId": {
                    "name": "taxGovId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "taxPercent": {
                    "name": "taxPercent",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "taxName": {
                    "name": "taxName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "subscriptionId": {
                    "name": "subscriptionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TransactionLineItemTaxes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "transactionLineItemTaxByLineItem",
                        "fields": [
                            "transactionLineItemId"
                        ],
                        "queryField": "transactionLineItemTaxByLineItem"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Invoice": {
            "name": "Invoice",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "subtotal": {
                    "name": "subtotal",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceNumber": {
                    "name": "invoiceNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "total": {
                    "name": "total",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "transactionCurrTotal": {
                    "name": "transactionCurrTotal",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "InvoiceType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "transactionCurr": {
                    "name": "transactionCurr",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "InvoiceStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "merchant": {
                    "name": "merchant",
                    "isArray": false,
                    "type": {
                        "enum": "InvoiceMerchant"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "lineItems": {
                    "name": "lineItems",
                    "isArray": true,
                    "type": {
                        "model": "TransactionLineItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "invoiceId"
                    }
                },
                "payments": {
                    "name": "payments",
                    "isArray": true,
                    "type": {
                        "model": "InvoicePayment"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "invoiceId"
                    }
                },
                "transferPayment": {
                    "name": "transferPayment",
                    "isArray": false,
                    "type": {
                        "model": "InvoiceTransferPayment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "invoiceTransferPaymentId"
                    }
                },
                "subscriptionId": {
                    "name": "subscriptionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "transferGroup": {
                    "name": "transferGroup",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Invoices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "invoicesBySite",
                        "fields": [
                            "siteId",
                            "createdAt"
                        ],
                        "queryField": "invoicesBySite"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "invoicesByStatus",
                        "fields": [
                            "status"
                        ],
                        "queryField": "invoicesByStatus"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "invoicesByStatusOrdered",
                        "fields": [
                            "status",
                            "createdAt"
                        ],
                        "queryField": "invoicesByStatusOrdered"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "customerId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "customerId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "InvoicePayment": {
            "name": "InvoicePayment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "stripePaymentIntentId": {
                    "name": "stripePaymentIntentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentMethod": {
                    "name": "paymentMethod",
                    "isArray": false,
                    "type": {
                        "model": "StripePaymentMethod"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "invoicePaymentPaymentMethodId"
                    }
                },
                "paymentStatus": {
                    "name": "paymentStatus",
                    "isArray": false,
                    "type": {
                        "enum": "StripePaymentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "paymentType": {
                    "name": "paymentType",
                    "isArray": false,
                    "type": {
                        "enum": "PaymentType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "invoiceId": {
                    "name": "invoiceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amountReceived": {
                    "name": "amountReceived",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "amountRequested": {
                    "name": "amountRequested",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "InvoicePayments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "createdAt"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "paymentsByInvoice",
                        "fields": [
                            "invoiceId"
                        ],
                        "queryField": "paymentsByInvoice"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "invoicePaymentByStatusAndCreatedAt",
                        "fields": [
                            "paymentStatus",
                            "createdAt"
                        ],
                        "queryField": "invoicePaymentByStatusAndCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "customerId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "customerId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "InvoiceTransferPayment": {
            "name": "InvoiceTransferPayment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "destination": {
                    "name": "destination",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "transferGroup": {
                    "name": "transferGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "stripePaymentIntentId": {
                    "name": "stripePaymentIntentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "stripeTransferRefId": {
                    "name": "stripeTransferRefId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "InvoiceTransferStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceIds": {
                    "name": "invoiceIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "InvoiceTransferPayments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "transfersByGroup",
                        "fields": [
                            "transferGroup"
                        ],
                        "queryField": "transfersByGroup"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "invoiceTransferPaymentByStatusAndCreatedAt",
                        "fields": [
                            "status",
                            "createdAt"
                        ],
                        "queryField": "invoiceTransferPaymentByStatusAndCreatedAt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groups": [
                                    "local-security"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "customerId",
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "customerId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "WalletTransfer": {
            "name": "WalletTransfer",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "subscriptionId": {
                    "name": "subscriptionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "siteId": {
                    "name": "siteId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customerId": {
                    "name": "customerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "conversionRate": {
                    "name": "conversionRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentMethod": {
                    "name": "paymentMethod",
                    "isArray": false,
                    "type": {
                        "model": "StripePaymentMethod"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "walletTransferPaymentMethodId"
                    }
                },
                "stripePaymentId": {
                    "name": "stripePaymentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "WalletTransferType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "WalletTransfers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "walletTransfersBySubscription",
                        "fields": [
                            "subscriptionId",
                            "createdAt"
                        ],
                        "queryField": "walletTransfersBySubscription"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "BillingAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:integratorId"
                            }
                        ]
                    }
                }
            ]
        },
        "StripePayoutDetails": {
            "name": "StripePayoutDetails",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "stripeAccId": {
                    "name": "stripeAccId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integratorId": {
                    "name": "integratorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "chargesEnabled": {
                    "name": "chargesEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "StripePayoutDetails",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "stripeAccId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "stripePayoutDetailsByInt",
                        "fields": [
                            "integratorId"
                        ],
                        "queryField": "stripePayoutDetailsByInt"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groupsField": "monitoringCenterId",
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "integratorId",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:integratorId"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "mutations": [
                                    "create"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "IntegratorAdmin"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ExchangeRateSetting": {
            "name": "ExchangeRateSetting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "rate": {
                    "name": "rate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ExchangeRateSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "country"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read"
                                ],
                                "groups": [
                                    "Controller",
                                    "Owner",
                                    "IntegratorAdmin"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "create"
                                ],
                                "groups": [
                                    "local-security"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PaymentTransferSettings": {
            "name": "PaymentTransferSettings",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mcOwnerAccess": {
                    "name": "mcOwnerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcControllerAccess": {
                    "name": "mcControllerAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mcIntAdminAccess": {
                    "name": "mcIntAdminAccess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "StripePayoutSettingsType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "variable": {
                    "name": "variable",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "variableMaxDollars": {
                    "name": "variableMaxDollars",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "variableTaxType": {
                    "name": "variableTaxType",
                    "isArray": false,
                    "type": {
                        "model": "TaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "paymentTransferSettingsVariableTaxTypeId"
                    }
                },
                "flat": {
                    "name": "flat",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "flatTaxType": {
                    "name": "flatTaxType",
                    "isArray": false,
                    "type": {
                        "model": "TaxType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "paymentTransferSettingsFlatTaxTypeId"
                    }
                },
                "modifiedBy": {
                    "name": "modifiedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "PaymentTransferSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "paymentTransferSettingsByMC",
                        "fields": [
                            "monitoringCenterId"
                        ],
                        "queryField": "paymentTransferSettingsByMC"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "mcOwnerAccess",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:mcOwnerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcControllerAccess",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:mcControllerAccess"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "mcIntAdminAccess",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:mcIntAdminAccess"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groups": [
                                    "local-security"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "WebhookHealth": {
            "name": "WebhookHealth",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsWebhookId": {
                    "name": "vmsWebhookId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "ackAvgInterval": {
                    "name": "ackAvgInterval",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "acklastReceived": {
                    "name": "acklastReceived",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "ackCount": {
                    "name": "ackCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "analyticAvgInterval": {
                    "name": "analyticAvgInterval",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "analyticlastReceived": {
                    "name": "analyticlastReceived",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "analyticCount": {
                    "name": "analyticCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "WebhookHealths",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "webhookHealthByDate",
                        "fields": [
                            "monitoringCenterId",
                            "vmsWebhookId",
                            "date"
                        ],
                        "queryField": "webhookHealthByDate"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "monitoringCenterId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:monitoringCenterId"
                            }
                        ]
                    }
                }
            ]
        },
        "Master": {
            "name": "Master",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Masters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "ActivityLogType": {
            "name": "ActivityLogType",
            "values": [
                "Info",
                "Failure"
            ]
        },
        "ActivityLogSubSystem": {
            "name": "ActivityLogSubSystem",
            "values": [
                "Arming",
                "Device",
                "Automation"
            ]
        },
        "ActivityLogEvent": {
            "name": "ActivityLogEvent",
            "values": [
                "Armed",
                "Disarmed",
                "Exception",
                "TroubleshootingURLRequest",
                "Webhook",
                "Audio",
                "SMS",
                "Email",
                "Snooze",
                "UnSnooze"
            ]
        },
        "AuditLogArea": {
            "name": "AuditLogArea",
            "values": [
                "Backend",
                "Frontend"
            ]
        },
        "AuditLogAction": {
            "name": "AuditLogAction",
            "values": [
                "Add",
                "Update",
                "Delete",
                "Navigate",
                "Click"
            ]
        },
        "CustomerType": {
            "name": "CustomerType",
            "values": [
                "MonitoringCenter",
                "Integrator",
                "Client"
            ]
        },
        "PhoneType": {
            "name": "PhoneType",
            "values": [
                "Landline",
                "Mobile"
            ]
        },
        "SiteContactType": {
            "name": "SiteContactType",
            "values": [
                "EmergencyContact",
                "EmailNotificationContact",
                "SiteContact",
                "AdminContact",
                "IncidentCODEContact"
            ]
        },
        "IncidentStatus": {
            "name": "IncidentStatus",
            "values": [
                "Open",
                "Assigned",
                "Closed"
            ]
        },
        "EventType": {
            "name": "EventType",
            "values": [
                "CameraDisconnect",
                "CameraReconnect",
                "Disarmed",
                "ErroneousAlert",
                "Misidentification",
                "Other",
                "Uncategorized",
                "Normal",
                "CheckIn",
                "CheckOut",
                "ClockIn",
                "ClockOut",
                "ParkingStart",
                "ParkingEnd",
                "GateAccess",
                "DoorAccess",
                "TemperatureCheck",
                "IDCheck",
                "PPECheck",
                "WorkingAloneCheck",
                "WelfareCheck",
                "ParkingViolation",
                "Suspicious",
                "Loitering",
                "Vandalism",
                "Trespass",
                "Emergency",
                "LifeInDanger",
                "Fire",
                "MedicalDuress",
                "HoldUp",
                "VehicleBreakIn",
                "DrugTrafficking",
                "Assault",
                "PackageDelivery",
                "Muted",
                "Snoozed",
                "DeviceNotice"
            ]
        },
        "RuleType": {
            "name": "RuleType",
            "values": [
                "AlarmOnly",
                "AlwaysOn"
            ]
        },
        "CameraEventSupervisionType": {
            "name": "CameraEventSupervisionType",
            "values": [
                "Animal",
                "Bicycle",
                "Boat",
                "DeviceNotice",
                "Car",
                "Fire",
                "HardHat",
                "Luggage",
                "NoHardHat",
                "Person",
                "Smoke",
                "Unknown",
                "Intrusion",
                "Loitering"
            ]
        },
        "EventOp": {
            "name": "EventOp",
            "values": [
                "DispatchPolice",
                "ContactSite",
                "ContactList",
                "LogEvent",
                "DispatchGuard",
                "TwowayAudio",
                "DispatchFire",
                "DispatchMedical",
                "DispatchOutreach",
                "DispatchBylaw",
                "SystemDisarm",
                "CreateClip"
            ]
        },
        "MonitoringStatus": {
            "name": "MonitoringStatus",
            "values": [
                "Armed",
                "Disarmed",
                "Alarm"
            ]
        },
        "IntegrationType": {
            "name": "IntegrationType",
            "values": [
                "EmergencyOperator",
                "GuardService",
                "Bylaw",
                "OutreachAgencies",
                "IncidentCODE"
            ]
        },
        "IntegrationSubType": {
            "name": "IntegrationSubType",
            "values": [
                "Direct",
                "Shared",
                "TrackTik",
                "Fire",
                "Police",
                "Medical"
            ]
        },
        "RspndrBilledTo": {
            "name": "RspndrBilledTo",
            "values": [
                "MC",
                "DEALER"
            ]
        },
        "RspndrLeaveWhen": {
            "name": "RspndrLeaveWhen",
            "values": [
                "WHEN_ADVISED",
                "WHEN_POLICE_ARRIVES",
                "WHEN_MAX_DURATION_EXPIRED",
                "LEAVE_IMMEDIATELY",
                "UNKNOWN"
            ]
        },
        "RspndrServiceKind": {
            "name": "RspndrServiceKind",
            "values": [
                "ALARM_RESPONSE"
            ]
        },
        "RspndrIncidentStatus": {
            "name": "RspndrIncidentStatus",
            "values": [
                "PENDING",
                "PUBLISHED",
                "TAKEN",
                "ARRIVED",
                "FINISHED",
                "CANCELLED",
                "GROUNDING_REQUESTED",
                "GROUNDING_REQUEST_CONFIRMED",
                "GROUNDING_REQUEST_DECLINED",
                "GROUNDING_REQUEST_CONFIRM_ACKED",
                "GROUNDING_REQUEST_DECLINE_ACKED",
                "GROUNDED",
                "GROUNDING_ENDING",
                "GROUNDING_END_CONFIRMED",
                "GROUNDING_END_DECLINED",
                "GROUNDING_END_CONFIRM_ACKED",
                "GROUNDING_END_DECLINE_ACKED",
                "UNKNOWN",
                "ERROR",
                "NOTED"
            ]
        },
        "Disposition": {
            "name": "Disposition",
            "values": [
                "L0",
                "L1",
                "L2",
                "L3",
                "L4"
            ]
        },
        "NotificationType": {
            "name": "NotificationType",
            "values": [
                "IncidentL0",
                "IncidentL1",
                "IncidentL2",
                "IncidentL3",
                "IncidentL4",
                "IncidentAssignedToMe",
                "IncidentGuardServiceAction",
                "IncidentEmergencyOpAction",
                "IncidentNewCameraEvent",
                "IncidentActionItemUpdated",
                "IncidentNoteAdded",
                "IncidentPhoneCall",
                "IncidentCameraEventSeparate",
                "IncidentClosed",
                "IncidentReopen",
                "CameraDisconnect",
                "CameraReconnect",
                "ErroneousAlert",
                "Misidentification",
                "Muted",
                "Other",
                "Snoozed",
                "PackageDelivery",
                "Uncategorized",
                "Normal",
                "CheckIn",
                "CheckOut",
                "ClockIn",
                "ClockOut",
                "ParkingStart",
                "ParkingEnd",
                "PPECheck",
                "GateAccess",
                "DoorAccess",
                "TemperatureCheck",
                "IDCheck",
                "WorkingAloneCheck",
                "WelfareCheck",
                "ParkingViolation",
                "DeviceNotice",
                "Suspicious",
                "Assault",
                "DrugTrafficking",
                "Loitering",
                "Vandalism",
                "VehicleBreakIn",
                "Trespass",
                "Emergency",
                "LifeInDanger",
                "Fire",
                "MedicalDuress",
                "HoldUp",
                "CameraNoticeResetFailure",
                "CameraNoticeResetSucceed",
                "MonitoringNoticeArmingDisarmingFailed"
            ]
        },
        "NotificationStatus": {
            "name": "NotificationStatus",
            "values": [
                "Read",
                "Unread"
            ]
        },
        "RapidSosEmergencyType": {
            "name": "RapidSosEmergencyType",
            "values": [
                "BURGLARY",
                "HOLDUP",
                "SILENT_ALARM",
                "CRASH",
                "MEDICAL",
                "FIRE",
                "CO",
                "OTHER"
            ]
        },
        "RapidSosCoverageType": {
            "name": "RapidSosCoverageType",
            "values": [
                "Error",
                "None",
                "Supplemental",
                "Full"
            ]
        },
        "RapidSosIncidentStatus": {
            "name": "RapidSosIncidentStatus",
            "values": [
                "NEW",
                "TIMEOUT",
                "IGNORED",
                "DISPATCH_REQUESTED",
                "ACCEPTED",
                "DECLINED",
                "CANCELED",
                "ERROR",
                "REJECTED"
            ]
        },
        "RapidSosLogMessageType": {
            "name": "RapidSosLogMessageType",
            "values": [
                "SYSTEM_UPDATE",
                "STATUS_UPDATE",
                "CHAT_MESSAGE",
                "MILESTONE",
                "MULTI_TRIP_SIGNAL"
            ]
        },
        "SiteType": {
            "name": "SiteType",
            "values": [
                "RESIDENTIAL",
                "COMMERCIAL",
                "VEHICLE",
                "PERSONAL"
            ]
        },
        "TrackTikIncidentStatus": {
            "name": "TrackTikIncidentStatus",
            "values": [
                "DISPATCH_REQUESTED",
                "CLOSED"
            ]
        },
        "DeviceHealthCheckSla": {
            "name": "DeviceHealthCheckSla",
            "values": [
                "HOUR_1",
                "HOUR_4",
                "HOUR_24"
            ]
        },
        "DeviceStatusCheckSla": {
            "name": "DeviceStatusCheckSla",
            "values": [
                "MINUTE_5",
                "MINUTE_10",
                "MINUTE_15",
                "MINUTE_30",
                "HOUR_1",
                "HOUR_4",
                "HOUR_24"
            ]
        },
        "DeviceStatusProperties": {
            "name": "DeviceStatusProperties",
            "values": [
                "vmsConnect",
                "isOnvif",
                "videoStreamState",
                "isAnalyticsEnabled",
                "isMotionEnabled"
            ]
        },
        "HealthCheckStatus": {
            "name": "HealthCheckStatus",
            "values": [
                "PASS",
                "FAIL"
            ]
        },
        "CameraEventTriggerType": {
            "name": "CameraEventTriggerType",
            "values": [
                "Motion",
                "Intrusion",
                "LineCrossing",
                "Analytic",
                "LicensePlate",
                "FaceRecognition",
                "Tampering",
                "Loitering",
                "Notice"
            ]
        },
        "CameraEventObjectType": {
            "name": "CameraEventObjectType",
            "values": [
                "Animal",
                "Bicycle",
                "Boat",
                "Car",
                "DeviceNotice",
                "Fire",
                "HardHat",
                "Luggage",
                "NoHardHat",
                "Person",
                "Smoke",
                "Unknown"
            ]
        },
        "TrackTikPriority": {
            "name": "TrackTikPriority",
            "values": [
                "LOW",
                "IMPORTANT",
                "URGENT"
            ]
        },
        "UserGroup": {
            "name": "UserGroup",
            "values": [
                "Operators",
                "SuperOperators",
                "IntegratorAdmin",
                "Admin",
                "BillingAdmin",
                "Controller",
                "Owner",
                "Customer"
            ]
        },
        "IncidentShareContactType": {
            "name": "IncidentShareContactType",
            "values": [
                "Site",
                "Client",
                "Emergency"
            ]
        },
        "IncidentShareType": {
            "name": "IncidentShareType",
            "values": [
                "ENDCUSTOMER",
                "OTHER"
            ]
        },
        "ProductStatus": {
            "name": "ProductStatus",
            "values": [
                "Draft",
                "Published"
            ]
        },
        "ProductType": {
            "name": "ProductType",
            "values": [
                "CameraPlan",
                "Hardware"
            ]
        },
        "AnalyticType": {
            "name": "AnalyticType",
            "values": [
                "BasicId",
                "AdvancedId",
                "FaceId"
            ]
        },
        "ActiveInactive": {
            "name": "ActiveInactive",
            "values": [
                "Active",
                "Inactive"
            ]
        },
        "SiteSubscriptionMerchant": {
            "name": "SiteSubscriptionMerchant",
            "values": [
                "Integrator",
                "MonitoringCenter"
            ]
        },
        "InvoiceMerchant": {
            "name": "InvoiceMerchant",
            "values": [
                "Integrator",
                "MonitoringCenter",
                "Platform"
            ]
        },
        "BillingFrequency": {
            "name": "BillingFrequency",
            "values": [
                "Monthly"
            ]
        },
        "InvoiceStatus": {
            "name": "InvoiceStatus",
            "values": [
                "Open",
                "PaymentRequested",
                "Paid",
                "Void"
            ]
        },
        "ArmedStates": {
            "name": "ArmedStates",
            "values": [
                "Armed",
                "Disarmed"
            ]
        },
        "MonitoringStates": {
            "name": "MonitoringStates",
            "values": [
                "Available",
                "Unavailable"
            ]
        },
        "AlarmStates": {
            "name": "AlarmStates",
            "values": [
                "Alarm",
                "NoAlarm"
            ]
        },
        "ArmingActions": {
            "name": "ArmingActions",
            "values": [
                "Arm",
                "Disarm"
            ]
        },
        "HolidayTypes": {
            "name": "HolidayTypes",
            "values": [
                "public",
                "bank",
                "school",
                "optional",
                "observance"
            ]
        },
        "Currency": {
            "name": "Currency",
            "values": [
                "CAD",
                "USD",
                "EUR",
                "MXN",
                "GBP",
                "JMD"
            ]
        },
        "TransactionType": {
            "name": "TransactionType",
            "values": [
                "COGS",
                "MonitoringIncluded",
                "MonitoringOverage",
                "LivePatrol",
                "SetupFee",
                "RecurringFees"
            ]
        },
        "InvoiceType": {
            "name": "InvoiceType",
            "values": [
                "Recurring",
                "Overage",
                "COGS",
                "Setup"
            ]
        },
        "StripePaymentStatus": {
            "name": "StripePaymentStatus",
            "values": [
                "succeeded",
                "processing",
                "requires_payment_method",
                "requires_confirmation",
                "requires_action",
                "canceled"
            ]
        },
        "PaymentType": {
            "name": "PaymentType",
            "values": [
                "Payment",
                "WalletTransfer",
                "CreditNote",
                "Refund"
            ]
        },
        "StripePaymentType": {
            "name": "StripePaymentType",
            "values": [
                "card"
            ]
        },
        "StripePayoutSettingsType": {
            "name": "StripePayoutSettingsType",
            "values": [
                "Card",
                "Bank",
                "Wallet"
            ]
        },
        "WalletTransferType": {
            "name": "WalletTransferType",
            "values": [
                "Manual",
                "Auto"
            ]
        },
        "InvoiceTransferStatus": {
            "name": "InvoiceTransferStatus",
            "values": [
                "Pending",
                "Complete",
                "Canceled"
            ]
        },
        "PayoutItemType": {
            "name": "PayoutItemType",
            "values": [
                "Bill",
                "Invoice"
            ]
        },
        "DataEntryStatus": {
            "name": "DataEntryStatus",
            "values": [
                "Pending",
                "Created",
                "Submitted",
                "Approved",
                "Published",
                "Suspended"
            ]
        },
        "VmsSourceType": {
            "name": "VmsSourceType",
            "values": [
                "Acknowledgement",
                "SupervisedEvent"
            ]
        },
        "Language": {
            "name": "Language",
            "values": [
                "English",
                "Spanish",
                "Portuguese",
                "French",
                "Chinese",
                "Italian",
                "Dutch"
            ]
        },
        "AuthCxUserAction": {
            "name": "AuthCxUserAction",
            "values": [
                "DeleteUser",
                "CreateUser",
                "UpdateMe",
                "UpdateUser",
                "DisableUser",
                "ListUsers",
                "GetMe",
                "GetUser"
            ]
        },
        "DeviceType": {
            "name": "DeviceType",
            "values": [
                "Camera",
                "Gateway",
                "Audio"
            ]
        },
        "TalkdownType": {
            "name": "TalkdownType",
            "values": [
                "Manual",
                "PlayMessage"
            ]
        },
        "AudioMessageType": {
            "name": "AudioMessageType",
            "values": [
                "Default",
                "Custom"
            ]
        },
        "AudioManagementAction": {
            "name": "AudioManagementAction",
            "values": [
                "AddGroups",
                "UpdateGroups",
                "RemoveGroups",
                "AddMessageTypes",
                "UpdateMessageTypes",
                "RemoveMessageTypes"
            ]
        },
        "HttpRequestType": {
            "name": "HttpRequestType",
            "values": [
                "POST",
                "GET",
                "PUT"
            ]
        },
        "ObjectGroupType": {
            "name": "ObjectGroupType",
            "values": [
                "Audio"
            ]
        },
        "TriggerType": {
            "name": "TriggerType",
            "values": [
                "Status",
                "Analytic",
                "Disposition"
            ]
        },
        "TriggerActionType": {
            "name": "TriggerActionType",
            "values": [
                "Webhook",
                "Audio",
                "SMS",
                "Email"
            ]
        },
        "TriggerCustomAttributes": {
            "name": "TriggerCustomAttributes",
            "values": [
                "siteId",
                "accountId"
            ]
        },
        "GridBehavior": {
            "name": "GridBehavior",
            "values": [
                "DefaultOpen",
                "DefaultClosed"
            ]
        },
        "VmsUserType": {
            "name": "VmsUserType",
            "values": [
                "Admin",
                "Guard"
            ]
        },
        "VmsPortalType": {
            "name": "VmsPortalType",
            "values": [
                "Alarm",
                "Admin",
                "Client"
            ]
        }
    },
    "nonModels": {
        "FXPrice": {
            "name": "FXPrice",
            "fields": {
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ExternalPartyResponse": {
            "name": "ExternalPartyResponse",
            "fields": {
                "police": {
                    "name": "police",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "fire": {
                    "name": "fire",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "ambulance": {
                    "name": "ambulance",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "bylaw": {
                    "name": "bylaw",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "security": {
                    "name": "security",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "outreach": {
                    "name": "outreach",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "siteContact": {
                    "name": "siteContact",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "agenciesArrived": {
                    "name": "agenciesArrived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "notArrivedReason": {
                    "name": "notArrivedReason",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notArrivedReasonTranslated": {
                    "name": "notArrivedReasonTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "notArrivedReasonSanitized": {
                    "name": "notArrivedReasonSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TalkdownInstructions": {
            "name": "TalkdownInstructions",
            "fields": {
                "talkdownType": {
                    "name": "talkdownType",
                    "isArray": false,
                    "type": {
                        "enum": "TalkdownType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "noteTranslated": {
                    "name": "noteTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "noteSanitized": {
                    "name": "noteSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "phone": {
                    "name": "phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "messageDetails": {
                    "name": "messageDetails",
                    "isArray": true,
                    "type": {
                        "nonModel": "TalkdownMessageDetail"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "hostConfiguration": {
                    "name": "hostConfiguration",
                    "isArray": false,
                    "type": {
                        "nonModel": "TalkdownHostConfiguration"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TalkdownMessageDetail": {
            "name": "TalkdownMessageDetail",
            "fields": {
                "messageType": {
                    "name": "messageType",
                    "isArray": false,
                    "type": {
                        "enum": "AudioMessageType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "messageTypeId": {
                    "name": "messageTypeId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "urlSuffix": {
                    "name": "urlSuffix",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TalkdownHostConfiguration": {
            "name": "TalkdownHostConfiguration",
            "fields": {
                "baseUrl": {
                    "name": "baseUrl",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "password": {
                    "name": "password",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "method": {
                    "name": "method",
                    "isArray": false,
                    "type": {
                        "enum": "HttpRequestType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CameraEventObject": {
            "name": "CameraEventObject",
            "fields": {
                "object": {
                    "name": "object",
                    "isArray": false,
                    "type": {
                        "enum": "CameraEventObjectType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "confidence": {
                    "name": "confidence",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AssignmentHistoryItem": {
            "name": "AssignmentHistoryItem",
            "fields": {
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "assignedAt": {
                    "name": "assignedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TriggerFilter": {
            "name": "TriggerFilter",
            "fields": {
                "filter": {
                    "name": "filter",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "frequencyLimit": {
                    "name": "frequencyLimit",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "lastTriggered": {
                    "name": "lastTriggered",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "actions": {
                    "name": "actions",
                    "isArray": true,
                    "type": {
                        "nonModel": "TriggerAction"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TriggerAction": {
            "name": "TriggerAction",
            "fields": {
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": {
                        "enum": "TriggerActionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "actionSettings": {
                    "name": "actionSettings",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "frequencyLimit": {
                    "name": "frequencyLimit",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "lastTriggered": {
                    "name": "lastTriggered",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LambdaResponse": {
            "name": "LambdaResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LambdaSearchResponse": {
            "name": "LambdaSearchResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "LambdaSearchResponseItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LambdaSearchResponseItem": {
            "name": "LambdaSearchResponseItem",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "overviewText": {
                    "name": "overviewText",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "LambdaDispositionBreakdownResponse": {
            "name": "LambdaDispositionBreakdownResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "l0": {
                    "name": "l0",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "l1": {
                    "name": "l1",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "l2": {
                    "name": "l2",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "l3": {
                    "name": "l3",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "l4": {
                    "name": "l4",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LambdaRapidSOSResponse": {
            "name": "LambdaRapidSOSResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "rapidSOSIncident": {
                    "name": "rapidSOSIncident",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "IncidentReportResponse": {
            "name": "IncidentReportResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nextRecord": {
                    "name": "nextRecord",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "IncidentReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "IncidentReportItem": {
            "name": "IncidentReportItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "resolvedAt": {
                    "name": "resolvedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "primaryDisposition": {
                    "name": "primaryDisposition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "siteName": {
                    "name": "siteName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "clientName": {
                    "name": "clientName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "assignedTo": {
                    "name": "assignedTo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CameraEventReportResponse": {
            "name": "CameraEventReportResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nextRecord": {
                    "name": "nextRecord",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "CameraEventReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CameraEventReportItem": {
            "name": "CameraEventReportItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "startTime": {
                    "name": "startTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "site": {
                    "name": "site",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vmsNotes": {
                    "name": "vmsNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsNotesTranslated": {
                    "name": "vmsNotesTranslated",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "vmsNotesSanitized": {
                    "name": "vmsNotesSanitized",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "camera": {
                    "name": "camera",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "disposition": {
                    "name": "disposition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "eventType": {
                    "name": "eventType",
                    "isArray": false,
                    "type": {
                        "enum": "EventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "client": {
                    "name": "client",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integrator": {
                    "name": "integrator",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "InvoiceReportResponse": {
            "name": "InvoiceReportResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nextRecord": {
                    "name": "nextRecord",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "InvoiceReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "InvoiceReportItem": {
            "name": "InvoiceReportItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceNumber": {
                    "name": "invoiceNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "siteName": {
                    "name": "siteName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "clientName": {
                    "name": "clientName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "invoiceType": {
                    "name": "invoiceType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fromName": {
                    "name": "fromName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "toName": {
                    "name": "toName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "total": {
                    "name": "total",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CustomerPaymentsReportResponse": {
            "name": "CustomerPaymentsReportResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nextRecord": {
                    "name": "nextRecord",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomerPaymentsReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CustomerPaymentsReportItem": {
            "name": "CustomerPaymentsReportItem",
            "fields": {
                "invoiceNumber": {
                    "name": "invoiceNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "paymentDate": {
                    "name": "paymentDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "billingEntityName": {
                    "name": "billingEntityName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "transactionId": {
                    "name": "transactionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PayoutsReportResponse": {
            "name": "PayoutsReportResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "errorMessage": {
                    "name": "errorMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nextRecord": {
                    "name": "nextRecord",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "PayoutsReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "PayoutsReportItem": {
            "name": "PayoutsReportItem",
            "fields": {
                "invoiceNumber": {
                    "name": "invoiceNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "paymentDate": {
                    "name": "paymentDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "transferAmount": {
                    "name": "transferAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "referenceId": {
                    "name": "referenceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "PayoutItemType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "transactionCurr": {
                    "name": "transactionCurr",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SiteVMSToken": {
            "name": "SiteVMSToken",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "accessToken": {
                    "name": "accessToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "refreshToken": {
                    "name": "refreshToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UniversalQueueResponse": {
            "name": "UniversalQueueResponse",
            "fields": {
                "statusCode": {
                    "name": "statusCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "rows": {
                    "name": "rows",
                    "isArray": true,
                    "type": {
                        "nonModel": "UniversalQueueRow"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "UniversalQueueRow": {
            "name": "UniversalQueueRow",
            "fields": {
                "incidentId": {
                    "name": "incidentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "monitoringCenterId": {
                    "name": "monitoringCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "primaryDisposition": {
                    "name": "primaryDisposition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "siteName": {
                    "name": "siteName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "overSLASeconds": {
                    "name": "overSLASeconds",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "monitoringCenterName": {
                    "name": "monitoringCenterName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "assignedTo": {
                    "name": "assignedTo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "assignBy": {
                    "name": "assignBy",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "verifiedAlarm": {
                    "name": "verifiedAlarm",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.3.6",
    "version": "dd9db0a19adc5e6df5a769b741c8801e"
};