<ng-particles id="tsparticles-el" (particlesLoaded)="particlesLoaded($event)" [url]="particlesUrl"
  [particlesInit]="particlesInit"></ng-particles>
<div class="home-component d-flex flex-column align-items-center justify-content-center h-100 w-100">
  <img [src]="logoUrl" style="max-width:400px;padding-top:50px" alt="logo" id="login-logo">
  <div style="max-width: 600px;" id="desope-auth" #descopeAuth></div>

  <p class="accent mt-5">
    <span (click)="onGoToNew()" class="underline-hover d-flex align-items-center cursor--pointer">
      {{ 'home.to-new-login' | translate }}
      <mat-icon class="icon--small-no-m">arrow_forward</mat-icon>
    </span>
  </p>
</div>