import { Injectable } from '@angular/core';
import { DataEntryStatus } from 'src/app/services/graphql.service';

@Injectable()
export class DataEntryStatusService {

  public onGetAllStatus(): DataEntryStatus[] {
    return Object.keys(DataEntryStatus) as DataEntryStatus[];
  }
}
