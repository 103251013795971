import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';

import { APIService, AuditLogAction, AuditLogArea, CreateAuditLogInput } from '../../services/graphql.service';
import { User } from '../models/user.model';
import { UserStoreService } from '../store/user-store.service';

@Injectable()
export class AuditLoggerService {

  constructor(private api: APIService, private router: Router,
    private userStore: UserStoreService) {
  }

  public async logNavigateAction(url?: string): Promise<void> {
    if (this.userStore.user) {
      if (!url) {
        url = this.router.url;
      }
      const input: CreateAuditLogInput = {
        partition: moment().format('YYYY-MM'),
        modifiedBy: this.userStore.user?.username,
        area: AuditLogArea.Frontend,
        action: AuditLogAction.Navigate,
        monitoringCenterId: this.userStore.user?.monitoringCenterId! ?? 'n/a',
        description: url
      };
      await this.api.CreateAuditLog(input);
    }
  }

  public async logClickAction(ctrlName: string): Promise<void> {
    if (this.userStore.user) {
      const input: CreateAuditLogInput = {
        partition: moment().format('YYYY-MM'),
        modifiedBy: this.userStore.user?.username,
        area: AuditLogArea.Frontend,
        action: AuditLogAction.Click,
        monitoringCenterId: this.userStore.user?.monitoringCenterId!,
        description: ctrlName
      };
      await this.api.CreateAuditLog(input);
    }
  }
}