import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
import { ResizableModule } from 'angular-resizable-element';
import { NgParticlesModule } from 'ng-particles';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { environment } from 'src/environments/environment';

import { CognitoUserService } from '../pages/admin/services/cognito-user.service';
import { ContactInfoComponent } from '../pages/incidents/pages/incident-detail/components/contact-info/contact-info.component';
import {
    IncidentSharePgMgmtComponent
    // eslint-disable-next-line max-len
} from '../pages/incidents/pages/incident-detail/components/integration-details/components/incident-share-pg-mgmt/incident-share-pg-mgmt.component';
import {
    CamerasComponent
} from '../pages/incidents/pages/incident-detail/components/video-event-panel/components/cameras/cameras.component';
import {
    SimpleVideoViewerComponent
} from '../pages/incidents/pages/incident-detail/components/video-event-panel/components/simple-video-viewer/simple-video-viewer.component';
import {
    SiteMapComponent
} from '../pages/incidents/pages/incident-detail/components/video-event-panel/components/site-map/site-map.component';

import { IdHoverComponent } from './components/id-hover/id-hover.component';
import { LocalizeUserInputComponent } from './components/localize-user-input/localize-user-input.component';
import { InitiateCallDirective } from './directives/initiate-call.directive';
import { LocalizedCurrencyPipe } from './pipes/currency-localize.pipe';
import { LocalizedDatePipe } from './pipes/date-localize.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { PopUpConfirmComponent } from './pop-up/pop-up-confirm/pop-up-confirm.component';
import { PopupImageComponent } from './pop-up/pop-up-image/popup-image.component';
import { PopUpComponent } from './pop-up/pop-up.component';
import { PopUpService } from './pop-up/pop-up.service';
import { PopupConfirmWithReasonComponent } from './pop-up/popup-confirm-with-reason/popup-confirm-with-reason.component';
import { PopupRefreshComponent } from './pop-up/popup-refresh/popup-refresh.component';
import { CameraService } from './services/camera.service';
import { CustomerService } from './services/customer.service';
import { DashboardService } from './services/dashboard.service';
import { DataEntryStatusService } from './services/data-entry-status.service';
import { ErrorService } from './services/error.service';
import { IncidentAuditService } from './services/incident-audit.service';
import { LanguageService } from './services/language.service';
import { LocalGuardApiService } from './services/local-guard-api.service';
import { RapidSOSService } from './services/rapidsos.service';
import { SidenavService } from './services/side-nav.service';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);

@NgModule({
    imports: [
        CommonModule,
        NgxMatIntlTelInputComponent,
        MatSidenavModule,
        MatButtonModule,
        MatToolbarModule,
        MatTooltipModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatChipsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatSelectModule,
        TranslateModule,
        PdfViewerModule,
        MatTabsModule,
        MatSortModule,
        FileSaverModule,
        NgxMatSelectSearchModule,
        MatDialogModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatRadioModule,
        PinchZoomModule,
        ResizableModule,
        NgxMaterialTimepickerModule,
        AgGridModule,
        NgParticlesModule,
        MatPaginatorModule,
    ],
    exports: [
        CommonModule,
        NgxMatIntlTelInputComponent,
        MatSidenavModule,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatToolbarModule,
        MatTooltipModule,
        MatCardModule,
        MatCheckboxModule,
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatChipsModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        TranslateModule,
        PdfViewerModule,
        MatTabsModule,
        FileSaverModule,
        NgxMatSelectSearchModule,
        MatDialogModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        InitiateCallDirective,
        MatSnackBarModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatRadioModule,
        HighlightPipe,
        PinchZoomModule,
        PopUpConfirmComponent,
        PopupRefreshComponent,
        ResizableModule,
        LocalizedDatePipe,
        LocalizedCurrencyPipe,
        NgxMaterialTimepickerModule,
        LocalizeUserInputComponent,
        AgGridModule,
        NgParticlesModule,
        IncidentSharePgMgmtComponent,
        SiteMapComponent,
        CamerasComponent,
        SimpleVideoViewerComponent,
        ContactInfoComponent,
        IdHoverComponent,
        MatPaginatorModule
    ],
    providers: [
        SidenavService,
        PopUpService,
        CognitoUserService,
        ErrorService,
        IncidentAuditService,
        CustomerService,
        LocalGuardApiService,
        DashboardService,
        RapidSOSService,
        CameraService,
        DataEntryStatusService,
        LanguageService
    ],
    declarations: [
        PopUpComponent,
        InitiateCallDirective,
        PopupImageComponent,
        PopUpConfirmComponent,
        PopupRefreshComponent,
        HighlightPipe,
        PopupConfirmWithReasonComponent,
        LocalizedDatePipe,
        LocalizedCurrencyPipe,
        LocalizeUserInputComponent,
        IncidentSharePgMgmtComponent,
        SiteMapComponent,
        CamerasComponent,
        SimpleVideoViewerComponent,
        ContactInfoComponent,
        IdHoverComponent,
    ]
})
export class SharedModule { }
