<mat-tab-group class="h-100" animationDuration="0ms">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="mr-3 icon--small">contact_phone</mat-icon>
      {{ 'contact-info.contact-info' | translate }}
    </ng-template>

    <div class="contact-container h-100 overflow-y-auto px-4 py-3">
      <div class="w-100">
        <p class="gray-600 mb-1">{{ 'contact-info.emergency-contact' | translate }}</p>
        <ng-container *ngFor="let contact of emergencyContacts">
          <div>
            <p class="mb-1">{{contact.name}}</p>
            <p class="mb-1" *ngIf="contact.notes"><span class="gray-600">{{ 'contact-info.notes' | translate }}:</span>
              <app-localize-user-input [value]="contact.notes" [translatedValues]="contact.notesTranslated"
                [sanitizedValues]="contact.notesSanitized">
              </app-localize-user-input>
            </p>
          </div>
          <div class="d-flex">
            <div>
              <ng-container *ngFor="let phone of getPhones(contact)">
                <ng-container *ngIf="!phone.archived">
                  <ng-container *ngIf="endUser; else notEndUserPhone">
                    <span class="pill mb-3 mr-2">
                      {{phone.number}}
                      <ng-container *ngIf="phone.ext">
                        <span class="mx-1">x</span>{{phone.ext}}
                      </ng-container>
                      ({{phone.type}})
                    </span>
                  </ng-container>
                  <ng-template #notEndUserPhone>
                    <span class="pill mb-3 mr-2">
                      <span class="pr-1" initiateCall [siteId]="incident.site.id"
                        [customerId]="incident.incidentCustomerId" [incidentId]="incident.id"
                        entityType="Incident">{{phone.number}}
                      </span>
                      <ng-container *ngIf="phone.ext">
                        <span class="mx-1">x</span>{{phone.ext}}
                      </ng-container>
                      ({{phone.type}})
                    </span>
                  </ng-template>
                </ng-container>
              </ng-container>
            </div>

            <div class="ml-auto" *ngIf="!endUser">
              <p class="mb-0"><span class="gray-600 verbal-passcode-hide cursor--pointer"
                  (click)="onShowContactPasscode($event, contact)">{{'contact-info.verbal-passcode' | translate }}
                  <span class="hidden cursor--default">
                    <mat-icon class="icon--small ml-0 vertical-align-bottom">visibility</mat-icon>
                  </span>

                  <span class="visible cursor--default">
                    <mat-icon class="icon--small ml-0 mr-1 vertical-align-bottom">visibility_off</mat-icon><span
                      class="accent">{{contact.verbalPasscode}}</span>
                  </span>
                </span>

              </p>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="d-flex">
        <div class="w-100">
          <p class="gray-600 mb-1">{{ 'contact-info.site-phone' | translate}}</p>
          <div>
            <ng-container *ngFor="let contact of siteContacts">
              <p class="mb-1" *ngIf="contact.notes"><span class="gray-600">{{ 'contact-info.notes' | translate
                  }}:</span>
                <app-localize-user-input [value]="contact.notes" [translatedValues]="contact.notesTranslated"
                  [sanitizedValues]="contact.notesSanitized">
                </app-localize-user-input>
              </p>
              <ng-container *ngFor="let phone of getPhones(contact)">
                <ng-container *ngIf="!phone.archived">
                  <div>
                    <span class="pill">
                      <span class="pr-1" initiateCall [siteId]="incident.site.id"
                        [customerId]="incident.incidentCustomerId" [incidentId]="incident.id"
                        entityType="Incident">{{phone.number}}</span>
                      <ng-container *ngIf="phone.ext">
                        <span class="mx-1">x</span>{{phone.ext}}
                      </ng-container>
                      ({{phone.type}})
                    </span>
                  </div>
                  <div class="ml-auto" *ngIf="!endUser">
                    <p class="mb-0"><span class="gray-600 verbal-passcode-hide cursor--pointer"
                        (click)="onShowContactPasscode($event, contact)">{{'contact-info.verbal-passcode' | translate }}
                        <span class="hidden cursor--default">
                          <mat-icon class="icon--small ml-0 vertical-align-bottom">visibility</mat-icon>
                        </span>

                        <span class="visible cursor--default">
                          <mat-icon class="icon--small ml-0 mr-1 vertical-align-bottom">visibility_off</mat-icon><span
                            class="accent">{{contact.verbalPasscode}}</span>
                        </span>
                      </span>
                    </p>
                  </div>
                </ng-container>
              </ng-container>

            </ng-container>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div>
          <p class="gray-600 mb-1">{{ 'contact-info.site-address' | translate}}</p>
          <p class="mb-0">{{savedSite.address.lineOne}}</p>
          <p class="mb-0" *ngIf="savedSite.address.lineTwo">{{savedSite.address.lineTwo}}</p>
          <p>{{savedSite.address.city}}, {{savedSite.address.state}}, {{savedSite.address.country}}
            {{savedSite.address.zipCode}}</p>
        </div>

        <div class="ml-auto">
          <p class="gray-600 mb-1">{{ 'contact-info.site-timezone' | translate}}</p>
          <p>{{savedSite.timezone}}</p>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="savedSite.alarmsCentralStation">
    <ng-template mat-tab-label>
      <mat-icon class="mr-3 icon--small">notifications_active</mat-icon>
      {{ 'contact-info.alarm-info' | translate }}
    </ng-template>
    <div class="contact-container h-100 overflow-y-auto px-4 py-3">
      <div class="d-flex">
        <div>
          <span class="gray-600 mr-1">{{ 'contact-info.central-station' | translate }}:</span>
          {{savedSite.alarmsCentralStation}}
        </div>
        <div class="ml-auto">
          <ng-container *ngIf="endUser; else notEndUser">
            <span class="gray-600 mr-1">{{ 'contact-info.phone' | translate }}:</span> <span class="pill">
              {{savedSite.alarmsCentralStationPhone}}
              <ng-container *ngIf="savedSite.alarmsCentralStationPhoneExt">
                <span class="mx-1">x</span>{{savedSite.alarmsCentralStationPhoneExt}}
              </ng-container>
            </span>
          </ng-container>
          <ng-template #notEndUser>
            <span class="gray-600 mr-1">{{ 'contact-info.phone' | translate }}:</span> <span class="pill">
              <span initiateCall [siteId]="incident.site.id" [customerId]="incident.incidentCustomerId"
                [incidentId]="incident.id" entityType="Incident">{{savedSite.alarmsCentralStationPhone}}</span>
              <ng-container *ngIf="savedSite.alarmsCentralStationPhoneExt">
                <span class="mx-1">x</span>{{savedSite.alarmsCentralStationPhoneExt}}
              </ng-container>
            </span>
          </ng-template>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div>
          <span class="gray-600">{{ 'contact-info.csid' | translate }}:</span> {{savedSite.alarmsCSID}}
        </div>
        <div class="ml-auto">
          <span class="gray-600">{{ 'contact-info.ar' | translate }}:</span> {{savedSite.alarmsAR}}
        </div>
        <div class="ml-auto" *ngIf="!endUser && site.alarmsVerbalPasscode">
          <span class="gray-600 verbal-passcode-hide cursor--pointer"
            (click)="onShowAlarmPasscode($event)">{{'contact-info.verbal-passcode' | translate }}
            <span class="hidden cursor--default">
              <mat-icon class="icon--small ml-0 vertical-align-bottom">visibility</mat-icon>
            </span>
            <span class="visible cursor--default">
              <mat-icon class="icon--small ml-0 mr-1 vertical-align-bottom">visibility_off</mat-icon><span
                class="accent">{{site.alarmsVerbalPasscode}}</span>
            </span>
          </span>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div>
          <span class="gray-600">{{ 'contact-info.permit-num' | translate }}:</span> {{savedSite.alarmsPermitNum}}
        </div>
        <div class="ml-auto">
          <span class="gray-600">{{ 'contact-info.permit-expiry' | translate }}:</span> {{savedSite.alarmsPermitExpires
          |
          date: 'mediumDate'}}
        </div>
      </div>
      <div class="d-flex mt-2" *ngIf="savedSite.alarmsNotes">
        <div>
          <span class="gray-600">{{ 'contact-info.notes' | translate }}:</span> <app-localize-user-input
            [value]="savedSite.alarmsNotes" [translatedValues]="savedSite.alarmsNotesTranslated"
            [sanitizedValues]="savedSite.alarmsNotesSanitized">
          </app-localize-user-input>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>