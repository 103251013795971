export const AG_GRID_LOCALE_EN: Record<string, string> = {
  'AreaColumnCombo': 'Area & Column',
  'addToLabels': 'Add ${variable} to labels',
  'addToValues': 'Add ${variable} to values',
  'andCondition': 'AND',
  'applyFilter': 'Apply',
  'april': 'April',
  'area': 'Area',
  'areaChart': 'Area',
  'areaColumnComboTooltip': 'Area & Column',
  'areaGroup': 'Area',
  'ariaChecked': 'checked',
  'ariaColumn': 'Column',
  'ariaColumnGroup': 'Column Group',
  'ariaColumnList': 'Column List',
  'ariaColumnSelectAll': 'Toggle Select All Columns',
  'ariaDateFilterInput': 'Date Filter Input',
  'ariaDefaultListName': 'List',
  'ariaDropZoneColumnComponentAggFuncSeparator': ' of ',
  'ariaDropZoneColumnComponentDescription': 'Press DELETE to remove',
  'ariaDropZoneColumnComponentSortAscending': 'ascending',
  'ariaDropZoneColumnComponentSortDescending': 'descending',
  'ariaDropZoneColumnGroupItemDescription': 'Press ENTER to sort',
  'ariaDropZoneColumnValueItemDescription': 'Press ENTER to change the aggregation type',
  'ariaFilterColumnsInput': 'Filter Columns Input',
  'ariaFilterFromValue': 'Filter from value',
  'ariaFilterInput': 'Filter Input',
  'ariaFilterList': 'Filter List',
  'ariaFilterMenuOpen': 'Open Filter Menu',
  'ariaFilterToValue': 'Filter to value',
  'ariaFilterValue': 'Filter Value',
  'ariaFilteringOperator': 'Filtering Operator',
  'ariaHidden': 'hidden',
  'ariaIndeterminate': 'indeterminate',
  'ariaInputEditor': 'Input Editor',
  'ariaLabelAggregationFunction': 'Aggregation Function',
  'ariaLabelCellEditor': 'Cell Editor',
  'ariaLabelColumnMenu': 'Column Menu',
  'ariaLabelContextMenu': 'Context Menu',
  'ariaLabelDialog': 'Dialog',
  'ariaLabelSelectField': 'Select Field',
  'ariaLabelSubMenu': 'SubMenu',
  'ariaLabelTooltip': 'Tooltip',
  'ariaMenuColumn': 'Press CTRL ENTER to open column menu.',
  'ariaPivotDropZonePanelLabel': 'Column Labels',
  'ariaRowDeselect': 'Press SPACE to deselect this row',
  'ariaRowGroupDropZonePanelLabel': 'Row Groups',
  'ariaRowSelect': 'Press SPACE to select this row',
  'ariaRowSelectAll': 'Press Space to toggle all rows selection',
  'ariaRowToggleSelection': 'Press Space to toggle row selection',
  'ariaSearch': 'Search',
  'ariaSearchFilterValues': 'Search filter values',
  'ariaSortableColumn': 'Press ENTER to sort',
  'ariaToggleCellValue': 'Press SPACE to toggle cell value',
  'ariaToggleVisibility': 'Press SPACE to toggle visibility',
  'ariaUnchecked': 'unchecked',
  'ariaValuesDropZonePanelLabel': 'Values',
  'ariaVisible': 'visible',
  'august': 'August',
  'autoRotate': 'Auto Rotate',
  'automatic': 'Automatic',
  'autosizeAllColumns': 'Autosize All Columns',
  'autosizeThiscolumn': 'Autosize This Column',
  'avg': 'Average',
  'axis': 'Axis',
  'background': 'Background',
  'barChart': 'Bar',
  'barGroup': 'Bar',
  'blank': 'Blank',
  'blanks': '(Blanks)',
  'blur': 'Blur',
  'bold': 'Bold',
  'boldItalic': 'Bold Italic',
  'bottom': 'Bottom',
  'bubble': 'Bubble',
  'bubbleTooltip': 'Bubble',
  'callout': 'Callout',
  'cancelFilter': 'Cancel',
  'categories': 'Categories',
  'category': 'Category',
  'chart': 'Chart',
  'chartDownloadToolbarTooltip': 'Download Chart',
  'chartLinkToolbarTooltip': 'Linked to Grid',
  'chartRange': 'Chart Range',
  'chartSettingsToolbarTooltip': 'Menu',
  'chartUnlinkToolbarTooltip': 'Unlinked from Grid',
  'clearFilter': 'Clear',
  'collapseAll': 'Close All Row Groups',
  'color': 'Color',
  'columnChart': 'Column',
  'columnGroup': 'Column',
  'columnLineCombo': 'Column & Line',
  'columnLineComboTooltip': 'Column & Line',
  'columns': 'Columns',
  'combinationChart': 'Combination',
  'combinationGroup': 'Combination',
  'contains': 'Contains',
  'copy': 'Copy',
  'copyWithGroupHeaders': 'Copy with Group Headers',
  'copyWithHeaders': 'Copy With Headers',
  'count': 'Count',
  'csvExport': 'CSV Export',
  'ctrlC': 'Ctrl+C',
  'ctrlV': 'Ctrl+V',
  'ctrlX': 'Ctrl+X',
  'customComboTooltip': 'Custom Combination',
  'cut': 'Cut',
  'data': 'Data',
  'dateFilter': 'Date Filter',
  'dateFormatOoo': 'yyyy-mm-dd',
  'december': 'December',
  'decimalSeparator': '.',
  'defaultCategory': '(None)',
  'doughnut': 'Doughnut',
  'doughnutTooltip': 'Doughnut',
  'empty': 'Choose One',
  'enabled': 'Enabled',
  'endsWith': 'Ends with',
  'equals': 'Equals',
  'excelExport': 'Excel Export',
  'expandAll': 'Expand All Row Groups',
  'export ': 'Export',
  'false': 'False',
  'february': 'February',
  'fillOpacity': 'Fill Opacity',
  'filterOoo': 'Filter...',
  'filteredRows': 'Filtered',
  'filters': 'Filters',
  'first': 'First',
  'firstPage': 'First Page',
  'font': 'Font',
  'format': 'Format',
  'greaterThan': 'Greater than',
  'greaterThanOrEqual': 'Greater than or equal',
  'group': 'Group',
  'groupBy': 'Group by',
  'groupFilterSelect': 'Select field:',
  'groupedAreaTooltip': 'Area',
  'groupedBar': 'Grouped',
  'groupedBarTooltip': 'Grouped',
  'groupedColumn': 'Grouped',
  'groupedColumnTooltip': 'Grouped',
  'groups': 'Row Groups',
  'height': 'Height',
  'histogramBinCount': 'Bin count',
  'histogramChart': 'Histogram',
  'histogramFrequency': 'Frequency',
  'histogramGroup': 'Histogram',
  'histogramTooltip': 'Histogram',
  'inRange': 'In range',
  'inRangeEnd': 'to',
  'inRangeStart': 'from',
  'invalidDate': 'Invalid Date',
  'invalidNumber': 'Invalid Number',
  'italic': 'Italic',
  'itemPaddingX': 'Item Padding X',
  'itemPaddingY': 'Item Padding Y',
  'itemSpacing': 'Item Spacing',
  'january': 'January',
  'july': 'July',
  'june': 'June',
  'labels': 'Labels',
  'last': 'Last',
  'lastPage': 'Last Page',
  'layoutHorizontalSpacing': 'Horizontal Spacing',
  'layoutVerticalSpacing': 'Vertical Spacing',
  'left': 'Left',
  'legend': 'Legend',
  'length': 'Length',
  'lessThan': 'Less than',
  'lessThanOrEqual': 'Less than or equal',
  'line': 'Line',
  'lineDash': 'Line Dash',
  'lineGroup': 'Line',
  'lineTooltip': 'Line',
  'lineWidth': 'Line Width',
  'loadingError': 'ERR',
  'loadingOoo': 'Loading...',
  'march': 'March',
  'markerPadding': 'Marker Padding',
  'markerSize': 'Marker Size',
  'markerStroke': 'Marker Stroke',
  'markers': 'Markers',
  'max': 'Max',
  'maxSize': 'Maximum Size',
  'may': 'May',
  'min': 'Min',
  'minSize': 'Minimum Size',
  'more': 'More',
  'navigator': 'Navigator',
  'nextPage': 'Next Page',
  'noAggregation': 'None',
  'noDataToChart': 'No data available to be charted.',
  'noMatches': 'No matches',
  'noPin': 'No Pin',
  'noRowsToShow': 'No Rows To Show',
  'none': 'None',
  'normal': 'Normal',
  'normalizedArea': '100% Stacked',
  'normalizedAreaTooltip': '100% Stacked',
  'normalizedBar': '100% Stacked',
  'normalizedBarTooltip': '100% Stacked',
  'normalizedColumn': '100% Stacked',
  'normalizedColumnTooltip': '100% Stacked',
  'notBlank': 'Not blank',
  'notContains': 'Not contains',
  'notEqual': 'Not equal',
  'november': 'November',
  'number': 'Number',
  'numberFilter': 'Number Filter',
  'october': 'October',
  'of': 'of',
  'offset': 'Offset',
  'offsets': 'Offsets',
  'orCondition': 'OR',
  'padding': 'Padding',
  'page': 'Page',
  'pageLastRowUnknown': '?',
  'paired': 'Paired Mode',
  'paste': 'Paste',
  'pie': 'Pie',
  'pieChart': 'Pie',
  'pieGroup': 'Pie',
  'pieTooltip': 'Pie',
  'pinColumn': 'Pin Column',
  'pinLeft': 'Pin Left',
  'pinRight': 'Pin Right',
  'pivotChart': 'Pivot Chart',
  'pivotChartAndPivotMode': 'Pivot Chart & Pivot Mode',
  'pivotChartRequiresPivotMode': 'Pivot Chart requires Pivot Mode enabled.',
  'pivotChartTitle': 'Pivot Chart',
  'pivotColumnGroupTotals': 'Total',
  'pivotColumnsEmptyMessage': 'Drag here to set column labels',
  'pivotMode': 'Pivot Mode',
  'pivots': 'Column Labels',
  'position': 'Position',
  'predefined': 'Predefined',
  'previousPage': 'Previous Page',
  'rangeChartTitle': 'Range Chart',
  'removeFromLabels': 'Remove ${variable} from labels',
  'removeFromValues': 'Remove ${variable} from values',
  'resetColumns': 'Reset Columns',
  'resetFilter': 'Reset',
  'right': 'Right',
  'rowDragRow': 'row',
  'rowDragRows': 'rows',
  'rowGroupColumnsEmptyMessage': 'Drag here to set row groups',
  'scatter': 'Scatter',
  'scatterGroup': 'X Y (Scatter)',
  'scatterTooltip': 'Scatter',
  'searchOoo': 'Search...',
  'secondaryAxis': 'Secondary Axis',
  'selectAll': '(Select All)',
  'selectAllSearchResults': '(Select All Search Results)',
  'selectedRows': 'Selected',
  'september': 'September',
  'series': 'Series',
  'seriesChartType': 'Series Chart Type',
  'seriesType': 'Series Type',
  'setFilter': 'Set Filter',
  'settings': 'Settings',
  'shadow': 'Shadow',
  'size': 'Size',
  'spacing': 'Spacing',
  'stackedArea': 'Stacked',
  'stackedAreaTooltip': 'Stacked',
  'stackedBar': 'Stacked',
  'stackedBarTooltip': 'Stacked',
  'stackedColumn': 'Stacked',
  'stackedColumnTooltip': 'Stacked',
  'startsWith': 'Starts with',
  'strokeOpacity': 'Line Opacity',
  'strokeWidth': 'Stroke Width',
  'sum': 'Sum',
  'textFilter': 'Text Filter',
  'thickness': 'Thickness',
  'thousandSeparator': ',',
  'ticks': 'Ticks',
  'time': 'Time',
  'title': 'Title',
  'titlePlaceholder': 'Chart title - double click to edit',
  'to': 'to',
  'tooltips': 'Tooltips',
  'top': 'Top',
  'totalAndFilteredRows': 'Rows',
  'totalRows': 'Total Rows',
  'true': 'True',
  'ungroupBy': 'Un-Group by',
  'valueAggregation': 'Value Aggregation',
  'valueColumnsEmptyMessage': 'Drag here to aggregate',
  'values': 'Values',
  'width': 'Width',
  'xOffset': 'X Offset',
  'xRotation': 'X Rotation',
  'xType': 'X Type',
  'xyChart': 'X Y (Scatter)',
  'xyValues': 'X Y Values',
  'yOffset': 'Y Offset',
  'yRotation': 'Y Rotation'
}
