import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {

  private loadingSpinner = new BehaviorSubject<boolean>(false);

  get showLoadingSpinner(): Observable<boolean> {
    return this.loadingSpinner.asObservable();
  }

  public loading(): void {
    this.loadingSpinner.next(true);
  }

  public done(): void {
    this.loadingSpinner.next(false);
  }
}
