import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-id-hover',
  templateUrl: './id-hover.component.html',
  styleUrls: ['./id-hover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdHoverComponent implements OnChanges {
  @Input() public id!: string;
  public tooltipString!: string;
  private copied!: string;

  public constructor(private clipboard: Clipboard, private translate: TranslateService) {
    this.translate.get('id-hover.copied').subscribe((res: string) => {
      this.copied = res + '!';
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) {
      this.tooltipString = this.id;
    }
  }

  public onCopy(): void {
    this.clipboard.copy(this.id);
    this.tooltipString = this.copied;
    setTimeout(() => {
      this.tooltipString = this.id;
    }, 2000);
  }
}
