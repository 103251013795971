<div>
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="accent" (click)="onIgnore()">
    {{ 'button.ignore' | translate }}</button>
  <button mat-flat-button color="primary" (click)="onRefresh()">
    {{ 'button.refresh' | translate }}
  </button>
</div>