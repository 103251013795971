import { Component, ChangeDetectionStrategy, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { CookieService } from 'ngx-cookie-service';
import { GlobalStrings } from 'src/app/shared/models/global-strings.constants';
import { environment } from 'src/environments/environment';
import { loadFull } from 'tsparticles';
import { Container, Engine } from 'tsparticles-engine';


@Component({
  selector: 'app-go',
  templateUrl: './go.component.html',
  styleUrls: ['./go.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoComponent implements AfterViewInit {
  public logoUrl;
  public buttonSpinner = true;
  public particlesUrl = environment.particlesUrl;
  private hasMira = true;

  constructor(private router: Router, private cookieService: CookieService,
    private el: ElementRef, private ref: ChangeDetectorRef) {

    const logoCookie = this.cookieService.get(`custom-logo-${window.location.hostname}`);
    if (logoCookie) {
      this.logoUrl = logoCookie;
    } else if (environment.isAiOne) {
      this.logoUrl = '../../../assets/aione.png';
    } else {
      this.logoUrl = '../../../assets/local-security.svg';
    }

    // currentAuthenticatedUser: when user comes to login page again after using hosted screen
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // Determine if we are in a state where the current authenticated user is a cognito profile
        const isExternal = user.getSignInUserSession().idToken.payload['cognito:groups']?.includes(GlobalStrings.AUTH_EXTERNAL_USER_GROUP)
        if (isExternal) {
          this.cookieService.set(GlobalStrings.AUTH_LAST_OAUTH, user.username, { sameSite: 'Strict', expires: 7 });
          void this.router.navigate(['/profiles'], { replaceUrl: true });
        } else {
          this.buttonSpinner = false;
          this.ref.markForCheck();
        }
      }).catch(() => {
        this.buttonSpinner = false;
        void Auth.federatedSignIn().catch(() => true);
        this.ref.markForCheck();
      });

  }

  public ngAfterViewInit(): void {
    this.hasMira = this.el.nativeElement.ownerDocument.body.classList.contains('mira-theme');
  }

  public onLoginClick(): void {
    this.buttonSpinner = true;
    this.ref.markForCheck();
    void Auth.federatedSignIn().catch(() => true);
  }

  public async particlesInit(engine: Engine): Promise<void> {
    await loadFull(engine);
  }

  public particlesLoaded(container: Container): void {
    if (!this.hasMira) {
      container.stop();
    }
  }

  public onGoToOld(): void {
    void this.router.navigate(['/start'], { replaceUrl: true });
  }
}
