import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from 'aws-amplify';
import { CameraAccessDetails } from 'src/app/shared/models/camera-access-details.model';
import { CxDevice } from 'src/app/shared/models/cx-incident.model';
import { LayoutSpecs } from 'src/app/shared/models/layout-specs.constants';
import { UserGroup } from 'src/app/shared/models/user-groups.model';
import { PopupImageComponent } from 'src/app/shared/pop-up/pop-up-image/popup-image.component';
import { AuthHelperService } from 'src/app/shared/services/auth-helper.service';
import { CameraService } from 'src/app/shared/services/camera.service';
import { displayTroubleshootingUrlBtn } from 'src/app/shared/utils/device.utils';
import { HealthCheckStatus } from 'src/models';
import { DeviceType } from 'src/models';
import { Camera } from 'src/models';

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CamerasComponent implements OnChanges {
  @Output() public videoUrl: EventEmitter<Camera | CxDevice> = new EventEmitter();
  @Input() public cameras: (Camera | CxDevice)[] = [];
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  public cameraUrlsMap: Map<string, string[]> = new Map();
  public userGroups = UserGroup;
  public deviceType = DeviceType;
  public healthCheckStatus = HealthCheckStatus;
  public cameraAccessDetailsMap: Map<string, CameraAccessDetails> = new Map();
  public loadingSet: Set<string> = new Set();

  constructor(private ref: ChangeDetectorRef, private cameraService: CameraService,
    private translate: TranslateService, private dialog: MatDialog,
    public authHelper: AuthHelperService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.cameras) {
      this.cameraUrlsMap.clear();
      void this.populateCameraUrlsMap();
      this.cameraAccessDetailsMap.clear();
    }
  }

  public onViewInMain(camera: Camera | CxDevice): void {
    this.videoUrl.emit(camera);
  }

  public async onResetCamera(camera: Camera): Promise<void> {
    return this.cameraService.onResetCamera(camera);
  }

  public onViewInVMS(camera: Camera | CxDevice): void {
    this.cameraService.onViewInVMS(camera);
  }

  public onGetTrueFalse(val: any): boolean {
    if (val) {
      return true;
    }
    return false;
  }

  public onViewThumbnail(url: string, isBaseline: boolean): void {
    this.translate.get(`camera-list.${isBaseline ? 'baseline' : 'current'}-thumbnail`).subscribe((text: string) => {
      const dialogConfig: MatDialogConfig = {
        maxHeight: LayoutSpecs.MODAL_MAXHEIGHT,
        width: LayoutSpecs.MODAL_WIDTH,
        maxWidth: LayoutSpecs.MODAL_MAXWIDTH,
        disableClose: false,
        hasBackdrop: true,
        autoFocus: false,
        data: { name: text, url }
      };
      this.dialog.open(PopupImageComponent, dialogConfig);
    });
  }

  public async onRunHealthCheck(camera: Camera): Promise<void> {
    await this.cameraService.onRunHealthCheck(camera);
  }

  public displayTroubleshootingUrlBtn(camera: Camera): boolean {
    return displayTroubleshootingUrlBtn(camera);
  }

  public onGetTroubleshootingUrl(camera: Camera): void {
    this.loadingSet.add(`troubleshooting-${camera.id}`);
    this.cameraService.onGetTroubleshootingUrl(camera).then((details: CameraAccessDetails | void) => {
      if (details) {
        this.cameraAccessDetailsMap.set(camera.id, details);
        this.ref.markForCheck();
      }
    }).finally(() => this.loadingSet.delete(`troubleshooting-${camera.id}`));
  }

  private async populateCameraUrlsMap(): Promise<void> {
    // Loop through the cameras and get their storage url values
    for (const c of this.cameras) {
      if (c.baselineThumbnailFilename) {
        const baseline = await Storage.get(`${this.getFilePath(c)}/${c.baselineThumbnailFilename}`);
        const signedURLs = [baseline];
        if (c.currentThumbnailFilename) {
          const current = await Storage.get(`${this.getFilePath(c)}/${c.currentThumbnailFilename}`);
          signedURLs.push(current);
        }
        this.cameraUrlsMap.set(c.id, signedURLs);
      }
    }
    this.ref.markForCheck();
  }

  private getFilePath(camera: Camera | CxDevice): string {
    return `${camera.monitoringCenterId}/${camera.customerId}/${camera.siteId}/devices/${camera.id}`;
  }
}
