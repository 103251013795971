import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GoComponent } from './pages/go/go.component';
import { HomeComponent } from './pages/home/home.component';
import { LogoutComponent } from './pages/home/logout/logout.component';
import { MobileLoginRedirectComponent } from './pages/home/mobile-login-redirect/mobile-login-redirect.component';
import { ProfilesComponent } from './pages/home/profiles/profiles.component';
import { UniversalQueueAuthComponent } from './pages/home/universal-queue-auth/universal-queue-auth.component';
import { AuthGenericGuard } from './shared/services/auth-generic-guard.service';
import { AuthGuard } from './shared/services/auth-guard.service';


const routes: Routes = [
  {
    path: 'profiles',
    component: ProfilesComponent,
    canActivate: [AuthGenericGuard]
  },
  {
    path: 'universal-queue-auth',
    component: UniversalQueueAuthComponent,
    canActivate: [AuthGenericGuard]
  },
  {
    path: 'login',
    component: GoComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'start',
    component: HomeComponent,
  },
  {
    path: 'mobile',
    children: [
      {
        path: '**',
        component: MobileLoginRedirectComponent
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./shared/pages/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard, AuthGenericGuard],
    canActivateChild: [AuthGuard, AuthGenericGuard],
  },
  {
    path: 'share',
    loadChildren: () => import('./pages/public/incident-share/incident-share.module').then(m => m.IncidentShareModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
