import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { GridBehavior } from 'src/app/services/graphql.service';

import { CognitoCustomerUser } from '../models/cognito-customer-user.model';
import { UserGroup } from '../models/user-groups.model';
import { Language, User } from '../models/user.model';
import { UserStoreService } from '../store/user-store.service';

import { AuthGenericService } from './auth-generic.service';

@Injectable()
export class AuthHelperService {

  constructor(private userStore: UserStoreService, private authGenericService: AuthGenericService,
    private router: Router) { }

  public getApplicationSecurityGroup(cognitoGroups: any[]): UserGroup {
    let group: UserGroup = UserGroup.Operators;
    // Find the user's group that corresponds to LS groups and not monitoring center groups
    for (const groupDtl of cognitoGroups) {
      let groupName = groupDtl;
      if (!(typeof groupName === 'string')) {
        groupName = groupName.GroupName;
      }
      if (Object.values(UserGroup).includes(groupName)) {
        group = UserGroup[groupName as keyof typeof UserGroup];
      }
    }
    return group;
  }

  public parseCognitoUser(cognitoUser: any, group?: UserGroup): User {
    if (!group) {
      group = this.getApplicationSecurityGroup(cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:groups']);
    }
    const attributes = cognitoUser.UserAttributes ? cognitoUser.UserAttributes
      : cognitoUser.Attributes ? cognitoUser.Attributes
        : Object.entries(cognitoUser.attributes).map(([key, value]) => ({ Name: key, Value: value }));
    const user = this.parseUser(cognitoUser, group, this.parseUserAttributes(attributes));
    return user;
  }

  public parseCognitoCustomerUser(cognitoUser: any): CognitoCustomerUser {
    const attributes = this.parseUserAttributes(cognitoUser.UserAttributes ?? cognitoUser.Attributes);

    return new CognitoCustomerUser({
      username: cognitoUser.Username ? cognitoUser.Username : cognitoUser.username,
      firstName: attributes['given_name'] ?? 'CHANGE ME',
      lastName: attributes['family_name'] ?? 'CHANGE ME',
      email: attributes['email'],
      phone: attributes['phone_number'],
      enabled: cognitoUser.Enabled,
      readonly: cognitoUser.readonly ?? true
    });
  }

  public userInGroup(userGroups: UserGroup[]): boolean {
    if (!this.userStore.user) {
      return false;
    }
    return userGroups.includes(this.userStore.user.group);
  }

  private parseUserAttributes(userAttributes: { 'Name': string, 'Value': string }[]): { [key: string]: string } {
    const attributes: { [key: string]: string } = {};
    for (const attr of userAttributes) {
      attributes[attr.Name] = attr.Value;
    }
    return attributes;
  }

  public onLogout(): void {
    this.userStore.onLogout();
    void Auth.signOut().then(() => {
      this.authGenericService.onLogout();
      void this.router.navigate(['/login']);
    });
  }

  public onProfileLogout(): void {
    this.userStore.onLogout();
    void Auth.signOut();
    void this.router.navigate(['/profiles']);
  }

  private parseUser(user: any, group: UserGroup, userAttributes: { [key: string]: string }): User {
    const clientListString = userAttributes['custom:clientList'];
    let clientList: string[] = [];
    if (clientListString && clientListString.length) {
      clientList = clientListString.split(',');
    }
    let name = userAttributes['name'];
    if (!name) {
      // Determine if we have a given_name and family_name
      const firstName = userAttributes['given_name'];
      const lastName = userAttributes['family_name'];
      if (firstName && lastName) {
        name = `${firstName} ${lastName}`;
      }
    }
    return new User({
      username: user.Username ? user.Username : user.username,
      name,
      email: userAttributes['email'],
      phone: userAttributes['phone_number'],
      monitoringCenterId: userAttributes['custom:monitoringCenterId'],
      clientList,
      enabled: user.Enabled,
      integratorId: userAttributes['custom:integratorId'],
      group,
      preferredLanguage: userAttributes['locale'] ? (userAttributes['locale'] as Language) : Language.ENGLISH,
      autoAssign: userAttributes['custom:incidentAutoAssign'] && parseInt(userAttributes['custom:incidentAutoAssign'], 10) === 1
        ? true : false,
      gridEnabled: userAttributes['custom:gridEnabled'] && parseInt(userAttributes['custom:gridEnabled'], 10) === 1
        ? true : false,
      gridBehavior: userAttributes['custom:gridBehavior'] ? userAttributes['custom:gridBehavior'] as GridBehavior : undefined
    });
  }
}
