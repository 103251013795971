import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from '@sentry/angular-ivy';
import Amplify, { Auth } from 'aws-amplify';

import { AppModule } from './app/app.module';
import aws_exports from './aws-exports';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

const LOCALHOST_ORIGIN = 'http://localhost:4200';
const LOCALHOST_SWAP = 'https://app.cname.pink';

Amplify.configure(aws_exports);
Amplify.configure({
  aws_cloud_logic_custom: [
    {
      name: 'AdminQueries',
      endpoint: `${window.origin === LOCALHOST_ORIGIN ? LOCALHOST_SWAP : window.origin}/api/admin`,
      region: 'us-east-1',
    },
    {
      name: 'localSecurityPublicAPI',
      endpoint: `${window.origin === LOCALHOST_ORIGIN ? LOCALHOST_SWAP : window.origin}/api/public`,
      region: 'us-east-1',
    },
    {
      name: 'localguard',
      endpoint: `${window.origin === LOCALHOST_ORIGIN ? LOCALHOST_SWAP : window.origin}/api/guard`,
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint: `https://${environment.amplifyEnv}.graphql-api.cname.bot/graphql`,
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken()
      };
    },
  },
  oauth: {
    domain: environment.hostedAuthUrl,
    'scope': [
      'email',
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: `${window.location.origin}/login`,
    redirectSignOut: `${window.location.origin}/login`,
    responseType: 'code',
  },
  Auth: {
    authenticationFlowType: 'CUSTOM_AUTH',
    region: aws_exports.aws_project_region,
    userPoolId: aws_exports.aws_user_pools_id,
    userPoolWebClientId: aws_exports.aws_user_pools_web_client_id,
  },
});
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

// Sentry.init({
//   dsn: environment.sentryDsn,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       maskAllInputs: false,
//       blockAllMedia: false,
//       networkDetailAllowUrls: [
//         window.location.origin,
//         aws_exports.aws_cloud_logic_custom[0].endpoint,
//         aws_exports.aws_cloud_logic_custom[1].endpoint,
//         aws_exports.aws_cloud_logic_custom[2].endpoint,
//         aws_exports.aws_appsync_graphqlEndpoint,
//       ],
//     }),
//     new Sentry.BrowserProfilingIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//  This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production
//   replaysSessionSampleRate: 1.0,
//   // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   replaysOnErrorSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
  if ((module as any).hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}

