import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { LambdaResponse } from 'src/models';


const API_NAME = 'localguard';

@Injectable()
export class LocalGuardApiService {

  public async getUserFlowSignature(identifier: string): Promise<string | undefined> {
    const path = `/userflow/signature/${identifier}`;
    const headers = await this.getHeaders();
    const signature = await API.get(API_NAME, path, { headers }).then((response: any) => {
      return response.signature as string;
    }).catch(error => {
      // We want to fail silently, but still see the error output
      console.error(error);
      return undefined
    });

    return signature;
  }

  public async getCobrowseAgentSignature(): Promise<LambdaResponse | undefined> {
    const path = '/cobrowse/token';
    const headers = await this.getHeaders();
    try {
      const token = await API.get(API_NAME, path, { headers });
      return {
        statusCode: 200,
        body: token
      };
    } catch (e: any) {
      return {
        statusCode: e.response.status,
        body: e.response.data ? JSON.parse(e.response.data) : undefined
      }
    }
  }

  private async getHeaders(): Promise<any> {
    // Cannot be done through an interceptor because the aws-amplify doesn't use HttpClient
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    };
  }

}