import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-confirm-with-reason',
  templateUrl: './popup-confirm-with-reason.component.html',
  styleUrls: ['./popup-confirm-with-reason.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupConfirmWithReasonComponent {
  public ctrl: UntypedFormControl = this.fb.control(null, Validators.required);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, title: string, label: string },
    private dialogRef: MatDialogRef<any>, private fb: UntypedFormBuilder) { }

  public onConfirm(): void {
    this.dialogRef.close({
      confirmed: true,
      reason: this.ctrl.value
    });
  }

  public onCancel(): void {
    this.dialogRef.close({
      confirmed: false
    });
  }

}
