import { Injectable } from '@angular/core';

const LOCALHOST_ORIGIN = 'http://localhost:4200';
const LOCALHOST_SWAP = 'https://app.cname.pink';

@Injectable()
export class RestUrlService {

  public getGuardApiUrl(): string {
    return `${this.getBase()}/api/guard`;
  }

  public getAdminQueriesApiUrl(): string {
    return `${this.getBase()}/api/admin`;
  }

  public getPublicApiUrl(): string {
    return `${this.getBase()}/api/public`;
  }

  private getBase(): string {
    return window.origin === LOCALHOST_ORIGIN ? LOCALHOST_SWAP : window.origin;
  }

}