import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { Storage } from 'aws-amplify';
import { CxSite } from 'src/app/shared/models/cx-incident.model';
import { UserStoreService } from 'src/app/shared/store/user-store.service';
import { Site } from 'src/models';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteMapComponent implements OnChanges, OnDestroy {
  @Input() public site!: Site | CxSite;
  public siteMapUrl?: string;
  public showLoadingSpinner = false;
  private openedWindow: Window | null = null;

  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler(_event: Event): void {
    if (this.openedWindow && !this.openedWindow.closed) {
      this.openedWindow.close();
    }
  }

  constructor(private userStore: UserStoreService, private ref: ChangeDetectorRef) { }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.site) {
      await this.onLoadSiteMap();
    }
  }

  public ngOnDestroy(): void {
    if (this.openedWindow && !this.openedWindow.closed) {
      this.openedWindow.close();
    }
  }

  public openImageInNewWindow(imageUrl: string): void {
    this.showLoadingSpinner = true;
    this.ref.markForCheck();

    const tempImage = new Image();
    tempImage.src = imageUrl;

    tempImage.onload = () => {
      const imageWidth = tempImage.width;
      const imageHeight = tempImage.height;

      const targetWidth = 800;
      const targetHeight = 800;

      // Calculate the aspect ratio of the image
      const aspectRatio = imageWidth / imageHeight;

      let adjustedWidth;
      let adjustedHeight;

      // Calculate adjusted dimensions while considering both aspect ratio and target dimensions
      if (aspectRatio > 1) {
        // Image is wider than tall
        adjustedWidth = targetWidth;
        adjustedHeight = adjustedWidth / aspectRatio;
      } else {
        // Image is taller than wide or square
        adjustedHeight = targetHeight;
        adjustedWidth = adjustedHeight * aspectRatio;
      }

      const windowWidth = targetWidth;
      const windowHeight = targetHeight;

      this.openedWindow = window.open('', '_blank', `width=${windowWidth},height=${windowHeight}`);

      if (this.openedWindow) {
        this.openedWindow.document.write(`
        <script src="https://unpkg.com/leaflet/dist/leaflet.js"></script>
        <link rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
      `);

        this.openedWindow.document.write('<div id="map" style="width:100%; height:100%"></div>');

        this.openedWindow.document.write(`
        <script>
          const map = L.map('map', { 
            attributionControl: false, 
            minZoom: 0,
            maxZoom: 4,
            crs: L.CRS.Simple, 
            center: [0, 0], 
            zoom: 0, 
            maxBoundsViscosity: 1,
          });

          L.imageOverlay('${imageUrl}', [[0, 0], [${adjustedHeight / 1.5}, ${adjustedWidth / 1.5}]]).addTo(map);
          map.setMaxBounds(new L.LatLngBounds([0,0], [${adjustedHeight / 1.5}, ${adjustedHeight / 1.5}]));
        </script>
      `);
      }

      this.showLoadingSpinner = false;
      this.ref.markForCheck();
    };
  }


  private async onLoadSiteMap(): Promise<void> {
    const storageFolder =
      `${this.userStore.monitoringCenterId ?? this.site.monitoringCenterId}/${this.site.customerId}/${this.site.id}/sitemaps/`;
    if (this.site.hasSiteMap) {
      let archivedSiteMaps: string[] = [];
      const list = await Storage.list(storageFolder);
      if (list.filter((a: any) => a.key.endsWith('.archived')).length > 0) {
        const archivedFile = await Storage.get(`${storageFolder}.archived`, { download: true });
        if (archivedFile) {
          archivedSiteMaps = (await (archivedFile as any).Body.text()).split(',');
        }
      }
      const siteMap = list.filter((a: any) => archivedSiteMaps.indexOf(a.key) === -1
        && !a.key.endsWith('.archived'))[0];
      if (siteMap) {
        this.siteMapUrl = await Storage.get(siteMap.key!);
        this.ref.markForCheck();
      }
    }
  }

}
