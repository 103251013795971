<div>
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ data.label }}</mat-label>
    <textarea matInput [formControl]="ctrl" required></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="accent" (click)="onConfirm()" [disabled]="ctrl.invalid">{{ 'button.submit' |
    translate }}</button>
  <button mat-flat-button color="primary" (click)="onCancel()">{{ 'button.cancel' | translate }}</button>
</div>