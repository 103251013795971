import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Customer } from 'src/app/services/graphql.service';

import { UserGroup } from '../models/user-groups.model';
import { Language, User } from '../models/user.model';
import { LocaleService } from '../services/locale.service';

@Injectable()
export class UserStoreService {

  private _user?: User;
  private _monitoringCenterId?: string;
  private _userList?: User[];
  private _monitoringCenter?: Customer;
  private _integrator?: Customer;
  private _integratorId?: string;
  private _callingEnabled = false;

  public userMonitoringCenterUpdated: Subject<void> = new Subject();
  public userUpdated: Subject<void> = new Subject();

  constructor(private localeService: LocaleService) { }

  public get user(): User | undefined {
    return this._user;
  }

  public set user(user: User | undefined) {
    this._user = user;
    if (user) {
      this._monitoringCenterId = user?.monitoringCenterId;
      this.localeService.setLocaleFromUserLanguage(this.user?.preferredLanguage ?? Language.ENGLISH);
      this._integratorId = user?.integratorId;
    } else {
      this._monitoringCenterId = undefined;
      this._integrator = undefined;
    }
    this.userUpdated.next();
  }

  public get monitoringCenterId(): string {
    return this._monitoringCenterId!;
  }

  public get userList(): User[] | undefined {
    return this._userList;
  }

  public set userList(userList: User[] | undefined) {
    this._userList = userList;
  }

  public set monitoringCenter(monitoringCenter: Customer | undefined) {
    // The monitoringCenter obj will be undefined if it's local-security
    // Prefer getMonitoringCenterId when possible
    this._monitoringCenter = monitoringCenter;
    if (monitoringCenter) {
      this._callingEnabled = monitoringCenter.softphoneEnabled ? true : false;
    }
    this.userMonitoringCenterUpdated.next();
    this.userUpdated.next();
  }

  public get monitoringCenter(): Customer | undefined {
    // The monitoringCenter obj will be undefined if it's local-security
    // Prefer getMonitoringCenterId when possible
    return this._monitoringCenter;
  }

  public set integrator(integrator: Customer | undefined) {
    // The integrator obj will be undefined if the user isn't an integrator admin
    this._integrator = integrator;
    this.userUpdated.next();
  }

  public get integrator(): Customer | undefined {
    // The integrator obj will be undefined if the user isn't an integrator admin
    return this._integrator;
  }

  public get integratorId(): string | undefined {
    return this._integratorId
  }

  public get callingEnabled(): boolean {
    return this._callingEnabled && this._user?.group !== UserGroup.IntegratorAdmin && this._user?.group !== UserGroup.Customer;
  }

  public get rapidSOSEnabled(): boolean {
    return this._monitoringCenter?.rapidSOSIntegration
      && this._monitoringCenter.rapidSOSIntegration.webhookEnabled
      ? true : false;
  }

  public onLogout(): void {
    this._user = undefined;
    this._userList = undefined;
    this._monitoringCenter = undefined;
    this._callingEnabled = false;
  }
}
