<ng-particles id="tsparticles-el" (particlesLoaded)="particlesLoaded($event)" [url]="particlesUrl"
  [particlesInit]="particlesInit"></ng-particles>
<div class="go-component d-flex flex-column align-items-center justify-content-center h-100 w-100">
  <img [src]="logoUrl" style="max-width:400px;padding-top:50px" alt="logo" id="login-logo">
  <button mat-flat-button color="accent" (click)="onLoginClick()" class="mt-4" [class.button-spinner]="buttonSpinner"
    [disabled]="buttonSpinner">
    {{ 'button.lets-go' | translate}}
    <mat-icon>arrow_forward</mat-icon>
  </button>

  <p class="accent mt-5" *ngIf="!buttonSpinner">
    <span (click)="onGoToOld()" class="underline-hover cursor--pointer d-flex align-items-center">
      <mat-icon class="icon--small-no-m">arrow_backward</mat-icon>
      {{ 'home.back-to-legacy-login' | translate }}
    </span>
  </p>
</div>