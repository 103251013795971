<ng-container *ngIf="sanitizedUrl; else noUrl">
  <div class="site-video-container" *ngIf="sanitizedUrl">
    <iframe width="560" height="315" [src]="sanitizedUrl" frameborder="0" allow="camera;microphone *"
      allowfullscreen></iframe>
  </div>
</ng-container>
<ng-template #noUrl>
  <div class="d-flex h-100 w-100 justify-content-center align-items-center">
    {{ 'simple-video-viewer.video-viewer-hint' | translate }}
  </div>
</ng-template>