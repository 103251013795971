import { Camera, TalkdownType } from 'src/app/services/graphql.service';
import { Camera as CameraModel } from 'src/models';

export function getTalkdownDevices(cameras: Camera[] | CameraModel[]): (Camera | CameraModel)[] {
  const talkdownCameras = [];
  for (const cam of cameras) {
    if (getDeviceHasTalkdown(cam)) {
      talkdownCameras.push(cam);
    }
  }
  return talkdownCameras;
}

export function getDeviceHasTalkdown(camera: Camera | CameraModel): boolean {
  if (camera.talkdownInstructions
    && (camera.talkdownInstructions?.talkdownType === TalkdownType.PlayMessage
      || (camera.talkdownInstructions?.talkdownType === TalkdownType.Manual
        && (camera.talkdownInstructions.note && camera.talkdownInstructions.note.trim().length > 0)
        || camera.talkdownInstructions.url || camera.talkdownInstructions.phone))) {
    return true;
  }
  return false;
}

export function getDeviceHasTalkdownNote(camera: Camera | CameraModel): boolean {
  return getDeviceHasTalkdown(camera) && camera.talkdownInstructions!.note !== null && camera.talkdownInstructions!.note !== undefined
    && camera.talkdownInstructions!.note.trim().length > 0;
}

export function getDeviceHasTalkdownUrl(camera: Camera | CameraModel): boolean {
  return getDeviceHasTalkdown(camera) && camera.talkdownInstructions!.url !== null && camera.talkdownInstructions!.url !== undefined;
}

export function getDeviceHasTalkdownPhone(camera: Camera | CameraModel): boolean {
  return getDeviceHasTalkdown(camera) && camera.talkdownInstructions!.phone !== null && camera.talkdownInstructions!.phone !== undefined
    && camera.talkdownInstructions!.phone.trim().length > 0;
}

export function displayTroubleshootingUrlBtn(camera: Camera | CameraModel): boolean {
  return camera.phpRegistrationCode !== null && camera.phpRegistrationCode !== undefined;
}