import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { APIService, AuthCXUserAction, CXUserDetailsInput } from 'src/app/services/graphql.service';
import { CognitoCustomerUser } from 'src/app/shared/models/cognito-customer-user.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { CustomerUserPermission, LambdaResponse, Language } from 'src/models';

import { UserGroup } from '../../../shared/models/user-groups.model';
import { User } from '../../../shared/models/user.model';
import { AuthHelperService } from '../../../shared/services/auth-helper.service';
import { UserStoreService } from '../../../shared/store/user-store.service';


@Injectable()
export class CognitoUserService {

  public API_NAME = 'AdminQueries';

  constructor(private authHelper: AuthHelperService, private userStore: UserStoreService,
    private api: APIService, private errorHandler: ErrorService,) { }

  public async getUserByUsername(userName: string): Promise<User> {
    const userPath = '/getUser';
    const userGroupPath = '/listGroupsForUser';
    const headers = await this.getHeaders();
    let user = new User();

    await Promise.all([
      API.get(this.API_NAME, `${userPath}?username=${encodeURIComponent(userName)}`, { headers }),
      API.get(this.API_NAME, `${userGroupPath}?username=${encodeURIComponent(userName)}`, { headers }),
    ]).then(([userRsp, groupRsp]) => {
      const group = this.authHelper.getApplicationSecurityGroup(groupRsp.Groups);
      user = this.authHelper.parseCognitoUser(userRsp, group);
    });

    return user;
  }

  public async getOrCreateCustomerUserByEmail(email: string, customerId: string): Promise<{
    profile: CognitoCustomerUser | { username: string, readonly: boolean },
    customerUser: CustomerUserPermission
  } | null> {

    return this.api.AuthManageCXUser(AuthCXUserAction.CreateUser, customerId, { email }).then((rsp: LambdaResponse) => {
      if (rsp.statusCode !== 200) {
        throw rsp.body;
      }
      const body = JSON.parse(rsp.body!);
      return {
        customerUser: body.cxUser,
        profile: body.profile
      }
    }).catch(e => {
      this.errorHandler.handleError(e);
      return null;
    });

  }

  public async onGetCognitoCustomerProfile(email: string): Promise<CognitoCustomerUser | null> {
    // Get the cognito user and return
    const customerUserPath = '/getCustomerUser';
    const headers = await this.getHeaders();

    const cognitoUser = await API.get(this.API_NAME, `${customerUserPath}?email=${encodeURIComponent(email)}`, { headers });
    if (cognitoUser) {
      cognitoUser.readonly = false;
      return this.authHelper.parseCognitoCustomerUser(cognitoUser);
    }
    return null;
  }

  public async updateCustomerUser(email: string, user: CognitoCustomerUser, sitePermissions: any, customerId: string): Promise<void> {
    const userInput: CXUserDetailsInput = {
      email,
      sitePermissions
    };
    if (user) {
      userInput.firstName = user.firstName;
      userInput.lastName = user.lastName;
      userInput.phone = user.phone;
    }
    return this.api.AuthManageCXUser(AuthCXUserAction.UpdateUser, customerId, userInput).then((rsp: LambdaResponse) => {
      if (rsp.statusCode !== 200) {
        this.errorHandler.handleError(rsp.body);
      }
    }).catch(e => this.errorHandler.handleError(e));
  }

  public async listGroups(): Promise<string[]> {
    const path = '/listGroups';
    const headers = await this.getHeaders();
    const groups: string[] = [];

    return await API.get(this.API_NAME, path, { headers }).then((resp: any) => {
      for (const g of resp.Groups) {
        groups.push(g.GroupName);
      }
      return groups;
    });

  }

  public async listUsers(): Promise<User[]> {
    const headers = await this.getHeaders();
    const path = '/listUsersInGroup';
    const users: User[] = [];

    await Promise.all([
      API.get(this.API_NAME, `${path}?groupname=${UserGroup.Owner}`, { headers }),
      API.get(this.API_NAME, `${path}?groupname=${UserGroup.Controller}`, { headers }),
      API.get(this.API_NAME, `${path}?groupname=${UserGroup.BillingAdmin}`, { headers }),
      API.get(this.API_NAME, `${path}?groupname=${UserGroup.Admin}`, { headers }),
      API.get(this.API_NAME, `${path}?groupname=${UserGroup.IntegratorAdmin}`, { headers }),
      API.get(this.API_NAME, `${path}?groupname=${UserGroup.SuperOperators}`, { headers }),
      API.get(this.API_NAME, `${path}?groupname=${UserGroup.Operators}`, { headers }),
    ]).then(([_owners, _controllers, _billingAdmins, _admins, _integratorAdmins, _superoperators, _operators]) => {
      // Loop through the groups and put them into groups by username
      // We require that all users be part of a group and only one group
      users.push(...this.parseUsers(_owners.Users));
      users.push(...this.parseUsers(_controllers.Users));
      users.push(...this.parseUsers(_billingAdmins.Users));
      users.push(...this.parseUsers(_admins.Users));
      users.push(...this.parseUsers(_integratorAdmins.Users));
      users.push(...this.parseUsers(_superoperators.Users));
      users.push(...this.parseUsers(_operators.Users));
    });

    return users;
  }

  public async listUsersByMonitoringCenter(monitoringCenterId: string): Promise<User[]> {
    if (this.userStore.userList) {
      return this.userStore.userList;
    }
    const headers = await this.getHeaders();
    const path = '/listUsersByMonitoringCenter';
    const users: User[] = [];
    await API.get(this.API_NAME, `${path}?monitoringCenterId=${monitoringCenterId}`, { headers }).then(((rawUsers: any) => {
      users.push(...this.parseUsers(rawUsers));
    }));
    this.userStore.userList = users;
    return users;
  }

  public async listCustomerUsersByCustomer(customerId: string): Promise<CustomerUserPermission[]> {
    return this.api.AuthManageCXUser(AuthCXUserAction.ListUsers, customerId).then((rsp: LambdaResponse) => {
      if (rsp.statusCode !== 200) {
        this.errorHandler.handleError(rsp.body);
        return [];
      } else {
        const users = JSON.parse(rsp.body!);
        return users as CustomerUserPermission;
      }
    }).catch(e => {
      this.errorHandler.handleError(e);
      return []
    }) as Promise<CustomerUserPermission[]>;
  }

  public async createUser(user: User): Promise<User> {
    const createUserPath = '/createUser';
    const headers = await this.getHeaders();
    const userData = {
      body: {
        username: user.username,
        userAttributes: this.generateUserAttributes(user)
      },
      headers
    };
    await API.post(this.API_NAME, createUserPath, userData);

    // Add the user to the group
    const addToGroupPath = '/addUserToGroup';
    const groupData = {
      body: {
        username: user.username,
        groupname: user.group
      },
      headers
    };
    await API.post(this.API_NAME, addToGroupPath, groupData);

    return user;
  }

  public async updateUserGroup(oldGroup: UserGroup, user: User): Promise<void> {
    const removePath = '/removeUserFromGroup';
    const addPath = '/addUserToGroup';
    const headers = await this.getHeaders();

    const removeData = {
      body: {
        username: user.username,
        groupname: oldGroup,
        newgroup: user.group
      },
      headers
    };

    const addData = {
      body: {
        username: user.username,
        groupname: user.group
      },
      headers
    };

    await Promise.all([
      API.post(this.API_NAME, removePath, removeData),
      API.post(this.API_NAME, addPath, addData)
    ]);
  }

  public async changeAdminMC(oldMC: string, newMC: string, user: User): Promise<void> {
    const path = '/updateAdminMonitoringCenterGroup';
    const headers = await this.getHeaders();

    const data = {
      body: {
        username: user.username,
        oldMC,
        newMC
      },
      headers
    };

    await API.post(this.API_NAME, path, data);
  }

  public async updateUserStatus(username: string, enabled: boolean): Promise<void> {
    const headers = await this.getHeaders();
    let path: string;
    if (enabled) {
      // The user was disabled so enable them
      path = '/enableUser';
    } else {
      // The user was enabled so disable them
      path = '/disableUser';
    }
    const data = {
      body: {
        username
      },
      headers
    };
    await API.post(this.API_NAME, path, data);
  }

  public async updateCustomerUserStatus(username: string, customerId: string, disabled: boolean): Promise<void> {
    await this.api.AuthManageCXUser(AuthCXUserAction.DisableUser, customerId, { email: username, disabled }).then((rsp: LambdaResponse) => {
      if (rsp.statusCode !== 200) {
        this.errorHandler.handleError(rsp.body);
      }
    }).catch(e => this.errorHandler.handleError(e));
  }

  public async deleteCustomerUser(username: string, customerId: string): Promise<void> {
    await this.api.AuthManageCXUser(AuthCXUserAction.DeleteUser, customerId, { email: username }).then((rsp: LambdaResponse) => {
      if (rsp.statusCode !== 200) {
        this.errorHandler.handleError(rsp.body);
      }
    }).catch(e => this.errorHandler.handleError(e));
  }

  public async updateUserAttributes(user: User): Promise<void> {
    const userAttributes = this.generateUserAttributes(user);
    await this.sendUserAttUpdates(userAttributes, user.username);
  }

  public async updateUserMFAOptions(username: string, smsEnabled: boolean, softwareEnabled: boolean,
    softwarePreviouslyEnabled: boolean): Promise<void> {
    const headers = await this.getHeaders();
    const path = '/updatemfa'
    const data = {
      body: {
        username,
        smsEnabled,
        softwareEnabled,
        softwarePreviouslyEnabled
      },
      headers
    };
    await API.post(this.API_NAME, path, data);
  }

  private async sendUserAttUpdates(attributes: { 'Name': string, 'Value': string | number }[], username: string): Promise<void> {
    const path = '/updateUserAttributes';
    const headers = await this.getHeaders();
    const data = {
      body: {
        username,
        userAttributes: attributes
      },
      headers
    };
    await API.post(this.API_NAME, path, data);
  }

  private async getHeaders(): Promise<any> {
    // Cannot be done through an interceptor because the aws-amplify doesn't use HttpClient
    return {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    };
  }

  private parseUsers(respUsers: any[]): User[] {
    const users: User[] = [];

    for (const u of respUsers) {
      const user = this.authHelper.parseCognitoUser(u, u.Group);
      users.push(user);
    }

    return users;
  }

  private generateUserAttributes(user: User): { 'Name': string, 'Value': string | number }[] {
    return [
      { Name: 'name', Value: user.name },
      { Name: 'email', Value: user.email },
      { Name: 'phone_number', Value: user.phone },
      { Name: 'locale', Value: user.preferredLanguage ?? Language.ENGLISH },
      { Name: 'custom:monitoringCenterId', Value: user.monitoringCenterId },
      { Name: 'custom:mcOwnerAccess', Value: user.group === UserGroup.Owner ? `${user.monitoringCenterId}-Owner` : '' },
      { Name: 'custom:mcControllerAccess', Value: user.group === UserGroup.Controller ? `${user.monitoringCenterId}-Controller` : '' },
      { Name: 'custom:mcIntAdminAccess', Value: user.group === UserGroup.IntegratorAdmin ? `${user.monitoringCenterId}-IntAdmin` : '' },
      {
        Name: 'custom:mcBillingAdminAccess',
        Value: user.group === UserGroup.BillingAdmin ? `${user.monitoringCenterId}-BillingAdmin` : ''
      },
      { Name: 'custom:clientList', Value: user.clientList.toString() },
      { Name: 'email_verified', Value: 'true' },
      { Name: 'phone_number_verified', Value: 'true' },
      { Name: 'custom:integratorId', Value: user.integratorId ? user.integratorId : '' },
      { Name: 'custom:incidentAutoAssign', Value: user.autoAssign ? '1' : '0' },
      { Name: 'custom:gridEnabled', Value: user.gridEnabled ? '1' : '0' },
      { Name: 'custom:gridBehavior', Value: user.gridBehavior ?? '' }
    ];
  }
}