export const AG_GRID_LOCALE_NL: Record<string, string> = {
  'AreaColumnCombo': 'Gebied en kolom',
  'addToLabels': '${variable} aan labels toevoegen',
  'addToValues': '${variable} aan waarden toevoegen',
  'andCondition': 'en',
  'applyFilter': 'Toepassen',
  'april': 'April',
  'area': 'Gebied',
  'areaChart': 'Gebied',
  'areaColumnComboTooltip': 'Gebied en kolom',
  'areaGroup': 'Gebied',
  'ariaChecked': 'gecontroleerd',
  'ariaColumn': 'Kolom',
  'ariaColumnGroup': 'Kolomgroep',
  'ariaColumnList': 'Kolomlijst',
  'ariaColumnSelectAll': 'Schakel Alle kolommen selecteren in of uit',
  'ariaDateFilterInput': 'Invoer van datumfilter',
  'ariaDefaultListName': 'Lijst',
  'ariaDropZoneColumnComponentAggFuncSeparator': ' van ',
  'ariaDropZoneColumnComponentDescription': 'Druk op DELETE om te verwijderen',
  'ariaDropZoneColumnComponentSortAscending': 'oplopend',
  'ariaDropZoneColumnComponentSortDescending': 'aflopend',
  'ariaDropZoneColumnGroupItemDescription': 'Druk op ENTER om te sorteren',
  'ariaDropZoneColumnValueItemDescription': 'Druk op ENTER om het aggregatietype te wijzigen',
  'ariaFilterColumnsInput': 'Invoer van kolommen filteren',
  'ariaFilterFromValue': 'Filteren op basis van waarde',
  'ariaFilterInput': 'Filter-invoer',
  'ariaFilterList': 'Filterlijst',
  'ariaFilterMenuOpen': 'Filtermenu openen',
  'ariaFilterToValue': 'Filteren op waarde',
  'ariaFilterValue': 'Filterwaarde',
  'ariaFilteringOperator': 'Filteroperator',
  'ariaHidden': 'Verborgen',
  'ariaIndeterminate': 'onbepaald',
  'ariaInputEditor': 'Invoerbewerker',
  'ariaLabelAggregationFunction': 'Aggregatiefunctie',
  'ariaLabelCellEditor': 'Celeditor',
  'ariaLabelColumnMenu': 'Kolommenu',
  'ariaLabelContextMenu': 'Contextmenu',
  'ariaLabelDialog': 'Dialoog',
  'ariaLabelSelectField': 'Veld selecteren',
  'ariaLabelSubMenu': 'Submenu',
  'ariaLabelTooltip': 'Tooltip',
  'ariaMenuColumn': 'Druk op CTRL ENTER om het kolommenu te openen.',
  'ariaPivotDropZonePanelLabel': 'Kolomlabels',
  'ariaRowDeselect': 'Druk op SPACE om deze rij te deselecteren',
  'ariaRowGroupDropZonePanelLabel': 'Rijgroepen',
  'ariaRowSelect': 'Druk op SPACE om deze rij te selecteren',
  'ariaRowSelectAll': 'Druk op de spatiebalk om alle rijen te selecteren',
  'ariaRowToggleSelection': 'Druk op de spatiebalk om de rijselectie in of uit te schakelen',
  'ariaSearch': 'Zoekopdracht',
  'ariaSearchFilterValues': 'Waarden van het zoekfilter',
  'ariaSortableColumn': 'Druk op ENTER om te sorteren',
  'ariaToggleCellValue': 'Druk op SPACE om de celwaarde te wijzigen',
  'ariaToggleVisibility': 'Druk op SPACE om de zichtbaarheid in of uit te schakelen',
  'ariaUnchecked': 'ongehinderd',
  'ariaValuesDropZonePanelLabel': 'Waarden',
  'ariaVisible': 'zichtbaar',
  'august': 'augustus',
  'autoRotate': 'Automatisch roteren',
  'automatic': 'Automatisch',
  'autosizeAllColumns': 'De grootte van alle kolommen automatisch wijzigen',
  'autosizeThiscolumn': 'De grootte van deze kolom automatisch wijzigen',
  'avg': 'Gemiddeld',
  'axis': 'As',
  'background': 'Achtergrond',
  'barChart': 'Bar',
  'barGroup': 'Bar',
  'blank': 'Blanco',
  'blanks': '(Lege plekken)',
  'blur': 'Vervaging',
  'bold': 'Stoutmoedig',
  'boldItalic': 'Vet en cursief',
  'bottom': 'Onderaan',
  'bubble': 'Bubble',
  'bubbleTooltip': 'Bubble',
  'callout': 'Oproep',
  'cancelFilter': 'Cancel',
  'categories': 'Categoriëen',
  'category': 'categorie',
  'chart': 'Diagram',
  'chartDownloadToolbarTooltip': 'Grafiek downloaden',
  'chartLinkToolbarTooltip': 'Gekoppeld aan Grid',
  'chartRange': 'Diagrambereik',
  'chartSettingsToolbarTooltip': 'Menukaart',
  'chartUnlinkToolbarTooltip': 'Niet gekoppeld aan het raster',
  'clearFilter': 'Duidelijk',
  'collapseAll': 'Alle rijgroepen sluiten',
  'color': 'Kleur',
  'columnChart': 'Kolom',
  'columnGroup': 'Kolom',
  'columnLineCombo': 'Kolom en lijn',
  'columnLineComboTooltip': 'Kolom en lijn',
  'columns': 'Kolommen',
  'combinationChart': 'Combinatie',
  'combinationGroup': 'Combinatie',
  'contains': 'Bevat',
  'copy': 'Kopie',
  'copyWithGroupHeaders': 'Kopiëren met groepskoppen',
  'copyWithHeaders': 'Kopiëren met kopteksten',
  'count': 'tellen',
  'csvExport': 'CSV-export',
  'ctrlC': 'Ctrl+C',
  'ctrlV': 'Ctrl+V',
  'ctrlX': 'Ctrl+X',
  'customComboTooltip': 'Combinatie op maat',
  'cut': 'Snijden',
  'data': 'Gegevens',
  'dateFilter': 'Datumfilter',
  'dateFormatOoo': 'JJJJ-MM-DD',
  'december': 'december',
  'decimalSeparator': '.',
  'defaultCategory': '(Geen)',
  'doughnut': 'Donut',
  'doughnutTooltip': 'Donut',
  'empty': 'Kies er een',
  'enabled': 'Ingeschakeld',
  'endsWith': 'Eindigt met',
  'equals': 'is gelijk aan',
  'excelExport': 'Exporteren naar Excel',
  'expandAll': 'Alle rijgroepen uitbreiden',
  'export': 'Exporteer',
  'false': 'false',
  'february': 'februari',
  'fillOpacity': 'Opacity opvullen',
  'filterOoo': 'Filteren...',
  'filteredRows': 'gefilterd',
  'filters': 'Filters',
  'first': 'Eerste',
  'firstPage': 'Eerste pagina',
  'font': 'Lettertype',
  'format': 'Formateer',
  'greaterThan': 'Groter dan',
  'greaterThanOrEqual': 'Groter dan of gelijk aan',
  'group': 'groep',
  'groupBy': 'Groeperen op',
  'groupFilterSelect': 'Selecteer een veld:',
  'groupedAreaTooltip': 'Gebied',
  'groupedBar': 'Gegroepeerd',
  'groupedBarTooltip': 'Gegroepeerd',
  'groupedColumn': 'Gegroepeerd',
  'groupedColumnTooltip': 'Gegroepeerd',
  'groups': 'Rijgroepen',
  'height': 'Hoogte',
  'histogramBinCount': 'Aantal vuilnisbakken',
  'histogramChart': 'Histogram',
  'histogramFrequency': 'Frequentie',
  'histogramGroup': 'Histogram',
  'histogramTooltip': 'Histogram',
  'inRange': 'Binnen bereik',
  'inRangeEnd': 'naar',
  'inRangeStart': 'vanaf',
  'invalidDate': 'Ongeldige datum',
  'invalidNumber': 'Ongeldig nummer',
  'italic': 'Cursief',
  'itemPaddingX': 'Voorwerpbekleding X',
  'itemPaddingY': 'Voorwerpvulling Y',
  'itemSpacing': 'Afstand tussen items',
  'january': 'Januari',
  'july': 'Juli',
  'june': 'Juni',
  'labels': 'Etiketten',
  'last': 'Laatste',
  'lastPage': 'Laatste pagina',
  'layoutHorizontalSpacing': 'Horizontale tussenruimte',
  'layoutVerticalSpacing': 'Verticale tussenruimte',
  'left': 'Links',
  'legend': 'Legende',
  'length': 'Lengte',
  'lessThan': 'Minder dan',
  'lessThanOrEqual': 'Kleiner dan of gelijk',
  'line': 'Lijn',
  'lineDash': 'Lijnstreepje',
  'lineGroup': 'Lijn',
  'lineTooltip': 'Lijn',
  'lineWidth': 'Lijnbreedte',
  'loadingError': 'FOUT',
  'loadingOoo': 'Bezig met laden ...',
  'march': 'maart',
  'markerPadding': 'Markervulling',
  'markerSize': 'Grootte van de marker',
  'markerStroke': 'Marker Stroke',
  'markers': 'Stiften',
  'max': 'max',
  'maxSize': 'Maximale grootte',
  'may': 'mei',
  'min': 'Min',
  'minSize': 'Minimale grootte',
  'more': 'Môre',
  'navigator': 'Navigator',
  'nextPage': 'Volgende pagina',
  'noAggregation': 'Geen',
  'noDataToChart': 'Er zijn geen gegevens beschikbaar om in kaart te worden gebracht.',
  'noMatches': 'Geen wedstrijden',
  'noPin': 'Geen pin',
  'noRowsToShow': 'Geen rijen om weer te geven',
  'none': 'Geen',
  'normal': 'Normale',
  'normalizedArea': '100% gestapeld',
  'normalizedAreaTooltip': '100% gestapeld',
  'normalizedBar': '100% gestapeld',
  'normalizedBarTooltip': '100% gestapeld',
  'normalizedColumn': '100% gestapeld',
  'normalizedColumnTooltip': '100% gestapeld',
  'notBlank': 'Niet blanco',
  'notContains': 'Bevat niet',
  'notEqual': 'Niet gelijk',
  'november': 'november',
  'number': 'Number',
  'numberFilter': 'Nummerfilter',
  'october': 'oktober',
  'of': ' van ',
  'offset': 'Offset',
  'offsets': 'Offsets',
  'orCondition': 'of',
  'padding': 'Opvulling',
  'page': 'Bladzijde',
  'pageLastRowUnknown': '?',
  'paired': 'Gekoppelde modus',
  'paste': 'Pasta',
  'pie': 'Taart',
  'pieChart': 'Taart',
  'pieGroup': 'Taart',
  'pieTooltip': 'Taart',
  'pinColumn': 'Kolom pinnen',
  'pinLeft': 'Pin links',
  'pinRight': 'Rechts vastmaken',
  'pivotChart': 'Draaigrafiek',
  'pivotChartAndPivotMode': 'Draaigrafiek en draaimodus',
  'pivotChartRequiresPivotMode': 'Pivot Chart vereist dat de draaimodus is ingeschakeld.',
  'pivotChartTitle': 'Draaigrafiek',
  'pivotColumnGroupTotals': 'Totaal',
  'pivotColumnsEmptyMessage': 'Sleep hierheen om kolomlabels in te stellen',
  'pivotMode': 'Pivot-modus',
  'pivots': 'Kolomlabels',
  'position': 'Positie',
  'predefined': 'Voorgedefinieerd',
  'previousPage': 'Vorige pagina',
  'rangeChartTitle': 'Range chart',
  'removeFromLabels': '${variable} uit labels verwijderen',
  'removeFromValues': '${variable} uit waarden verwijderen',
  'resetColumns': 'Kolommen opnieuw instellen',
  'resetFilter': 'Resetten',
  'right': 'Klopt',
  'rowDragRow': 'rij',
  'rowDragRows': 'rijen',
  'rowGroupColumnsEmptyMessage': 'Sleep hierheen om rijgroepen in te stellen',
  'scatter': 'Verspreiden',
  'scatterGroup': 'X Y (verstrooiing)',
  'scatterTooltip': 'Verspreiden',
  'searchOoo': 'Zoek...',
  'secondaryAxis': 'Secundaire as',
  'selectAll': '(Alles selecteren)',
  'selectAllSearchResults': '(Selecteer alle zoekresultaten)',
  'selectedRows': 'Geselecteerd',
  'september': 'september',
  'series': 'Serie',
  'seriesChartType': 'Diagramtype van de serie',
  'seriesType': 'Soort serie',
  'setFilter': 'Filter instellen',
  'settings': 'Instellingen',
  'shadow': 'Schaduw',
  'size': 'Formaat',
  'spacing': 'Tussenruimte',
  'stackedArea': 'Gestapeld',
  'stackedAreaTooltip': 'Gestapeld',
  'stackedBar': 'Gestapeld',
  'stackedBarTooltip': 'Gestapeld',
  'stackedColumn': 'Gestapeld',
  'stackedColumnTooltip': 'Gestapeld',
  'startsWith': 'Begint met',
  'strokeOpacity': 'Ondoorzichtigheid van de lijn',
  'strokeWidth': 'Lijnbreedte',
  'sum': 'Som',
  'textFilter': 'Tekstfilter',
  'thickness': 'Dikte',
  'thousandSeparator': ', ',
  'ticks': 'Teken',
  'time': 'Tijdstip',
  'title': 'Titel',
  'titlePlaceholder': 'Diagramtitel - dubbelklik om te bewerken',
  'to': 'naar',
  'tooltips': 'Tips voor gereedschap',
  'top': 'Bovenste',
  'totalAndFilteredRows': 'rijen',
  'totalRows': 'Totaal aantal rijen',
  'true': 'true',
  'ungroupBy': 'Un-Group door',
  'valueAggregation': 'Aggregatie van waarden',
  'valueColumnsEmptyMessage': 'Sleep hierheen om te aggregeren',
  'values': 'Waarden',
  'width': 'Breedte',
  'xOffset': 'X-offset',
  'xRotation': 'X-rotatie',
  'xType': 'Typ X',
  'xyChart': 'X Y (verstrooiing)',
  'xyValues': 'X Y-waarden',
  'yOffset': 'Y-offset',
  'yRotation': 'Y-rotatie'
}
