<h2 mat-dialog-title>
  {{ 'incident-share-pg-mgmt.public-page-management' | translate }}
</h2>
<div mat-dialog-content>
  <div class="p-3 bg-color--gray-dark bordered">
    <ng-container *ngIf="!addingOrEditing; else showForms">
      <div class="d-flex">
        <button mat-flat-button color="accent" class="ml-auto button button--outlined"
          (click)="onCreateIncidentShareForm()">
          {{ 'incident-share-pg-mgmt.create-public-page' | translate }}
        </button>
        <button mat-flat-button color="accent" class="ml-2 button button--outlined" (click)="onCreateIncidentCodeForm()"
          *ngIf="!incidentCodeDetail && incidentCodeEnabled">
          {{ 'incident-share-pg-mgmt.create-incident-code' | translate }}
        </button>
      </div>
      <ng-container *ngIf="shareSettings.length > 0; else noShareSettings">
        <form *ngIf="incidentShareForm" [formGroup]="incidentShareForm">
          <ng-container formArrayName="sendSettings">

            <mat-expansion-panel *ngFor="let item of shareSettings; let i = index" class="my-3">
              <mat-expansion-panel-header class="d-flex justify-content-center">
                <mat-panel-title>
                  <h4 class="mb-0">{{'incident-share-pg-mgmt.type' | translate}}: {{ 'IncidentShareType.' +
                    item.incidentDataToShare!.shareType! | translate }}
                    <span class="gray-600 pl-5"
                      [ngClass]="{'danger': !onShowExpireButton(i)}">{{'incident-share-pg-mgmt.expiration' |
                      translate}}: {{ item.expiration
                      | localizedDate: 'short'}}
                    </span>
                  </h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                {{'incident-share-pg-mgmt.code' | translate}}: {{ item.code }}
              </p>
              <div class="d-flex">
                <div class="w-33">
                  <p class="mb-0">
                    {{'incident-share-pg-mgmt.public-data' | translate}}:
                  </p>
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.externalIncidentNotes!">
                    {{'incident-share-pg-mgmt.public-incident-notes' | translate}}</mat-checkbox><br />
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.externalSiteCriticalNotes!">
                    {{'incident-share-pg-mgmt.public-site-notes' | translate}}</mat-checkbox><br />
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.externalClientCriticalNotes!">
                    {{'incident-share-pg-mgmt.public-client-notes' | translate}}</mat-checkbox><br />
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.completedActionItems!">
                    {{'incident-share-pg-mgmt.completed-action-items' | translate}}</mat-checkbox><br />
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.objectsFound!">
                    {{'incident-share-pg-mgmt.objects-found' | translate}}</mat-checkbox><br />
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.alarmStationInfo!">
                    {{'incident-share-pg-mgmt.alarm-station-info' | translate}}</mat-checkbox><br />
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.customerContactInfo!">
                    {{'incident-share-pg-mgmt.customer-contact-info' | translate}}</mat-checkbox><br />
                  <mat-checkbox disabled [checked]="item.incidentDataToShare!.videoFeedUrls!">
                    {{'incident-share-pg-mgmt.video-feed-urls' | translate}}</mat-checkbox><br />
                </div>
                <div class="w-33">
                  <p class="mb-0">
                    {{'incident-share-pg-mgmt.attachments' | translate}}:
                  </p>
                  <ng-container *ngFor="let att of attachments">
                    <mat-checkbox disabled [checked]="item.incidentDataToShare!.attachmentsList!.includes(att)">
                      {{att}}</mat-checkbox><br />
                  </ng-container>
                </div>
                <div class="w-33">
                  <p class="mb-0">
                    {{'incident-share-pg-mgmt.link' | translate}}:
                  </p>
                  <p>
                    <a [href]="onGetLink(item!.code!)" target="_blank" class="accent">{{onGetLink(item!.code!)}}</a>
                  </p>
                  <ng-container [formGroupName]="i">
                    <div>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{ 'incident-share-pg-mgmt.send-link-via' | translate }}</mat-label>
                        <mat-select formControlName="type" name="share-type">
                          <mat-option value="EMAIL">
                            {{ 'incident-share-pg-mgmt.email' | translate }}
                          </mat-option>
                          <mat-option value="SMS">
                            {{ 'incident-share-pg-mgmt.sms' | translate }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <ng-container *ngIf="onGetSendFormCtrl().controls[i].value.type">
                      <div>
                        <ng-container *ngIf="onGetSendFormCtrl().controls[i].value.type === 'EMAIL'; else text">
                          <mat-form-field appearance="outline" class="w-75">
                            <mat-label>{{ 'incident-share-pg-mgmt.email' | translate }}</mat-label>
                            <input type="email" required matInput formControlName="email" name="shareEmail"
                              autocomplete="off">
                          </mat-form-field>
                        </ng-container>
                        <ng-template #text>
                          <mat-form-field appearance="outline" class="w-75">
                            <mat-label>{{ 'incident-share-pg-mgmt.phone' | translate }}</mat-label>
                            <ngx-mat-intl-tel-input [preferredCountries]="['ca', 'us', 'mx', 'jm', 'br']"
                              [enableSearch]="true" name="sharePhone" formControlName="phone" [required]="true"
                              autocomplete="off">
                            </ngx-mat-intl-tel-input>
                          </mat-form-field>
                        </ng-template>
                        <span class="w-25 ml-3">
                          <button type="button" [disabled]="onGetSendDisabledIncidentShare(i)" mat-flat-button
                            color="accent" class="button" (click)="onSendShareLinkIncidentShare(i)">
                            {{ 'button.send' | translate }}
                          </button>
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex">
                <button type="button" mat-flat-button color="accent" class="ml-auto button"
                  (click)="onEditShareSettings(item)">
                  {{ 'button.edit' | translate }}
                </button>
                <button *ngIf="onShowExpireButton(i)" type="button" mat-flat-button color="primary" class="ml-2 button"
                  (click)="onDisableShare(i)">
                  {{ 'incident-share-pg-mgmt.expire' | translate }}
                </button>
              </div>
            </mat-expansion-panel>

          </ng-container>
        </form>
      </ng-container>
      <ng-template #noShareSettings>
        <p class="gray-600">{{ 'incident-share-pg-mgmt.no-public-pages' | translate }}</p>
      </ng-template>

      <ng-container *ngIf="incidentCodeDetail; else noIncidentCode">
        <form *ngIf="incidentCodeShareForm" [formGroup]="incidentCodeShareForm">
          <mat-expansion-panel class="my-3">
            <mat-expansion-panel-header class="d-flex justify-content-center">
              <mat-panel-title>
                <h4 class="mb-0">{{'incident-share-pg-mgmt.incident-code' | translate}}
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              {{'incident-share-pg-mgmt.code' | translate}}: {{ incidentCodeDetail.publicCode }}
            </p>
            <div class="d-flex">
              <div class="w-33">
                <p class="mb-0">
                  {{'incident-share-pg-mgmt.public-data' | translate}}:
                </p>
                <mat-checkbox disabled [checked]="incidentCodeDetail.incidentDataToShare!.externalIncidentNotes!">
                  {{'incident-share-pg-mgmt.public-incident-notes' | translate}}</mat-checkbox><br />
                <mat-checkbox disabled [checked]="incidentCodeDetail.incidentDataToShare!.externalSiteCriticalNotes!">
                  {{'incident-share-pg-mgmt.public-site-notes' | translate}}</mat-checkbox><br />
                <mat-checkbox disabled [checked]="incidentCodeDetail.incidentDataToShare!.alarmStationInfo!">
                  {{'incident-share-pg-mgmt.alarm-station-info' | translate}}</mat-checkbox><br />
              </div>
              <div class="w-33">
                <p class="mb-0">
                  {{'incident-share-pg-mgmt.attachments' | translate}}:
                </p>
                <ng-container *ngFor="let att of attachments">
                  <mat-checkbox disabled
                    [checked]="incidentCodeDetail.incidentDataToShare!.attachmentsList!.includes(att)">
                    {{att}}</mat-checkbox><br />
                </ng-container>
              </div>
              <div class="w-33">
                <p class="mb-0">
                  {{'incident-share-pg-mgmt.link' | translate}}:
                </p>
                <p>
                  <a [href]="incidentCodeDetail.shareUrl" target="_blank" class="accent">{{
                    incidentCodeDetail.shareUrl }}</a>
                </p>

                <div>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ 'incident-share-pg-mgmt.send-link-via' | translate }}</mat-label>
                    <mat-select formControlName="type" name="share-type">
                      <mat-option value="EMAIL">
                        {{ 'incident-share-pg-mgmt.email' | translate }}
                      </mat-option>
                      <mat-option value="SMS">
                        {{ 'incident-share-pg-mgmt.sms' | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <ng-container *ngIf="incidentCodeShareForm.value.type">
                  <div>
                    <ng-container *ngIf="incidentCodeShareForm.value.type === 'EMAIL'; else text">
                      <mat-form-field appearance="outline" class="w-75">
                        <mat-label>{{ 'incident-share-pg-mgmt.email' | translate }}</mat-label>
                        <input type="email" required matInput formControlName="email" name="shareEmail"
                          autocomplete="off">
                      </mat-form-field>
                    </ng-container>
                    <ng-template #text>
                      <mat-form-field appearance="outline" class="w-75">
                        <mat-label>{{ 'incident-share-pg-mgmt.phone' | translate }}</mat-label>
                        <ngx-mat-intl-tel-input [preferredCountries]="['ca', 'us', 'mx', 'jm', 'br']"
                          [enableSearch]="true" name="sharePhone" formControlName="phone" [required]="true"
                          autocomplete="off">
                        </ngx-mat-intl-tel-input>
                      </mat-form-field>
                    </ng-template>
                    <span class="w-25 ml-3">
                      <button type="button" [disabled]="onGetSendDisabledIncidentCode()" mat-flat-button color="accent"
                        class="button" (click)="onSendShareLinkIncidentCode()">
                        {{ 'button.send' | translate }}
                      </button>
                    </span>
                  </div>
                </ng-container>

              </div>
            </div>
          </mat-expansion-panel>


        </form>
      </ng-container>
      <ng-template #noIncidentCode>
        <p *ngIf="incidentCodeEnabled" class="gray-600">{{ 'incident-share-pg-mgmt.no-incident-code-pages' | translate
          }}</p>
      </ng-template>

    </ng-container>
    <ng-template #showForms>
      <div *ngIf="additionalDataForm">
        <form [formGroup]="additionalDataForm">
          <div class="d-flex">
            <mat-form-field appearance="outline" class="w-25" *ngIf="!addingOrEditingIncidentCode">
              <mat-label>{{ 'incident-share-pg-mgmt.type' | translate }}</mat-label>
              <mat-select formControlName="shareType" required>
                <mat-option [value]="shareType.OTHER">
                  {{ 'IncidentShareType.OTHER' | translate }}
                </mat-option>
                <mat-option [value]="shareType.ENDCUSTOMER" *ngIf="additionalDataForm.get('id')?.value">
                  {{ 'IncidentShareType.ENDCUSTOMER' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="ml-auto w-25" *ngIf="!addingOrEditingIncidentCode">
              <mat-label>{{ 'incident-share-pg-mgmt.expiry-date' | translate }}</mat-label>
              <input matInput formControlName="date" [matDatepicker]="picker" name="expiry-date" autocomplete="off"
                required [min]="minDate" [max]="maxDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>


            <mat-form-field appearance="outline" class="w-10 ml-1" *ngIf="!addingOrEditingIncidentCode">
              <mat-label>{{ 'incident-share-pg-mgmt.expiry-hour' | translate }}</mat-label>
              <input matInput type="number" formControlName="hour" required name="expiry-hour" autocomplete="off">
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-10 ml-1" *ngIf="!addingOrEditingIncidentCode">
              <mat-label>{{ 'incident-share-pg-mgmt.expiry-minute' | translate }}</mat-label>
              <input matInput type="number" formControlName="minute" required name="expiry-minute" autocomplete="off">
            </mat-form-field>
          </div>
          <div class="d-flex">
            <div class="w-50">
              <p class="mb-1">
                {{'incident-share-pg-mgmt.public-data' | translate}}:
              </p>
              <div>
                <mat-checkbox formControlName="externalIncidentNotes">
                  {{ 'incident-share-pg-mgmt.public-incident-notes' | translate }}
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="externalSiteCriticalNotes">
                  {{ 'incident-share-pg-mgmt.public-site-notes' | translate }}
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="externalClientCriticalNotes" *ngIf="!addingOrEditingIncidentCode">
                  {{ 'incident-share-pg-mgmt.public-client-notes' | translate }}
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="completedActionItems" *ngIf="!addingOrEditingIncidentCode">
                  {{ 'incident-share-pg-mgmt.completed-action-items' | translate }}
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="alarmStationInfo">
                  {{ 'incident-share-pg-mgmt.alarm-station-info' | translate }}
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="objectsFound" *ngIf="!addingOrEditingIncidentCode">
                  {{ 'incident-share-pg-mgmt.objects-found' | translate }}
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="customerContactInfo" *ngIf="!addingOrEditingIncidentCode">
                  {{ 'incident-share-pg-mgmt.customer-contact-info' | translate }}
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="videoFeedUrls" *ngIf="!addingOrEditingIncidentCode">
                  {{ 'incident-share-pg-mgmt.video-feed-urls' | translate }}
                </mat-checkbox>
              </div>
            </div>
            <div class="w-50">
              <p class="mb-1">
                {{'incident-share-pg-mgmt.attachments' | translate}}:
              </p>
              <ng-container formArrayName="attachments">
                <div *ngFor="let item of onGetAttachmentsCtrl().controls; let i = index">
                  <mat-checkbox formControlName="{{i}}">{{attachments[i]}}
                  </mat-checkbox>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="mt-2">
            <button mat-flat-button type="button" [disabled]="additionalDataForm.invalid" color="accent" class="button"
              (click)="onSaveForm()">
              {{ 'button.save' | translate }}
            </button>
            <button mat-flat-button type="button" color="primary" class="ml-2 button"
              (click)="onCancelIncidentShareForm()">
              {{ 'button.cancel' | translate }}
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onClose()">{{ 'button.close' | translate }}</button>
</div>