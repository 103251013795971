export const AG_GRID_LOCALE_FR: Record<string, string> = {
  'AreaColumnCombo': 'Zone et colonne',
  'addToLabels': 'Ajouter ${variable} aux étiquettes',
  'addToValues': 'Ajouter ${variable} aux valeurs',
  'andCondition': 'ET',
  'applyFilter': 'Appliquer',
  'april': 'Avril',
  'area': 'Superficie',
  'areaChart': 'Superficie',
  'areaColumnComboTooltip': 'Zone et colonne',
  'areaGroup': 'Superficie',
  'ariaChecked': 'coché',
  'ariaColumn': 'Colonne',
  'ariaColumnGroup': 'Groupe de colonnes',
  'ariaColumnList': 'Liste des colonnes',
  'ariaColumnSelectAll': 'Basculer la sélection de toutes les colonnes',
  'ariaDateFilterInput': 'Entrée du filtre de date',
  'ariaDefaultListName': 'Liste',
  'ariaDropZoneColumnComponentAggFuncSeparator': 'de',
  'ariaDropZoneColumnComponentDescription': 'Appuyez sur SUPPRIMER pour supprimer',
  'ariaDropZoneColumnComponentSortAscending': 'Croissant',
  'ariaDropZoneColumnComponentSortDescending': 'Descendant',
  'ariaDropZoneColumnGroupItemDescription': 'Appuyez sur ENTER pour trier',
  'ariaDropZoneColumnValueItemDescription': "Appuyez sur ENTRÉE pour changer le type d'agrégation",
  'ariaFilterColumnsInput': 'Filtrer les entrées des colonnes',
  'ariaFilterFromValue': 'Filtrer à partir de la valeur',
  'ariaFilterInput': 'Entrée du filtre',
  'ariaFilterList': 'Liste des filtres',
  'ariaFilterMenuOpen': 'Ouvrir le menu Filtre',
  'ariaFilterToValue': 'Filtrer à la valeur',
  'ariaFilterValue': 'Valeur du filtre',
  'ariaFilteringOperator': 'Opérateur de filtrage',
  'ariaHidden': 'Caché',
  'ariaIndeterminate': 'indéterminée',
  'ariaInputEditor': "Éditeur d'entrée",
  'ariaLabelAggregationFunction': "Fonction d'agrégation",
  'ariaLabelCellEditor': 'Éditeur de cellules',
  'ariaLabelColumnMenu': 'Menu de la colonne',
  'ariaLabelContextMenu': 'Menu contextuel',
  'ariaLabelDialog': 'Dialogue',
  'ariaLabelSelectField': 'Sélectionner un champ',
  'ariaLabelSubMenu': 'Sous-menu',
  'ariaLabelTooltip': 'Infobulle',
  'ariaMenuColumn': 'Appuyez sur la touche CTRL ENTER pour ouvrir le menu de la colonne.',
  'ariaPivotDropZonePanelLabel': 'Étiquettes de colonne',
  'ariaRowDeselect': 'Appuyez sur SPACE pour désélectionner cette ligne',
  'ariaRowGroupDropZonePanelLabel': 'Groupes de rangées',
  'ariaRowSelect': 'Appuyez sur SPACE pour sélectionner cette ligne',
  'ariaRowSelectAll': 'Appuyez sur Espace pour activer la sélection de toutes les lignes',
  'ariaRowToggleSelection': 'Appuyez sur Espace pour basculer la sélection de ligne',
  'ariaSearch': 'Recherche',
  'ariaSearchFilterValues': 'Rechercher les valeurs des filtres',
  'ariaSortableColumn': 'Appuyez sur ENTER pour trier',
  'ariaToggleCellValue': 'Appuyez sur SPACE pour activer la valeur de la cellule',
  'ariaToggleVisibility': 'Appuyez sur ESPACE pour activer la visibilité',
  'ariaUnchecked': 'non cochée',
  'ariaValuesDropZonePanelLabel': 'Valeurs',
  'ariaVisible': 'visible',
  'august': 'août',
  'autoRotate': 'Rotation automatique',
  'automatic': 'Automatique',
  'autosizeAllColumns': 'Taille automatique de toutes les colonnes',
  'autosizeThiscolumn': 'Taille automatique de cette colonne',
  'avg': 'Moyenne',
  'axis': 'Axe',
  'background': 'Contexte',
  'barChart': 'Barreau',
  'barGroup': 'Barreau',
  'blank': 'Vide',
  'blanks': '(Blancs)',
  'blur': 'Flou',
  'bold': 'Gras',
  'boldItalic': 'Italique gras',
  'bottom': 'Bas',
  'bubble': 'Bulle',
  'bubbleTooltip': 'Bulle',
  'callout': 'Appel',
  'cancelFilter': 'Annuler',
  'categories': 'Catégories',
  'category': 'Catégorie',
  'chart': 'Diagramme',
  'chartDownloadToolbarTooltip': 'Télécharger le graphique',
  'chartLinkToolbarTooltip': 'Lié à la grille',
  'chartRange': 'Plage du graphique',
  'chartSettingsToolbarTooltip': 'Menu',
  'chartUnlinkToolbarTooltip': 'Non couplé à partir de la grille',
  'clearFilter': 'Clair',
  'collapseAll': 'Fermer tous les groupes de rangées',
  'color': 'Couleur',
  'columnChart': 'Colonne',
  'columnGroup': 'Colonne',
  'columnLineCombo': 'Colonne et ligne',
  'columnLineComboTooltip': 'Colonne et ligne',
  'columns': 'Colonnes',
  'combinationChart': 'Combinaison',
  'combinationGroup': 'Combinaison',
  'contains': 'contient',
  'copy': 'Copie',
  'copyWithGroupHeaders': 'Copie avec les en-têtes de groupe',
  'copyWithHeaders': 'Copier avec les en-têtes',
  'count': 'Dénombrement',
  'csvExport': 'exportation csv',
  'ctrlC': 'Ctrl+C',
  'ctrlV': 'Ctrl+V',
  'ctrlX': 'Ctrl+X',
  'customComboTooltip': 'Combinaison personnalisée',
  'cut': 'Couper',
  'data': 'Données',
  'dateFilter': 'Filtre de date',
  'dateFormatOoo': 'aaaa-mm-jj',
  'december': 'décembre',
  'decimalSeparator': '.',
  'defaultCategory': '(Aucun)',
  'doughnut': 'Beignet',
  'doughnutTooltip': 'Beignet',
  'empty': 'Choisir une',
  'enabled': 'Activé',
  'endsWith': 'se termine par',
  'equals': 'égal',
  'excelExport': 'Exportation Excel',
  'expandAll': 'Développer tous les groupes de lignes',
  'export ': 'Exporter',
  'false': 'faux',
  'february': 'Février',
  'fillOpacity': 'Opacité du remplissage',
  'filterOoo': 'Filtrer...',
  'filteredRows': 'Filtré',
  'filters': 'Filtres',
  'first': "D'abord",
  'firstPage': 'Première page',
  'font': 'Police',
  'format': 'Format',
  'greaterThan': 'Supérieur à',
  'greaterThanOrEqual': 'Supérieur ou égal',
  'group': 'Groupe',
  'groupBy': 'Regrouper par',
  'groupFilterSelect': 'Sélectionner le champ: ',
  'groupedAreaTooltip': 'Superficie',
  'groupedBar': 'Groupés',
  'groupedBarTooltip': 'Groupés',
  'groupedColumn': 'Groupés',
  'groupedColumnTooltip': 'Groupés',
  'groups': 'Groupes de rangées',
  'height': 'Hauteur',
  'histogramBinCount': 'Nombre de bacs',
  'histogramChart': 'Histogramme',
  'histogramFrequency': 'Fréquence',
  'histogramGroup': 'Histogramme',
  'histogramTooltip': 'Histogramme',
  'inRange': "Dans l'aire de répartition",
  'inRangeEnd': 'à',
  'inRangeStart': 'De',
  'invalidDate': 'Date non valide',
  'invalidNumber': 'Numéro non valide',
  'italic': 'Italique',
  'itemPaddingX': "Rembourrage de l'objet X",
  'itemPaddingY': "Rembourrage de l'objet Y",
  'itemSpacing': 'Espacement entre les objets',
  'january': 'janvier',
  'july': 'Juillet',
  'june': 'Juin',
  'labels': 'Étiquettes',
  'last': 'Dernier',
  'lastPage': 'Dernière page',
  'layoutHorizontalSpacing': 'Espacement horizontal',
  'layoutVerticalSpacing': 'Espacement vertical',
  'left': 'Gauche',
  'legend': 'Légende',
  'length': 'Longueur',
  'lessThan': 'Inférieur à',
  'lessThanOrEqual': 'Inférieur ou égal',
  'line': 'Ligne',
  'lineDash': 'Tiret de ligne',
  'lineGroup': 'Ligne',
  'lineTooltip': 'Ligne',
  'lineWidth': 'Largeur de ligne',
  'loadingError': 'ERRER',
  'loadingOoo': 'Chargement en cours…',
  'march': 'mars',
  'markerPadding': 'Rembourrage marqueur',
  'markerSize': 'Taille du marqueur',
  'markerStroke': 'AVC marqueur',
  'markers': 'Marqueurs',
  'max': 'max',
  'maxSize': 'Taille maximale',
  'may': 'Mai',
  'min': 'min',
  'minSize': 'Taille minimale',
  'more': 'Plus',
  'navigator': 'Navigateur',
  'nextPage': 'Page suivante',
  'noAggregation': 'Aucun',
  'noDataToChart': 'Aucune donnée disponible ne peut être cartographiée.',
  'noMatches': 'Pas de correspondances',
  'noPin': 'Pas de goupille',
  'noRowsToShow': 'Aucune ligne à afficher',
  'none': 'Aucun',
  'normal': 'Normal',
  'normalizedArea': '100 % empilés',
  'normalizedAreaTooltip': '100 % empilés',
  'normalizedBar': '100 % empilés',
  'normalizedBarTooltip': '100 % empilés',
  'normalizedColumn': '100 % empilés',
  'normalizedColumnTooltip': '100 % empilés',
  'notBlank': 'Non vide',
  'notContains': 'Ne contient pas',
  'notEqual': "N'est pas égal",
  'november': 'novembre',
  'number': 'Nombre',
  'numberFilter': 'Filtre de nombre',
  'october': 'Octobre',
  'of': 'de',
  'offset': 'Compensation',
  'offsets': 'Compensations',
  'orCondition': 'OU',
  'padding': 'Rembourrage',
  'page': 'Page',
  'pageLastRowUnknown': '?',
  'paired': 'Mode jumelé',
  'paste': 'Pâte',
  'pie': 'Tarte',
  'pieChart': 'Tarte',
  'pieGroup': 'Tarte',
  'pieTooltip': 'Tarte',
  'pinColumn': 'Colonne Pin',
  'pinLeft': 'Broche Gauche',
  'pinRight': 'Broche Droite',
  'pivotChart': 'Diagramme pivotant',
  'pivotChartAndPivotMode': 'Diagramme croisé dynamique et mode Pivot',
  'pivotChartRequiresPivotMode': 'Le mode Pivot est activé.',
  'pivotChartTitle': 'Diagramme pivotant',
  'pivotColumnGroupTotals': 'Total',
  'pivotColumnsEmptyMessage': 'Faites glisser ici pour définir les étiquettes des colonnes',
  'pivotMode': 'Mode Pivot',
  'pivots': 'Étiquettes de colonne',
  'position': 'Poste',
  'predefined': 'Prédéfini',
  'previousPage': 'Page précédente',
  'rangeChartTitle': 'Diagramme des plages',
  'removeFromLabels': 'Supprimer ${variable} des étiquettes',
  'removeFromValues': 'Supprimer ${variable} des valeurs',
  'resetColumns': 'Réinitialiser les colonnes',
  'resetFilter': 'Réinitialiser',
  'right': 'Droite',
  'rowDragRow': 'Rangée',
  'rowDragRows': 'Rangées',
  'rowGroupColumnsEmptyMessage': 'Faites glisser ici pour définir les groupes de rangées',
  'scatter': 'Dispersion',
  'scatterGroup': 'X Y (Dispersion)',
  'scatterTooltip': 'Dispersion',
  'searchOoo': 'Rechercher...',
  'secondaryAxis': 'Axe secondaire',
  'selectAll': '(Sélectionner tout)',
  'selectAllSearchResults': '(Sélectionnez tous les résultats de recherche)',
  'selectedRows': 'Sélectionné',
  'september': 'septembre',
  'series': 'Série',
  'seriesChartType': 'Type de diagramme en série',
  'seriesType': 'Type de série',
  'setFilter': 'Définir le filtre',
  'settings': 'Paramètres',
  'shadow': 'Ombre',
  'size': 'Taille',
  'spacing': 'Espacement',
  'stackedArea': 'Empilés',
  'stackedAreaTooltip': 'Empilés',
  'stackedBar': 'Empilés',
  'stackedBarTooltip': 'Empilés',
  'stackedColumn': 'Empilés',
  'stackedColumnTooltip': 'Empilés',
  'startsWith': 'commence par',
  'strokeOpacity': 'Opacité des lignes',
  'strokeWidth': 'Largeur du trait',
  'sum': 'Somme',
  'textFilter': 'Filtre de texte',
  'thickness': 'Épaisseur',
  'thousandSeparator': ',',
  'ticks': 'Tiques',
  'time': ' Temps ',
  'title': 'Titre',
  'titlePlaceholder': 'Titre du graphique - double-cliquez pour modifier',
  'to': 'à',
  'tooltips': "Conseils d'outils",
  'top': 'Haut',
  'totalAndFilteredRows': 'Rangées',
  'totalRows': 'Total des rangées',
  'true': 'vrai',
  'ungroupBy': 'Dégrouper par',
  'valueAggregation': 'Agrégation de la valeur',
  'valueColumnsEmptyMessage': 'Faites glisser ici pour regrouper',
  'values': 'Valeurs',
  'width': 'Largeur',
  'xOffset': 'X Décalé',
  'xRotation': 'X Rotation',
  'xType': 'Type X',
  'xyChart': 'X Y (Dispersion)',
  'xyValues': 'X Y Valeurs',
  'yOffset': 'Décalé Y',
  'yRotation': 'Rotation Y'
}
