import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import DescopeSdk from '@descope/web-js-sdk';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import { DescopeProjectInfo } from '../interfaces/descope-project-info.interface';

@Injectable()
export class AuthDescopeService {
  private descopeSdk;
  private email?: string;

  constructor(private router: Router, private cookieService: CookieService) {
    const descopeInfo = this.getDescopeInfo();
    try {
      this.descopeSdk = DescopeSdk({
        projectId: descopeInfo.projectId,
        persistTokens: true,
        autoRefresh: true,
        baseUrl: descopeInfo.authUrl,
      });
    } catch (e) {
      // We were unable to configure the descope sdk, that's okay, not a fatal error
      // this will happen most commonly if descopeInfo.authUrl is not configured within cloudflare
    }
  }

  public async onGetSessionToken(): Promise<string | undefined> {
    let sessionToken: string | undefined;
    try {
      if (this.descopeSdk) {
        sessionToken = this.descopeSdk.getSessionToken();

        if (!sessionToken || this.descopeSdk.isJwtExpired(sessionToken)) {
          try {
            await this.descopeSdk.refresh();
            sessionToken = this.descopeSdk.getSessionToken();
          } catch (error) {
            // The user's session token is invalid and refreshing the token didn't work
            // We don't want to logout the user in this case because they might have a cognito oauth token
            // await this.onLogoutUser();
            sessionToken = undefined;
          }
        }
      }
    } catch (e) {
      // We were unable to call the descope sdk
      // this will happen most commonly if descopeInfo.authUrl is not configured within cloudflare
    }

    return sessionToken;
  }

  public async onLogoutUser(navigate?: boolean): Promise<void> {
    this.email = undefined;
    try {
      if (this.descopeSdk) {
        await this.descopeSdk.logout(this.descopeSdk.getRefreshToken());
        if (navigate) {
          await this.router.navigate(['/login']);
        }
      }
    } catch (e) {
      // We were unable to call the descope sdk
      // this will happen most commonly if descopeInfo.authUrl is not configured within cloudflare
    }

  }

  public async onGetEmail(): Promise<string | undefined> {
    if (this.email) {
      return this.email;
    }
    try {
      if (this.descopeSdk) {
        const me = await this.descopeSdk.me(this.descopeSdk.getRefreshToken());
        this.email = me.data?.email;
        return this.email;
      }
    } catch (e) {
      // We were unable to call the descope sdk
      // this will happen most commonly if descopeInfo.authUrl is not configured within cloudflare
    }
    return;
  }

  public getDescopeInfo(): DescopeProjectInfo {
    let baseUrl;
    if (window.location.hostname === 'localhost') {
      baseUrl = 'https://app.cname.pink';
    } else {
      baseUrl = `${window.origin}`;
    }
    const descopeCookie = this.cookieService.get(`descope-flow-${window.location.hostname}`);
    let flowId;
    if (descopeCookie) {
      flowId = descopeCookie;
    } else {
      flowId = environment.descopeFlowId;
    }
    return {
      projectId: environment.descopeProjectId,
      authUrl: baseUrl,
      flowId,
    };
  }
}