import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'localizedCurrency',
})
export class LocalizedCurrencyPipe extends CurrencyPipe implements PipeTransform {

  constructor(private localeService: LocaleService) {
    super(localeService.locale, 'USD');
  }

  public formatCurrency(value: number, currency: string): any {
    return super.transform(value, this.localeService.locale, currency);
  }

}