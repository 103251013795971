export abstract class AuditActions {
  public static readonly SAVE = 'Save';
  public static readonly SEND = 'Send';
  public static readonly CREATE = 'Create';
  public static readonly CANCEL = 'Cancel';
  public static readonly CLOSE = 'Close';
  public static readonly CANCEL_EDIT = 'Cancel Edit';
  public static readonly CANCEL_CREATE = 'Cancel Create';
  public static readonly DELETE = 'Delete';
  public static readonly EDIT = 'Edit';
  public static readonly CONNECT = 'Connect';
  public static readonly VIEW = 'View';
  public static readonly DOWNLOAD = 'Download';
  public static readonly PUBLISH = 'Publish';
  public static readonly DEACTIVATE = 'Deactivate';
  public static readonly ACTIVATE = 'Activate';
  public static readonly ARM = 'Arm';
  public static readonly DISARM = 'Disarm';
  public static readonly HOLD = 'Hold';
  public static readonly RESUME = 'Resume';
}