import { Injectable } from '@angular/core';

import { IntegrationSubType, RapidSOSEmergencyType } from 'src/app/services/graphql.service';


@Injectable()
export class RapidSOSService {

  public getRapidSOSEmergencyTypeList(subTypeList: IntegrationSubType[]): RapidSOSEmergencyType[] {
    const emergencyTypes: RapidSOSEmergencyType[] = [];
    if (subTypeList.includes(IntegrationSubType.Fire)) {
      emergencyTypes.push(RapidSOSEmergencyType.FIRE);
      emergencyTypes.push(RapidSOSEmergencyType.CRASH);
      emergencyTypes.push(RapidSOSEmergencyType.CO);
    }

    if (subTypeList.includes(IntegrationSubType.Medical)) {
      emergencyTypes.push(RapidSOSEmergencyType.MEDICAL);
    }

    if (subTypeList.includes(IntegrationSubType.Police)) {
      emergencyTypes.push(RapidSOSEmergencyType.BURGLARY);
      emergencyTypes.push(RapidSOSEmergencyType.HOLDUP);
      emergencyTypes.push(RapidSOSEmergencyType.CRASH);
      emergencyTypes.push(RapidSOSEmergencyType.SILENT_ALARM);
    }

    emergencyTypes.push(RapidSOSEmergencyType.OTHER);

    return emergencyTypes;

  }
}