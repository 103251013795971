import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-popup-refresh',
  templateUrl: './popup-refresh.component.html',
  styleUrls: ['./popup-refresh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupRefreshComponent {

  constructor(private router: Router, private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, title: string, reloadUrl: any }, private dialogRef: MatDialogRef<any>,) { }

  public onRefresh(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.dialogRef.close();
    this.router.navigate(this.data.reloadUrl, { relativeTo: this.route });
  }

  public onIgnore(): void {
    this.dialogRef.close();
  }

}
