import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe extends DatePipe implements PipeTransform {

  constructor(private localeService: LocaleService) {
    super(localeService.locale);
  }

  public transform(value: any, pattern: string = 'mediumDate'): any {
    return super.transform(value, pattern, undefined, this.localeService.locale);
  }

}