import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth, } from 'aws-amplify';

import { UserGroup } from '../models/user-groups.model';
import { User } from '../models/user.model';
import { UserStoreService } from '../store/user-store.service';

import { AuthHelperService } from './auth-helper.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private userStore: UserStoreService,
    private authHelper: AuthHelperService) { }

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await Auth.currentSession().then((session: CognitoUserSession) => {
      if (session && session.isValid()) {
        if (this.userStore.user && this.userStore.user.name) {
          return this.checkIfUserTypeCanAccess(next, state, this.userStore.user);
        }

        return Auth.currentAuthenticatedUser()
          .then((cognitoUser) => {
            const user = this.populateUserDetails(cognitoUser);
            this.userStore.user = user;
            return this.checkIfUserTypeCanAccess(next, state, user);
          })
          .catch(() => {
            this.authHelper.onLogout();
            return false;
          });
      }
      return false;
    }).catch((e) => {
      this.authHelper.onLogout();
      return false;
    });
  }

  public async canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.canActivate(next, state);
  }

  private checkIfUserTypeCanAccess(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, user: User): boolean {
    if (user.group === UserGroup.Customer) {
      if (!state.url.includes('vms') && !state.url.includes('cx')) {
        void this.router.navigate(['/vms']);
      }
      return true;
    } else {
      if (next.data.inGroups) {
        if (!next.data.inGroups.includes(user.group)) {
          if (user.group === UserGroup.Operators || user.group === UserGroup.SuperOperators) {
            void this.router.navigate(['/vms']);
          } else if (user.group === UserGroup.IntegratorAdmin) {
            void this.router.navigate(['/admin/customers']);
          } else {
            void this.router.navigate(['/admin/customers']);
          }
          return false;
        }
      }
      return true;
    }

  }

  private populateUserDetails(cognitoUser: any): User {
    const user = this.authHelper.parseCognitoUser(cognitoUser);
    return user;
  }
}
