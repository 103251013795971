import { v4 as uuidv4 } from 'uuid';

export const escapeRegExp = (str: string): string => {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

export { uuidv4 }

export const onGetHtml = (str?: string): string => {
  if (!str) {
    return '';
  }
  return str.split('\n').join('<br>');
}

export const onRemoveWhitespace = (str?: string): string | undefined => {
  str = str?.trim();
  str = str?.replace(/[\t\n\r\ ]/gm, '');
  return str;
}

export const onRemoveUrlProtocolEnding = (vmsUrl?: string): string | null => {
  if (vmsUrl) {
    vmsUrl = vmsUrl.replace('https://', '').replace('http://', '');
    if (vmsUrl.charAt(vmsUrl.length - 1) === '/') {
      vmsUrl = vmsUrl.substring(0, vmsUrl.length - 1);
    }
    return vmsUrl;
  }
  return null;
}