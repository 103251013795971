import { Customer } from 'src/app/services/graphql.service';
import { environment } from 'src/environments/environment';
import { Customer as ModelCustomer } from 'src/models'

export const getVMSAlarmUrl = (monitoringCenter?: Customer | ModelCustomer): string => {
  if (monitoringCenter && monitoringCenter.vmsAlarmUrl) {
    return `https://${monitoringCenter.vmsAlarmUrl}`;
  }

  return environment.vmsAlarmUrl;
}

export const getVMSAdminUrl = (monitoringCenter?: Customer | ModelCustomer): string => {
  if (monitoringCenter && monitoringCenter.vmsAdminUrl) {
    return `https://${monitoringCenter.vmsAdminUrl}`;
  }

  return environment.vmsAdminUrl;
}

export const getVMSPortalUrl = (monitoringCenter?: Customer | ModelCustomer): string => {
  if (monitoringCenter && monitoringCenter.vmsUrl) {
    return `https://${monitoringCenter.vmsUrl}`;
  }

  return environment.vmsPublicPortalUrl;
}
