export const AG_GRID_LOCALE_ES: Record<string, string> = {
  'AreaColumnCombo': 'Área y columna',
  'addToLabels': 'Añadir ${variable} a las etiquetas',
  'addToValues': 'Añadir ${variable} a los valores',
  'andCondition': 'y',
  'applyFilter': 'Utilizar',
  'april': 'abril',
  'area': 'área',
  'areaChart': 'área',
  'areaColumnComboTooltip': 'Área y columna',
  'areaGroup': 'área',
  'ariaChecked': 'comprobado',
  'ariaColumn': 'Columna',
  'ariaColumnGroup': 'Grupo de columnas',
  'ariaColumnList': 'Lista de columnas',
  'ariaColumnSelectAll': 'Alternar seleccionar todas las columnas',
  'ariaDateFilterInput': 'Entrada de filtro de fecha',
  'ariaDefaultListName': 'Lista',
  'ariaDropZoneColumnComponentAggFuncSeparator': 'de',
  'ariaDropZoneColumnComponentDescription': 'Presiona ELIMINAR para eliminar',
  'ariaDropZoneColumnComponentSortAscending': 'ascendiendo',
  'ariaDropZoneColumnComponentSortDescending': 'descendiendo',
  'ariaDropZoneColumnGroupItemDescription': 'Pulse ENTER para ordenar',
  'ariaDropZoneColumnValueItemDescription': 'Pulse ENTER para cambiar el tipo de agregación',
  'ariaFilterColumnsInput': 'Filtrar entrada de columnas',
  'ariaFilterFromValue': 'Filtrar por valor',
  'ariaFilterInput': 'Entrada de filtro',
  'ariaFilterList': 'Lista de filtros',
  'ariaFilterMenuOpen': 'Abrir menú de filtros',
  'ariaFilterToValue': 'Filtrar por valor',
  'ariaFilterValue': 'Valor de filtro',
  'ariaFilteringOperator': 'Operador de filtrado',
  'ariaHidden': 'Escondido',
  'ariaIndeterminate': 'indeterminado',
  'ariaInputEditor': 'Editor de entrada',
  'ariaLabelAggregationFunction': 'Función de agregación',
  'ariaLabelCellEditor': 'Editor de celdas',
  'ariaLabelColumnMenu': 'Menú de columnas',
  'ariaLabelContextMenu': 'Menú contextual',
  'ariaLabelDialog': 'Diálogo',
  'ariaLabelSelectField': 'Seleccionar campo',
  'ariaLabelSubMenu': 'Submenú',
  'ariaLabelTooltip': 'Información sobre herramientas',
  'ariaMenuColumn': 'Pulse CTRL ENTER para abrir el menú de la columna.',
  'ariaPivotDropZonePanelLabel': 'Etiquetas de columna',
  'ariaRowDeselect': 'Pulse la barra espaciadora para deseleccionar esta fila',
  'ariaRowGroupDropZonePanelLabel': 'Grupos de filas',
  'ariaRowSelect': 'Pulse la barra espaciadora para seleccionar esta fila',
  'ariaRowSelectAll': 'Pulse la barra espaciadora para cambiar la selección de todas las filas',
  'ariaRowToggleSelection': 'Pulse la barra espaciadora para cambiar la selección de filas',
  'ariaSearch': 'Buscar',
  'ariaSearchFilterValues': 'Valores de filtro de búsqueda',
  'ariaSortableColumn': 'Pulse ENTER para ordenar',
  'ariaToggleCellValue': 'Pulse la barra espaciadora para cambiar el valor de la celda',
  'ariaToggleVisibility': 'Pulse la barra espaciadora para cambiar la visibilidad',
  'ariaUnchecked': 'Sin marcar',
  'ariaValuesDropZonePanelLabel': 'Valores',
  'ariaVisible': 'visible',
  'august': 'agosto',
  'autoRotate': 'Rotación automática',
  'automatic': 'Automático',
  'autosizeAllColumns': 'Ajustar automáticamente el tamaño de todas las columnas',
  'autosizeThiscolumn': 'Ajustar automáticamente el tamaño de esta columna',
  'avg': 'Promedio',
  'axis': 'Eje',
  'background': 'Antecedentes',
  'barChart': 'Bar',
  'barGroup': 'Bar',
  'blank': 'En blanco',
  'blanks': '(Espacios en blanco)',
  'blur': 'Difuminar',
  'bold': 'Audaz',
  'boldItalic': 'Cursiva en negrita',
  'bottom': 'Parte inferior',
  'bubble': 'Burbuja',
  'bubbleTooltip': 'Burbuja',
  'callout': 'Llamada',
  'cancelFilter': 'Annuler',
  'categories': 'Categorías',
  'category': 'Category',
  'chart': 'Gráfico',
  'chartDownloadToolbarTooltip': 'Descargar gráfico',
  'chartLinkToolbarTooltip': 'Vinculado a la red',
  'chartRange': 'Rango de gráficos',
  'chartSettingsToolbarTooltip': 'Menú',
  'chartUnlinkToolbarTooltip': 'Desvinculado de la cuadrícula',
  'clearFilter': 'despejar',
  'collapseAll': 'Cerrar todos los grupos de filas',
  'color': 'Color',
  'columnChart': 'Columna',
  'columnGroup': 'Columna',
  'columnLineCombo': 'Columna y línea',
  'columnLineComboTooltip': 'Columna y línea',
  'columns': 'Columnas',
  'combinationChart': 'Combinación',
  'combinationGroup': 'Combinación',
  'contains': 'contains',
  'copy': 'Copia',
  'copyWithGroupHeaders': 'Copiar con encabezados de grupo',
  'copyWithHeaders': 'Copiar con encabezados',
  'count': 'Contar',
  'csvExport': 'Exportación a CSV',
  'ctrlC': 'Ctrl+C',
  'ctrlV': 'Ctrl+V',
  'ctrlX': 'Ctrl+X',
  'customComboTooltip': 'Combinación personalizada',
  'cut': 'Corte',
  'data': 'Datos',
  'dateFilter': 'Filtro de fecha',
  'dateFormatOoo': 'aaaa-mm-dd',
  'december': 'diciembre',
  'decimalSeparator': '.',
  'defaultCategory': '(Ninguna)',
  'doughnut': 'Rosquilla',
  'doughnutTooltip': 'Rosquilla',
  'empty': 'Elige uno',
  'enabled': 'Habilitada',
  'endsWith': 'Termina con',
  'equals': 'equals',
  'excelExport': 'Exportación a Excel',
  'expandAll': 'Expandir todos los grupos de filas',
  'export': 'Exportar',
  'false': 'Falso',
  'february': 'febrero',
  'fillOpacity': 'Opacidad de relleno',
  'filterOoo': 'Filtrar...',
  'filteredRows': 'Filtrado',
  'filters': 'Filtros',
  'first': 'First',
  'firstPage': 'Primer página',
  'font': 'Tipo de Letra',
  'format': 'Formatear',
  'greaterThan': 'greater than',
  'greaterThanOrEqual': 'mayor o igual',
  'group': 'Grupo',
  'groupBy': 'Agrupar por',
  'groupFilterSelect': 'Seleccione el campo:',
  'groupedAreaTooltip': 'área',
  'groupedBar': 'Agrupado',
  'groupedBarTooltip': 'Agrupado',
  'groupedColumn': 'Agrupado',
  'groupedColumnTooltip': 'Agrupado',
  'groups': 'Grupos de filas',
  'height': 'Altura',
  'histogramBinCount': 'Recuento de compartimentos',
  'histogramChart': 'Histograma',
  'histogramFrequency': 'Frecuencia',
  'histogramGroup': 'Histograma',
  'histogramTooltip': 'Histograma',
  'inRange': 'Dentro del rango',
  'inRangeEnd': 'Para',
  'inRangeStart': ' de',
  'invalidDate': 'Fecha no válida',
  'invalidNumber': 'Número inválido',
  'italic': 'Itálico',
  'itemPaddingX': 'Relleno de artículos X',
  'itemPaddingY': 'Relleno de artículos Y',
  'itemSpacing': 'Espaciado entre artículos',
  'january': 'enero',
  'july': 'julio',
  'june': 'junio',
  'labels': 'Etiquetas',
  'last': 'Last',
  'lastPage': 'Última página',
  'layoutHorizontalSpacing': 'Espaciado horizontal',
  'layoutVerticalSpacing': 'Espaciado vertical',
  'left': 'left',
  'legend': 'Leyenda',
  'length': 'Longitud',
  'lessThan': 'less than',
  'lessThanOrEqual': 'menor o igual',
  'line': 'Línea',
  'lineDash': 'Línea Dash',
  'lineGroup': 'Línea',
  'lineTooltip': 'Línea',
  'lineWidth': 'Ancho del trazo',
  'loadingError': 'ERRAR',
  'loadingOoo': 'Cargando...',
  'march': 'marcha',
  'markerPadding': 'Relleno de marcadores',
  'markerSize': 'Tamaño del marcador',
  'markerStroke': 'Trazo marcador',
  'markers': 'Marcadores',
  'max': 'max',
  'maxSize': 'Tamaño máximo',
  'may': 'mayo',
  'min': 'min',
  'minSize': 'Tamaño mínimo',
  'more': 'Mas',
  'navigator': 'Navegante',
  'nextPage': 'Próxima página',
  'noAggregation': 'Ninguno',
  'noDataToChart': 'No hay datos disponibles para graficar.',
  'noMatches': 'No hay coincidencias',
  'noPin': 'Sin pin',
  'noRowsToShow': 'No hay filas para mostrar',
  'none': 'Ninguno',
  'normal': 'NORMAL',
  'normalizedArea': '100% apilado',
  'normalizedAreaTooltip': '100% apilado',
  'normalizedBar': '100% apilado',
  'normalizedBarTooltip': '100% apilado',
  'normalizedColumn': '100% apilado',
  'normalizedColumnTooltip': '100% apilado',
  'notBlank': 'No está en blanco',
  'notContains': 'no contiene',
  'notEqual': 'No es igual',
  'november': 'noviembre',
  'number': 'Número',
  'numberFilter': 'Filtro de números',
  'october': 'octubre',
  'of': 'de',
  'offset': 'Compensación',
  'offsets': 'Compensaciones',
  'orCondition': 'o',
  'padding': 'relleno',
  'page': 'página',
  'pageLastRowUnknown': '?',
  'paired': 'Modo emparejado',
  'paste': 'Pegar',
  'pie': 'Pastel',
  'pieChart': 'Pastel',
  'pieGroup': 'Pastel',
  'pieTooltip': 'Pastel',
  'pinColumn': 'Columna de pines',
  'pinLeft': 'Pin izquierdo',
  'pinRight': 'Pin a la derecha',
  'pivotChart': 'Gráfico pivotante',
  'pivotChartAndPivotMode': 'Gráfico dinámico y modo pivote',
  'pivotChartRequiresPivotMode': 'El gráfico dinámico requiere que el modo dinámico esté habilitado.',
  'pivotChartTitle': 'Gráfico pivotante',
  'pivotColumnGroupTotals': 'Total',
  'pivotColumnsEmptyMessage': 'Arrastre aquí para configurar las etiquetas de las columnas',
  'pivotMode': 'Modo Pivot',
  'pivots': 'Etiquetas de columna',
  'position': 'Posición',
  'predefined': 'Predefinido',
  'previousPage': 'Página previa',
  'rangeChartTitle': 'Gráfico de rangos',
  'removeFromLabels': 'Eliminar ${variable} de las etiquetas',
  'removeFromValues': 'Eliminar ${variable} de los valores',
  'resetColumns': 'Restablecer columnas',
  'resetFilter': 'Resetear',
  'right': '¿Verdad?',
  'rowDragRow': 'Fila',
  'rowDragRows': 'Filas',
  'rowGroupColumnsEmptyMessage': 'Arrastre aquí para configurar los grupos de filas',
  'scatter': 'Dispersión',
  'scatterGroup': 'X Y (dispersión)',
  'scatterTooltip': 'Dispersión',
  'searchOoo': 'búsqueda ...',
  'secondaryAxis': 'Eje secundario',
  'selectAll': '(Seleccionar todo)',
  'selectAllSearchResults': '(Seleccionar todos los resultados de la búsqueda)',
  'selectedRows': 'seleccionado',
  'september': 'setiembre',
  'series': 'Series',
  'seriesChartType': 'Tipo de gráfico de series',
  'seriesType': 'Tipo de serie',
  'setFilter': 'Set filtro',
  'settings': 'Ajustes',
  'shadow': 'Sombra',
  'size': 'Tamaño',
  'spacing': 'Espaciado',
  'stackedArea': 'Apilado',
  'stackedAreaTooltip': 'Apilado',
  'stackedBar': 'Apilado',
  'stackedBarTooltip': 'Apilado',
  'stackedColumn': 'Apilado',
  'stackedColumnTooltip': 'Apilado',
  'startsWith': 'Comienza con',
  'strokeOpacity': 'Opacidad de línea',
  'strokeWidth': 'Ancho de trazo',
  'sum': 'Suma',
  'textFilter': 'Filtro de texto',
  'thickness': 'Grosor',
  'thousandSeparator': ',',
  'ticks': 'garrapatas',
  'time': 'Hora',
  'title': 'titulo',
  'titlePlaceholder': 'Título del gráfico: haga doble clic para editar',
  'to': 'Para',
  'tooltips': 'Información sobre herramientas',
  'top': 'Parte superior',
  'totalAndFilteredRows': 'Filas',
  'totalRows': 'Filas totales',
  'true': 'true',
  'ungroupBy': 'Desagrupar por',
  'valueAggregation': 'Agregación de valores',
  'valueColumnsEmptyMessage': 'Arrastra aquí para agregar',
  'values': 'Valores',
  'width': 'Anchura',
  'xOffset': 'Desfase X',
  'xRotation': 'Rotación X',
  'xType': 'Tipo X',
  'xyChart': 'X Y (dispersión)',
  'xyValues': 'Valores X Y',
  'yOffset': 'Desfase en Y',
  'yRotation': 'Rotación Y'
}
