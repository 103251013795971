import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-login-redirect',
  templateUrl: './mobile-login-redirect.component.html',
  styleUrls: ['./mobile-login-redirect.component.scss']
})
export class MobileLoginRedirectComponent {
  public url = window.location;
}
