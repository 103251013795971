import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import english_us from '../../../assets/i18n/en-US.json';
import spanish_latin_america from '../../../assets/i18n/es-419.json';
import french_canada from '../../../assets/i18n/fr-CA.json';
import italian from '../../../assets/i18n/it.json';
import dutch from '../../../assets/i18n/nl.json';
import portuguese_brazil from '../../../assets/i18n/pt-BR.json';
import chinese_simp from '../../../assets/i18n/zh-Hans-CN.json';
import { Language } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private _locale!: string;

  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en-US', english_us);
    this.translate.setTranslation('es-419', spanish_latin_america);
    this.translate.setTranslation('pt-BR', portuguese_brazil);
    this.translate.setTranslation('fr-CA', french_canada);
    this.translate.setTranslation('it', italian);
    this.translate.setTranslation('zh-Hans-CN', chinese_simp);
    this.translate.setTranslation('nl', dutch);

    translate.setDefaultLang('en-US');
    this.locale = navigator.language;
  }

  public get locale(): string {
    return this._locale;
  }

  public set locale(localeStr: string) {
    let isDefault = false;
    switch (localeStr) {
      case 'en-US':
      case 'fr-CA':
      case 'pt-BR':
      case 'es-419':
      case 'zh-Hans-CN':
      case 'it':
      case 'nl':
        this._locale = localeStr
        break;
      default:
        this._locale = 'en-US';
        isDefault = true;
    }

    if (isDefault) {
      if (localeStr.toLowerCase().includes('nl')) {
        // Dutch catch cases
        this._locale = 'nl';
      } else if (localeStr.toLowerCase().includes('es')) {
        // Spanish catch cases
        this._locale = 'es-419';
      } else if (localeStr.toLowerCase().includes('pt')) {
        // Portuguese catch cases
        this._locale = 'pt-BR';
      } else if (localeStr.toLowerCase().includes('fr')) {
        // French catch cases
        this._locale = 'fr-CA';
      } else if (localeStr.toLowerCase().includes('zh') || localeStr.toLowerCase().includes('ch')) {
        // Chinese catch cases
        this._locale = 'zh-Hans-CN';
      }
    }


    this.translate.use(this.locale);
  }

  public setLocaleFromUserLanguage(lang: string): void {
    if (lang === Language.SPANISH) {
      this.locale = 'es-419';
    } else if (lang === Language.PORTUGUESE) {
      this.locale = 'pt-BR';
    } else if (lang === Language.FRENCH) {
      this.locale = 'fr-CA';
    } else if (lang === Language.CHINESE) {
      this.locale = 'zh-Hans-CN';
    } else if (lang === Language.ITALIAN) {
      this.locale = 'it'
    } else if (lang === Language.DUTCH) {
      this.locale = 'nl';
    } else {
      this.locale = 'en-US';
    }
  }

  public getSimplifiedLang(): string {
    switch (this._locale) {
      case 'en-US':
        return 'en';
      case 'fr-CA':
        return 'ca'
      case 'pt-BR':
        return 'br';
      case 'es-419':
        return 'es';
      case 'zh-Hans-CN':
        return 'cn';
      case 'it':
      case 'nl':
        return this._locale;
      default:
        return 'en';
    }
  }
}
