export const environment = {
  production: true,
  isStagingEnv: false,
  isProdEnv: false,
  isAiOne: false,
  hmr: false,
  sharePageBaseUrl: 'https://cname.red/share/',
  vmsAlarmUrl: 'https://lsbetavmsalarm.3deye.me',
  vmsPublicPortalUrl: 'https://lsbetavms.3deye.me',
  vmsAdminUrl: 'https://lsbetavmsadmin.3deye.me',
  vmsCameraAdminUrlParams: '/SystemIntegrator/Cameras/Manage?customerId={customerId}&cameraId={cameraId}',
  eventSLAWarningTimeMinutes: 10,
  eventSLADangerTimeMinutes: 5,
  RSPNDR_TENANT_ID: 'localsec',
  defaultPhone: '+15555555555',
  LOCAL_SEC_MC: 'local-security',
  idleTimeoutMinutes: 30,
  idleTimeoutPingSeconds: 60,
  idleTimeoutPeriodSeconds: 30,
  stripeExpressDashboard: 'https://connect.stripe.com/express_login',
  stripePublishableKey: 'pk_test_51JMx77LKJOSz1EtY9R1UwuMtaig9oXaYOiLXd9odRopBXygomQQ03JyKXZrg5bwfAx4gxm7gLH80noDgB06CSsRa00xe8N3RaG',
  linkBaseUrl: 'https://link.id.ls?app={appUrl}&service={serviceId}',
  stateExclude: ['AW', 'CW', 'SX', 'Sint Maarten (Dutch part)', 'Sint Maarten', 'Curaçao', 'Aruba'],
  vsocUrl: 'https://vsoc.localsecurity.org',
  userFlowToken: 'ct_ati3hfyovfdfxi662e6dsiwyae',
  // eslint-disable-next-line max-len
  agGridLicenseKey: 'Using_this_AG_Grid_Enterprise_key_( AG-046315 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Local Security, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( localsecurity )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( localsecurity )_need_to_be_licensed___( localsecurity )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 8 August 2024 )____[v2]_MTcyMzA3MTYwMDAwMA==578a5227e08b5d0f62cdd17234e02300',
  descopeProjectId: 'P2WDrG44iaY2BoL7JdHCTQuvwKWz',
  descopeFlowId: 'passkeys-web-sign-up-or-in',
  cobrowseLicense: 'ut_dZroP7B9-UQ',
  cobrowseAgentUrl: 'https://cobrowse.io/dashboard',
  sentryDsn: 'https://4674dfdeb3335824d8ebd2ae7db2077b@o4506079068160000.ingest.sentry.io/4506214278955008',
  particlesUrl: 'https://r2.vgs.ai/default_particles.json',
  localGuardApiVersion: '1.15',
  hostedAuthUrl: 'simar.auth.us-east-1.amazoncognito.com',
  amplifyEnv: 'simar',
};
