<div class="incident-site-map w-100 h-100">
  <ng-container *ngIf="siteMapUrl; else noMap">
    <button mat-mini-fab color="primary" class="button new-tab" (click)="openImageInNewWindow(siteMapUrl)"
      [class.button-spinner]="showLoadingSpinner" [disabled]="showLoadingSpinner">
      <mat-icon class="icon--small-no-m">open_in_new</mat-icon>
    </button>
    <pinch-zoom class="h-100 w-100" style="background-color: black;">
      <img [src]="siteMapUrl" />
    </pinch-zoom>
  </ng-container>
  <ng-template #noMap>
    <div class="d-flex justify-content-center align-items-center w-100 h-100">
      {{ 'site-map.no-map' | translate }}
    </div>
  </ng-template>
</div>