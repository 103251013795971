import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom, take } from 'rxjs';
import { environment } from 'src/environments/environment';

import aws_exports from '../../../aws-exports';
import { GlobalStrings } from '../models/global-strings.constants';

import { AuthDescopeService } from './auth-descope.service';
import { RestUrlService } from './rest-url.service';

interface TokenResponse {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  username: string
}

@Injectable()
export class AuthGenericService {

  constructor(private authDescopeService: AuthDescopeService, private cookieService: CookieService,
    private http: HttpClient, private restUrlService: RestUrlService) { }

  /**
   * This method can be used when we want to avoid logging the user out of their profile
   * This should be used when validating the auth guard, etc
   */
  public async onGetSessionToken(): Promise<string | undefined> {
    const descopeToken = await this.authDescopeService.onGetSessionToken();

    if (descopeToken) {
      return descopeToken;
    }

    const cognitoOauthToken = await this.onGetCognitoOauthToken();

    if (cognitoOauthToken) {
      return cognitoOauthToken;
    }

    // TODO log user out because there is no session

    return;
  }

  /**
   * This method can be used when switching profiles
   * it will trigger a re-call for the oauth token
   */
  public async onGetSessionTokenAllowOauthSync(): Promise<string | undefined> {
    const descopeToken = await this.authDescopeService.onGetSessionToken();

    if (descopeToken) {
      return descopeToken;
    }

    const cognitoOauthToken = await this.onGetCognitoOauthTokenUsingAmplifyRefresh();

    if (cognitoOauthToken) {
      return cognitoOauthToken;
    }

    // No session, calling function will log out if appropriate
    return;
  }

  /**
   * This method should be used when we know that we will have a cognito profiles token and an oauth token
   */
  public async onGetCognitoOauthToken(): Promise<string | undefined> {
    const oauthProfileId = this.cookieService.get(GlobalStrings.AUTH_LAST_OAUTH);

    if (!oauthProfileId) {
      return;
    }

    // The user has accessed the app using a cognito hosted flow
    let idToken;

    try {
      idToken = await this.onRefreshCognitoOauthToken(oauthProfileId);
    } catch (e) {
      // If we end up here there is no session
    }

    return idToken ?? undefined;
  }

  /**
   * This method assumes the only cognito user we have is the cognito oauth user
   */
  public async onGetEmail(): Promise<string> {
    const descopeEmail = await this.authDescopeService.onGetEmail();

    if (descopeEmail) {
      return descopeEmail;
    }

    const cognitoOauthEmail = await this.onGetCognitoEmail();
    if (cognitoOauthEmail) {
      return cognitoOauthEmail;
    }


    throw new Error('No logged in user with email found.');
  }

  public onLogout(): void {
    // Log out of descope
    void this.authDescopeService.onLogoutUser();
    // Log out of the cognito oauth
    this.onCognitoOAuthLogout();
  }

  /**
   * This method can be used when we know that the only congito token we will have is for oauth
   */
  private async onGetCognitoOauthTokenUsingAmplifyRefresh(): Promise<string | undefined> {
    const oauthProfileId = this.cookieService.get(GlobalStrings.AUTH_LAST_OAUTH);

    if (!oauthProfileId) {
      return;
    }
    localStorage.setItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}`
      + `.${aws_exports.aws_user_pools_web_client_id}`
      + `.${GlobalStrings.AUTH_LAST_AUTH_USER}`, oauthProfileId);

    try {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        return session.getIdToken().getJwtToken();
      }
    } catch (e) {
      // If we end up here there is no session
    }

    return;
  }

  /**
   * IMPORTANT: This method will only work when the cognito oauth user is the current logged in user
   * (i.e. we don't have a cognito profile yet)
   */
  private async onGetCognitoEmail(): Promise<string | undefined> {
    const oauthProfileId = this.cookieService.get(GlobalStrings.AUTH_LAST_OAUTH);

    if (!oauthProfileId) {
      return;
    }

    try {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const username = await cognitoUser.getUsername();
        if (username === oauthProfileId) {
          return cognitoUser.attributes.email ? cognitoUser.attributes.email as string : undefined;
        }
      }
    } catch (e) {
      // If we end up here there is no session
    }

    return;
  }

  private async onRefreshCognitoOauthToken(oauthProfileId: string): Promise<string> {
    const idToken = localStorage.getItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
      + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_ID_TOKEN}`);

    const refreshToken = localStorage.getItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
      + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_REFRESH_TOKEN}`);

    const accessToken = localStorage.getItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
      + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_ACCESS_TOKEN}`);

    const url = `${this.restUrlService.getPublicApiUrl()}/token`;

    const httpRequest = this.http.post<TokenResponse>(url,
      {
        idToken,
        refreshToken,
        accessToken
      },
      {
        headers: { Authorization: `Bearer ${idToken}` }
      }
    ).pipe(take(1));

    const response = await lastValueFrom(httpRequest).then((tokenResponse: TokenResponse) => {
      localStorage.setItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
        + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_ID_TOKEN}`,
        tokenResponse.idToken);

      localStorage.setItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
        + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_ACCESS_TOKEN}`,
        tokenResponse.accessToken);

      localStorage.setItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
        + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_REFRESH_TOKEN}`,
        tokenResponse.refreshToken);

      return tokenResponse.idToken;

    }).catch((e: HttpErrorResponse) => {
      throw new Error(`${e.status}: ${e.error}`);
    });

    return response;
  }

  private onCognitoOAuthLogout(): void {
    // We have to clear out all of the tokens
    const oauthProfileId = this.cookieService.get(GlobalStrings.AUTH_LAST_OAUTH);
    if (oauthProfileId) {

      localStorage.removeItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
        + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_ID_TOKEN}`);

      localStorage.removeItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
        + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_ACCESS_TOKEN}`);

      localStorage.removeItem(`${GlobalStrings.AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER}.`
        + `${aws_exports.aws_user_pools_web_client_id}.${oauthProfileId}` + `.${GlobalStrings.AUTH_COGNITO_REFRESH_TOKEN}`);

      // Remove cookie that remembered profile
      this.cookieService.delete(GlobalStrings.AUTH_LAST_OAUTH);

      // Auth token items
      window.location.href = `https://${environment.hostedAuthUrl}/logout` +
        `?client_id=${aws_exports.aws_user_pools_web_client_id}&logout_uri=${window.location.origin}/login`

    }
  }
}
