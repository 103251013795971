import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LayoutSpecs } from '../models/layout-specs.constants';
import { PopUpComponent } from './pop-up.component';

@Injectable()
export class PopUpService {
  private opened = false;

  constructor(private dialog: MatDialog) { }

  public openDialog(message: string, title: string): void {
    if (!this.opened) {
      this.opened = true;
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: { message, title },
        maxHeight: LayoutSpecs.MODAL_MAXHEIGHT,
        width: LayoutSpecs.MODAL_WIDTH,
        maxWidth: LayoutSpecs.MODAL_MAXWIDTH,
        disableClose: false,
        hasBackdrop: true
      });

      dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }

  public openCodeDialog(message: string, title: string): void {
    if (!this.opened) {
      this.opened = true;
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: { message, title, code: true },
        maxHeight: LayoutSpecs.MODAL_MAXHEIGHT,
        width: LayoutSpecs.MODAL_WIDTH,
        maxWidth: LayoutSpecs.MODAL_MAXWIDTH,
        disableClose: false,
        hasBackdrop: true
      });

      dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }
}