import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/es-US';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localePt from '@angular/common/locales/pt';
import localeZh from '@angular/common/locales/zh';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import * as Sentry from '@sentry/angular-ivy';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GoComponent } from './pages/go/go.component';
import { HomeComponent } from './pages/home/home.component';
import { LogoutComponent } from './pages/home/logout/logout.component';
import { MobileLoginRedirectComponent } from './pages/home/mobile-login-redirect/mobile-login-redirect.component';
import { ProfilesComponent } from './pages/home/profiles/profiles.component';
import { UniversalQueueAuthComponent } from './pages/home/universal-queue-auth/universal-queue-auth.component';
import { PhoneToastrComponent } from './phone-toastr/phone-toastr.component';
import { AuditLoggerService } from './shared/services/audit-logger.service';
import { AuthDescopeService } from './shared/services/auth-descope.service';
import { AuthGenericGuard } from './shared/services/auth-generic-guard.service';
import { AuthGenericService } from './shared/services/auth-generic.service';
import { AuthGuard } from './shared/services/auth-guard.service';
import { AuthHelperService } from './shared/services/auth-helper.service';
import { BrowserService } from './shared/services/browser.service';
import { LocaleService } from './shared/services/locale.service';
import { ProfilesService } from './shared/services/profiles.service';
import { RestUrlService } from './shared/services/rest-url.service';
import { SpinnerService } from './shared/services/spinner.service';
import { SharedModule } from './shared/shared.module';
import { UserStoreService } from './shared/store/user-store.service';


registerLocaleData(localePt);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeZh);
registerLocaleData(localeEn);
registerLocaleData(localeNl);

// required for AOT compilation
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PhoneToastrComponent,
    ProfilesComponent,
    LogoutComponent,
    UniversalQueueAuthComponent,
    MobileLoginRedirectComponent,
    GoComponent
  ],
  imports: [
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      toastComponent: PhoneToastrComponent
    })
  ],
  exports: [],
  providers: [
    AuthGuard,
    AuthGenericGuard,
    AuthDescopeService,
    AuthGenericService,
    RestUrlService,
    AuditLoggerService,
    SpinnerService,
    UserStoreService,
    AuthHelperService,
    ProfilesService,
    BrowserService,
    CookieService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'es-419' },
    { provide: LOCALE_ID, useValue: 'fr-CA' },
    { provide: LOCALE_ID, useValue: 'zh-Hans-CN' },
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: MAT_DATE_LOCALE,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => localeService.locale
    },
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => localeService.locale
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // }, {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    //   useFactory: () => () => { },
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    readonly router: Router,
    readonly auditLogger: AuditLoggerService,
    readonly browserService: BrowserService
  ) {
    router.events
      .subscribe((e: any) => {
        if (e.routerEvent) {
          void this.auditLogger.logNavigateAction(e.routerEvent.urlAfterRedirects);
          this.browserService.onValidateBrowser();
        }
      });
  }
}