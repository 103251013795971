import { ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { CookieService } from 'ngx-cookie-service';
import { UserGroup } from 'src/app/shared/models/user-groups.model';
import { AuthGenericService } from 'src/app/shared/services/auth-generic.service';
import { AuthHelperService } from 'src/app/shared/services/auth-helper.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ProfilesService } from 'src/app/shared/services/profiles.service';
import { UserStoreService } from 'src/app/shared/store/user-store.service';

@Component({
  selector: 'app-universal-queue-auth',
  templateUrl: './universal-queue-auth.component.html',
  styleUrls: ['./universal-queue-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UniversalQueueAuthComponent implements OnInit {
  private username?: string;
  private incidentId?: string;

  constructor(private router: Router, private cookieService: CookieService,
    private authHelper: AuthHelperService, private userStore: UserStoreService,
    private customerService: CustomerService, private zone: NgZone,
    private profilesService: ProfilesService, private authGenericService: AuthGenericService) {
    const navigation = this.router.getCurrentNavigation();
    this.username = navigation?.finalUrl?.queryParams?.username as string | undefined;
    this.incidentId = navigation?.finalUrl?.queryParams?.incidentId as string | undefined;

    if (!this.username || !this.incidentId) {
      void this.router.navigate(['/profiles']);
    }
  }

  public async ngOnInit(): Promise<void> {
    if (this.username === this.userStore.user?.username) {
      // We shouldn't end up here, but we will include this catch in case
      await this.onNavigateToIncident();
    } else {
      // Log the user out of their current profile and select the new one
      await this.onSelectProfile();
    }
  }

  private async onNavigateToIncident(): Promise<void> {
    await this.router.navigate(['/incidents', this.incidentId]);
  }

  private async onSelectProfile(): Promise<void> {
    try {
      if (!this.username) {
        throw new Error('No username provided');
      }
      const storedToken = await this.authGenericService.onGetSessionTokenAllowOauthSync();
      if (!storedToken) {
        throw new Error('No stored token');
      }

      await this.profilesService.onSelectProfile(this.username, storedToken, true);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(cognitoUser);
      cognitoUser.UserAttributes = attributes;
      const user = this.authHelper.parseCognitoUser(cognitoUser);
      this.userStore.user = user;
      this.cookieService.set(`last-profile-${user.email}`, user.username, { sameSite: 'Strict', expires: 14 });

      if (user.group === UserGroup.Customer) {
        this.zone.run((): void => {
          void this.router.navigate(['/vms'])
        });
        return;
      } else {
        await Promise.all([
          this.customerService.onLoadMonitoringCenter(),
          this.customerService.onLoadIntegrator(),
        ]);
        await this.zone.run(async (): Promise<void> => {
          if (user.group === UserGroup.Operators) {
            // We shouldn't run into this situation, but just in case
            void this.router.navigate(['/vms']);
          } else if (user.monitoringCenterId === 'local-security') {
            // We shouldn't run into this situation, but just in case
            void this.router.navigate(['/admin/customers']);
          } else if (user.group === UserGroup.IntegratorAdmin) {
            // We shouldn't run into this situation, but just in case
            void this.router.navigate(['/admin/customers']);
          } else {
            await this.onNavigateToIncident();
          }
        });
      }

    } catch (e) {
      void this.router.navigate(['/profiles']);
    }
  }

}
