import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-simple-video-viewer',
  templateUrl: './simple-video-viewer.component.html',
  styleUrls: ['./simple-video-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleVideoViewerComponent implements OnInit, OnChanges {
  @Input() public videoUrl?: string;
  public sanitizedUrl?: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) { }

  public ngOnInit(): void {
    this.onSanitizeUrl();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.videoUrl) {
      this.onSanitizeUrl();
    }
  }

  private onSanitizeUrl(): void {
    if (this.videoUrl) {
      this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    }
  }

}
