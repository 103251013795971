import { Injectable } from '@angular/core';
import { APIService, DashboardDetail } from 'src/app/services/graphql.service';

import { UserGroup } from '../models/user-groups.model';
import { UserStoreService } from '../store/user-store.service';


@Injectable()
export class DashboardService {

  constructor(private api: APIService, private userStore: UserStoreService) { }

  /**
   * @param userGroup
   * @returns whether or not a given user can have custom dashboards
   */
  public canHaveCustomDashboards(userGroup: UserGroup): boolean {
    return userGroup !== UserGroup.SuperOperators && userGroup !== UserGroup.Operators
  }

  /**
   * @param username
   * @returns All supplemental dashboards that are customized for the given username
   */
  public async onGetSingleUserDash(username: string): Promise<DashboardDetail[]> {
    const result = await this.api.DashboardDetailsByMonitoringCenter(this.userStore.monitoringCenterId, undefined,
      { archived: { ne: true }, username: { eq: username } });
    if (result.items) {
      return result.items as DashboardDetail[];
    }
    return [];
  }

  /**
   * @param integratorId
   * @returns All supplemental dashboards for Customer.type === "Integrator"
   */
  public async onGetDashboardsForIntAccount(integratorId: string): Promise<DashboardDetail[]> {
    const result = await this.api.DashboardDetailsByMonitoringCenter(this.userStore.monitoringCenterId, undefined,
      { integratorId: { eq: integratorId }, archived: { ne: true } });
    if (result.items) {
      return result.items as DashboardDetail[];
    }
    return [];
  }

  /**
   *
   * @param monitoringCenterId
   * @param integratorId
   * @returns All supplemental dashboards configured for the monitoring center if no integrator id is passed in,
   * otherwise all supplemental dashboards for the integrator
   */
  public async onGetDashboardsForAccount(monitoringCenterId: string, integratorId?: string): Promise<DashboardDetail[]> {
    const filters: any = {
      and: [
        { archived: { ne: true } }, { archived: { ne: true } },
        {
          or: [
            { username: { attributeExists: false } },
            { username: { eq: null } },
            { username: { eq: '' } }
          ]
        }
      ]
    };

    if (integratorId) {
      filters.and.push({ integratorId: { eq: integratorId } })
    } else {
      filters.and.push({
        or: [
          { integratorId: { attributeExists: false } },
          { integratorId: { eq: null } },
          { integratorId: { eq: '' } }
        ]
      });
    }
    const result = await this.api.DashboardDetailsByMonitoringCenter(monitoringCenterId, undefined, filters);
    if (result.items) {
      return result.items as DashboardDetail[];
    }
    return [];
  }

  /**
   *
   * @returns All dashboards that the user has permission to view
   */
  public async getAllDashboardsForUser(): Promise<DashboardDetail[]> {
    const dashboards: DashboardDetail[] = [];
    const result = await this.api.DashboardDetailsByMonitoringCenter(this.userStore.monitoringCenterId, undefined,
      { archived: { ne: true } });
    if (result.items) {
      const items = result.items as DashboardDetail[];

      const userGroup = this.userStore.user!.group;

      // Any MC level dashboards assigned to this user group
      const mcLevel = items.filter(x => x?.allowedGroups.includes(userGroup)) ?? [];
      dashboards.push(...mcLevel);

      if (userGroup === UserGroup.IntegratorAdmin) {
        // See if there are integrator level dashboards
        const intLevel = items.filter(x => x.integratorId === this.userStore.user?.integratorId) ?? [];
        dashboards.push(...intLevel);
      }

      // See if there are any user level dashboards
      const userLevel = items.filter(x => x.username === this.userStore.user?.username) ?? [];
      dashboards.push(...userLevel);
    }
    return dashboards;
  }

  /**
   *
   * @returns The BoldBI URL for the user's monitoring center.
   */
  public getBoldBUrl(): string | undefined {
    return this.userStore.monitoringCenter?.boldBIIntegration?.siteUrl;
  }

  /**
   *
   * @returns the default dashboard for the monitoring center
   */
  public getDefaultDashboardId(): string | undefined {
    return this.userStore.monitoringCenter?.boldBIIntegration?.siteDefaultDashboardId;
  }
}
