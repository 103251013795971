import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ErrorCodes } from '../models/error-codes.enum';
import { PopUpService } from '../pop-up/pop-up.service';

@Injectable()
export class ErrorService {
  private errorMessage?: string;
  private errorTitle?: string;

  constructor(private popupService: PopUpService, private translate: TranslateService) {
    this.translate.get('error-codes.error').subscribe((text: string) => this.errorTitle = text);
  }

  public handleError(error: any): void {
    // We get this error message when we're switching user profiles
    if (error.message !== 'No current user') {
      this.getErrorMessage(error);
      this.popupService.openCodeDialog(this.errorMessage ?? '', this.errorTitle ?? '');
    }
  }

  private getErrorMessage(error: any): void {
    if (typeof error === 'string') {
      try {
        const rsp = JSON.parse(error);
        if (rsp.errorCode) {
          this.translate.get(`error-codes.${rsp.errorCode}`).subscribe((text: string) => {
            this.errorMessage = text;
            if (rsp.errorCode === ErrorCodes.VMSCustomError) {
              this.errorMessage += `<br><br>${rsp.message}`;
            }
          });
        } else if (rsp.message) {
          this.errorMessage = rsp.message;
        }
      } catch (e) {
        this.errorMessage = error;
      }
    } else if (error.body && typeof error.body === 'string') {
      try {
        const rsp = JSON.parse(error.body);
        if (rsp.errorCode) {
          this.translate.get(`error-codes.${rsp.errorCode}`).subscribe((text: string) => {
            this.errorMessage = text;
            if (rsp.errorCode === ErrorCodes.VMSCustomError || ErrorCodes.CustomError) {
              this.errorMessage += `<br><br>${rsp.message}`;
            }
          });
        } else if (rsp.message) {
          this.errorMessage = rsp.message;
        }
      } catch (e) {
        this.errorMessage = error.body;
      }
    } else if (typeof error.response === 'object' && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.errorMessage) {
      this.errorMessage = error.errorMessage;
    } else if (typeof error === 'object' && error.errors && error.errors[0]) {
      let errorStr = '';
      for (const err of error.errors) {
        errorStr += `${err.message}\n`;
      }
      this.errorMessage = errorStr;
    } else {
      this.translate.get(ErrorCodes.ErrorOccurred).subscribe((text: string) => this.errorMessage = text);
    }
  }
}
