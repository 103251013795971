<div class="incident-cameras px-2 py-1">
  <div class="d-flex justify-content-center mb-2 mt-1">
    <span class="cursor--pointer ml-auto" (click)="accordion.openAll()">
      <mat-icon class="icon--small">unfold_more</mat-icon>
    </span>

    <span class="cursor--pointer ml-2" (click)="accordion.closeAll()">
      <mat-icon class="icon--small">unfold_less</mat-icon>
    </span>
  </div>

  <mat-accordion class="cameras-list-panel" multi>
    <mat-expansion-panel *ngFor="let camera of cameras" class="mb-3" hideToggle>
      <mat-expansion-panel-header class="d-flex justify-content-center py-2" [collapsedHeight]="'auto'"
        [expandedHeight]="'auto'">
        <mat-panel-title class="align-items-center d-flex mr-0">
          <mat-icon *ngIf="camera.deviceType === deviceType.CAMERA">
            videocam
          </mat-icon>
          <mat-icon *ngIf="camera.deviceType === deviceType.AUDIO">
            volume_up
          </mat-icon>
          <mat-icon *ngIf="camera.deviceType === deviceType.GATEWAY">
            router
          </mat-icon>
          <span class="ml-1">
            {{ camera.name }}
          </span>
          <span class="ml-auto gray-600">
            {{camera.vmsCameraId}}
          </span>
          <div class="ml-2">
            <ng-container *ngIf="camera.deviceType === deviceType.CAMERA; else notCamera">
              <span class="fixed-width-status">
                <ng-container *ngIf="camera.healthCheckStatus === healthCheckStatus.PASS; else badStatus">
                  <mat-icon class="good">check_circle</mat-icon>
                </ng-container>
                <ng-template #badStatus>
                  <mat-icon class="danger">error</mat-icon>
                </ng-template>
              </span>
            </ng-container>
            <ng-template #notCamera>
              <span class="fixed-width-status"></span>
            </ng-template>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-content">

        <!-- Buttons -->
        <div class="d-flex flex-wrap mb-3 action-btns">
          <button mat-flat-button color="primary" class="button" (click)="onGetTroubleshootingUrl(camera)"
            *ngIf="displayTroubleshootingUrlBtn(camera)"
            [class.button-spinner]="loadingSet.has('troubleshooting-' + camera.id)"
            [disabled]="loadingSet.has('troubleshooting-' + camera.id)">
            <mat-icon class="icon--small">link</mat-icon>
            {{ 'camera-list.device-url' | translate }}
          </button>
          <button mat-flat-button color="primary" class="button" (click)="onResetCamera(camera)"
            *ngIf="camera.deviceType !== deviceType.GATEWAY && !authHelper.userInGroup([userGroups.IntegratorAdmin, userGroups.SuperOperators])">
            <mat-icon class="icon--small">refresh</mat-icon>
            {{ 'camera-list.reset-connection' | translate }}
          </button>
          <button mat-flat-button class="button ml-auto" color="primary" (click)="onViewInVMS(camera)">
            <mat-icon class="icon--small">open_in_new</mat-icon>
            {{ 'camera-list.vms' | translate }}
          </button>
          <button *ngIf="camera.deviceType !== deviceType.GATEWAY" mat-flat-button color="accent"
            (click)="onViewInMain(camera)" class="button ml-auto">
            <ng-container *ngIf="camera.deviceType === deviceType.CAMERA">
              {{ 'button.view' | translate }}
            </ng-container>

            <ng-container *ngIf="camera.deviceType === deviceType.AUDIO">
              {{ 'button.talk' | translate }}
            </ng-container>

            <mat-icon class="icon--small">arrow_forward</mat-icon>
          </button>
        </div>

        <!-- Camera Status -->
        <div>
          <div class="d-flex mb-2">
            <div class="d-flex align-items-center w-33">
              <ng-container *ngIf="camera.vmsConnect; else noVMSConnect">
                <mat-icon class="good">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noVMSConnect>
                <mat-icon class="danger">error_outline</mat-icon>
              </ng-template>
              <span class="ml-2">
                {{'camera-list.connect' | translate}}
              </span>
            </div>
            <div class="d-flex align-items-center w-33">
              <ng-container
                *ngIf="camera.deviceUrlOnline && camera.deviceType !== deviceType.CAMERA; else noDeviceUrlOnline">
                <mat-icon class="good">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noDeviceUrlOnline>
                <mat-icon class="danger">error_outline</mat-icon>
              </ng-template>
              <span class="ml-2">
                {{'camera-list.url-online' | translate}}
              </span>
            </div>
            <div class="d-flex align-items-center mr-auto ml-auto w-33" *ngIf="camera.deviceType === deviceType.CAMERA">
              <ng-container *ngIf="camera.videoStreamState === 'Online'; else videoOffline">
                <mat-icon class="good">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #videoOffline>
                <mat-icon class="danger">error_outline</mat-icon>
              </ng-template>
              <span class="ml-2">
                {{'camera-list.video-stream-online' | translate}}
              </span>
            </div>
            <div class="d-flex align-items-center w-33" *ngIf="camera.deviceType === deviceType.CAMERA">
              <ng-container *ngIf="camera.isOnvif; else noOnvif">
                <mat-icon class="good">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noOnvif>
                <mat-icon class="danger">error_outline</mat-icon>
              </ng-template>
              <span class="ml-2">
                {{'camera-list.onvif' | translate}}
              </span>
            </div>
          </div>
          <div class="d-flex mb-4" *ngIf="camera.deviceType === deviceType.CAMERA">
            <div class="d-flex align-items-center w-33">
              <ng-container *ngIf="camera.isAnalyticsEnabled; else noAnalytics">
                <mat-icon class="good">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noAnalytics>
                <mat-icon class="danger">error_outline</mat-icon>
              </ng-template>
              <span class="ml-2">
                {{'camera-list.analytics' | translate}}
              </span>
            </div>
            <div class="d-flex align-items-center ml-auto mr-auto w-33">
              <ng-container *ngIf="camera.isMotionEnabled; else noMotion">
                <mat-icon class="good">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noMotion>
                <mat-icon class="danger">error_outline</mat-icon>
              </ng-template>
              <span class="ml-2">
                {{'camera-list.motion' | translate}}
              </span>
            </div>
            <div class="d-flex align-items-center w-33">
              <ng-container *ngIf="camera.passingSceneDetection; else noSceneDetection">
                <mat-icon class="good">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noSceneDetection>
                <ng-container *ngIf="camera.passingSceneDetection === false; else sceneDetectionNotEnabled">
                  <mat-icon class="danger">error_outline</mat-icon>
                </ng-container>
                <ng-template #sceneDetectionNotEnabled>
                  <mat-icon class="warning">not_interested</mat-icon>
                </ng-template>
              </ng-template>
              <span class="ml-2">
                {{'camera-list.scene-change' | translate}}
              </span>
            </div>
          </div>

        </div>
        <!-- Thumbnails -->
        <div class="d-flex thumbnails" *ngIf="cameraUrlsMap.has(camera.id!)!">
          <div class="thumbnail w-50">
            <p class="mb-2">{{ 'camera-list.baseline-thumbnail' | translate }}:</p>
            <img class="cursor--pointer w-100" src="{{cameraUrlsMap.get(camera.id!)![0]}}"
              (click)="onViewThumbnail(cameraUrlsMap.get(camera.id!)![0], true)" />
          </div>
          <div class="thumbnail w-50">
            <p class="mb-2">{{ 'camera-list.current-thumbnail' | translate }}:</p>
            <img class="w-100 cursor--pointer" *ngIf="cameraUrlsMap.get(camera.id!)!.length === 2"
              src="{{cameraUrlsMap.get(camera.id!)![1]}}"
              (click)="onViewThumbnail(cameraUrlsMap.get(camera.id!)![1], false)" />
          </div>
        </div>


        <!-- Camera Details -->
        <table class="details-table">
          <tr>
            <td>{{ 'camera-list.vms-name' | translate}}:</td>
            <td>{{camera.vmsCameraName}}</td>
          </tr>
          <tr>
            <td>{{ 'camera-list.status' | translate}}:</td>
            <td>{{ 'DataEntryStatus.' + camera.status | translate }}</td>
          </tr>
          <tr>
            <td>{{ 'camera-list.critical-notes' | translate}}:</td>
            <td class="danger"><span *ngIf="camera.criticalNotes">
                <app-localize-user-input [value]="camera.criticalNotes"
                  [translatedValues]="camera.criticalNotesTranslated" [sanitizedValues]="camera.criticalNotesSanitized">
                </app-localize-user-input>
              </span>
            </td>
          </tr>
          <tr>
            <td>{{ 'camera-list.description' | translate}}:</td>
            <td>
              <ng-container *ngIf="camera.description">
                <app-localize-user-input [value]="camera.description" [translatedValues]="camera.descriptionTranslated"
                  [sanitizedValues]="camera.descriptionSanitized">
                </app-localize-user-input>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td>{{ 'camera-list.vms-description' | translate}}:</td>
            <td>{{camera.vmsDescription}}</td>
          </tr>
          <tr *ngIf="camera.deviceType !== deviceType.GATEWAY">
            <td>{{ 'camera-list.coordinates' | translate}}:</td>
            <td *ngIf="camera.latitude && camera.longitude">{{camera.latitude}}, {{camera.longitude}}</td>
          </tr>
          <tr>
            <td *ngIf="camera.deviceType !== deviceType.GATEWAY">{{ 'camera-list.zone' | translate}}:</td>
            <td *ngIf="camera.zoneNumber && camera.zoneDescription">
              <span class="d-flex align-items-center"><span class="mr-1">{{camera.zoneNumber}} -</span>
                <app-localize-user-input [value]="camera.zoneDescription"
                  [translatedValues]="camera.zoneDescriptionTranslated"
                  [sanitizedValues]="camera.zoneDescriptionSanitized">
                </app-localize-user-input>
              </span>
            </td>
          </tr>
          <tr *ngIf="camera.deviceType !== deviceType.GATEWAY">
            <td>{{ 'camera-list.has-microphone' | translate }}:</td>
            <td class="d-flex">
              <ng-container *ngIf="camera.hasMic; else noMic">
                <mat-icon class="good icon--small-no-m">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noMic>
                <mat-icon class="warning icon--small-no-m">not_interested</mat-icon>
              </ng-template>
            </td>
          </tr>
          <tr *ngIf="camera.deviceType !== deviceType.GATEWAY">
            <td>{{ 'camera-list.has-speaker' | translate }}:</td>
            <td class="d-flex">
              <ng-container *ngIf="camera.hasSpeaker; else noSpeaker">
                <mat-icon class="good icon--small-no-m">check_circle_outline</mat-icon>
              </ng-container>
              <ng-template #noSpeaker>
                <mat-icon class="warning icon--small-no-m">not_interested</mat-icon>
              </ng-template>
            </td>
          </tr>
          <ng-container *ngIf="cameraAccessDetailsMap.has(camera.id)">
            <tr>
              <td>{{ 'camera-list.device-url' | translate}}:</td>
              <td>
                <a class="color--accent" [href]="cameraAccessDetailsMap.get(camera.id)!.url" target="_blank">
                  {{cameraAccessDetailsMap.get(camera.id)!.url}}</a>
              </td>
            </tr>
            <tr>
              <td>{{ 'camera-list.device-username' | translate}}:</td>
              <td>{{cameraAccessDetailsMap.get(camera.id)!.username}}</td>
            </tr>
            <tr>
              <td>{{ 'camera-list.device-password' | translate}}:</td>
              <td>{{cameraAccessDetailsMap.get(camera.id)!.password}}</td>
            </tr>
          </ng-container>
        </table>

      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>