import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { IncidentAuditService } from 'src/app/shared/services/incident-audit.service';

import { CallDetails } from '../models/call-details.model';
import { AuditLoggerService } from '../services/audit-logger.service';
import { CallService } from '../services/call.service';
import { UserStoreService } from '../store/user-store.service';


@Directive({
  selector: '[initiateCall]',
  host: {
    '[style.cursor]': '"pointer"',
  }
})
export class InitiateCallDirective {
  @Input() public incidentId?: string;
  @Input() public siteId?: string;
  @Input() public customerId?: string;
  @Input() public entityId?: string; // Entity id is populated if it's not site, customer, or incident
  @Input() public entityType!: string;

  constructor(private element: ElementRef, private auditLogger: AuditLoggerService,
    private incidentAudit: IncidentAuditService, private callService: CallService,
    private userStore: UserStoreService) {
    if (this.userStore.callingEnabled) {
      this.element.nativeElement.classList.add('cursor--pointer');
    }
  }

  @HostListener('click') public onClick(): void {
    if (this.userStore.callingEnabled) {
      const phone: string = this.element.nativeElement.innerText;
      if (this.entityType.toLowerCase() === 'incident' && this.incidentId) {
        void this.incidentAudit.logAction(`Call initiated to ${phone}`, this.incidentId);
      } else {
        let id = this.entityId;
        if (this.siteId) {
          id = this.siteId;
        } else if (this.customerId) {
          id = this.customerId;
        }
        void this.auditLogger.logClickAction(`Call initiated to ${phone} for ${this.entityType.toLowerCase()} ${id}`);
      }
      const callDetails = new CallDetails({
        phoneNumber: phone,
        customerId: this.customerId,
        siteId: this.siteId,
        incidentId: this.incidentId
      });
      this.callService.triggerCall(callDetails);
    }
  }

}
