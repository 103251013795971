import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom, take } from 'rxjs';

import { ApiUserProfile } from '../models/api-user-profile.interface';
import { GlobalStrings } from '../models/global-strings.constants';
import { UserGroup } from '../models/user-groups.model';
import { UserStoreService } from '../store/user-store.service';

import { AuthHelperService } from './auth-helper.service';
import { CustomerService } from './customer.service';
import { RestUrlService } from './rest-url.service';


@Injectable()
export class ProfilesService {

  constructor(private userStore: UserStoreService, private authHelper: AuthHelperService,
    private zone: NgZone, private router: Router,
    private customerService: CustomerService, private http: HttpClient,
    private cookieService: CookieService, private restUrlService: RestUrlService) { }

  public async onSelectProfile(username: string, token: string, logoutExisting?: boolean): Promise<void> {
    if (logoutExisting) {
      this.userStore.onLogout();
      void this.onLogoutCurrentProfileSession();
    }

    const signInResponse = await Auth.signIn(username);
    await Auth.sendCustomChallengeAnswer(signInResponse, signInResponse.challengeParam.code, { token });

    // This will throw an error if the user is not yet authenticated:
    await Auth.currentSession();
  }

  public async onProfileSignIn(): Promise<void> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(cognitoUser);
    cognitoUser.UserAttributes = attributes;
    const user = this.authHelper.parseCognitoUser(cognitoUser);
    this.userStore.user = user;
    this.cookieService.set(`last-profile-${user.email}`, user.username, { sameSite: 'Strict', expires: 14 });

    if (user.group === UserGroup.Customer) {
      this.zone.run(() => {
        void this.router.navigate(['/vms']);
      });
      return;
    } else {
      await Promise.all([
        this.customerService.onLoadMonitoringCenter(),
        this.customerService.onLoadIntegrator(),
      ]);
      this.zone.run(() => {
        const currentUrl = this.router.url;
        let newUrl;
        if (user.group === UserGroup.Operators) {
          newUrl = '/vms';
        } else if (user.monitoringCenterId === 'local-security') {
          newUrl = '/admin/customers';
        } else if (user.group === UserGroup.IntegratorAdmin) {
          newUrl = '/admin/customers';
        } else if (user.group === UserGroup.Customer) {
          newUrl = '/vms';
        } else {
          newUrl = '/incidents';
        }
        if (newUrl === currentUrl) {
          window.location.reload();
        } else {
          void this.router.navigate([newUrl]);
        }
      });
    }
  }

  public async onListUserProfiles(token: string): Promise<ApiUserProfile[]> {
    const url = `${this.restUrlService.getPublicApiUrl()}/me/profiles?listing=true`;

    const httpRequest = this.http.get<ApiUserProfile[]>(url,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ).pipe(take(1));

    const response = await lastValueFrom(httpRequest).then((users: ApiUserProfile[]) => {
      return users;
    }).catch((e: HttpErrorResponse) => {
      throw new Error(`${e.status}: ${e.error}`);
    })

    return response;
  }

  public async onLogoutCurrentProfileSession(): Promise<void> {
    // We only want to logout the session if it IN NOT the "oauth" session
    try {
      const session = await Auth.currentSession();
      const token = session.getAccessToken();
      const payload = token.decodePayload();

      const oauthProfileId = this.cookieService.get(GlobalStrings.AUTH_LAST_OAUTH);
      if (payload.username !== oauthProfileId) {
        await Auth.signOut();
      }
    } catch (e) {
      // If we end up here ignore the error because we aren't logged in
    }
  }
}