import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { AuthDescopeService } from 'src/app/shared/services/auth-descope.service';
import { environment } from 'src/environments/environment';
import { loadFull } from 'tsparticles';
import { Container, Engine } from 'tsparticles-engine';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  @ViewChild('descopeAuth') public descopeAuth!: ElementRef;
  public logoUrl;
  public particlesUrl = environment.particlesUrl;
  private hasMira = true;
  private successUnlisten?: () => void;
  private destroy$ = new Subject<void>();

  constructor(private renderer: Renderer2, private router: Router,
    private route: ActivatedRoute, private authDescopeService: AuthDescopeService,
    private cookieService: CookieService, private el: ElementRef) {
    const logoCookie = this.cookieService.get(`custom-logo-${window.location.hostname}`);
    if (logoCookie) {
      this.logoUrl = logoCookie;
    } else if (environment.isAiOne) {
      this.logoUrl = '../../../assets/aione.png';
    } else {
      this.logoUrl = '../../../assets/local-security.svg';
    }
  }

  public ngAfterViewInit(): void {
    this.hasMira = this.el.nativeElement.ownerDocument.body.classList.contains('mira-theme');
    this.initDescopeWc();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.successUnlisten) {
      this.successUnlisten();
    }
  }

  public async particlesInit(engine: Engine): Promise<void> {
    await loadFull(engine);
  }

  public particlesLoaded(container: Container): void {
    if (!this.hasMira) {
      container.stop();
    }
  }

  public onGoToNew(): void {
    void this.router.navigate(['/login']);
  }

  private initDescopeWc(): void {
    try {
      const descopeInfo = this.authDescopeService.getDescopeInfo();
      const wcElement = this.renderer.createElement('descope-wc');
      this.renderer.setAttribute(wcElement, 'project-id', descopeInfo.projectId);
      this.renderer.setAttribute(wcElement, 'flow-id', descopeInfo.flowId);
      this.renderer.setAttribute(wcElement, 'base-url', descopeInfo.authUrl);
      this.renderer.setAttribute(wcElement, 'theme', 'dark');

      this.descopeAuth.nativeElement.appendChild(wcElement);

      const onSuccess = (): void => {
        let returnUrl = decodeURIComponent(this.route.snapshot.queryParams['dReturnUrl']);
        if (!returnUrl || !returnUrl.includes('universal-queue-auth')) {
          returnUrl = '/profiles';
        }
        if (!returnUrl.startsWith('/')) {
          returnUrl = `/${returnUrl}`;
        }
        void this.router.navigateByUrl(`${returnUrl}`);
      };

      this.successUnlisten = this.renderer.listen(wcElement, 'success', onSuccess);
    } catch (e) {
      // We were unable to configure the descope sdk, that's okay, not a fatal error
      // this will happen most commonly if descopeInfo.authUrl is not configured within cloudflare
    }
  }

}