import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiUserProfile } from 'src/app/shared/models/api-user-profile.interface';
import { AuthGenericService } from 'src/app/shared/services/auth-generic.service';
import { AuthHelperService } from 'src/app/shared/services/auth-helper.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ProfilesService } from 'src/app/shared/services/profiles.service';


@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilesComponent implements OnInit {
  public profiles: ApiUserProfile[] = [];
  public showLoadingSpinner = true;
  public error?: string;
  public usersLoaded = false;
  public loadingProfile = false;
  public showEmailVerification = false;

  private storedToken?: string;

  constructor(private authGenericService: AuthGenericService, private errorHandler: ErrorService,
    private ref: ChangeDetectorRef, private profilesService: ProfilesService,
    private authHelper: AuthHelperService, private translate: TranslateService,
    private cookieService: CookieService) { }

  public async ngOnInit(): Promise<void> {
    this.showLoadingSpinner = true;
    this.ref.markForCheck();

    await this.profilesService.onLogoutCurrentProfileSession();

    this.storedToken = await this.authGenericService.onGetSessionTokenAllowOauthSync();
    if (!this.storedToken) {
      this.translate.get('profiles.no-session').subscribe((text: string) => this.error = text);
      this.ref.markForCheck();
      this.showLoadingSpinner = false;
      return;
    }

    let profileLookupResult;
    try {
      profileLookupResult = await this.onDetermineProfileToUser();
    } catch (e) {
      this.errorHandler.handleError(e);
      this.authHelper.onLogout();
      this.showLoadingSpinner = false;
      return;
    }

    let username = profileLookupResult.username;
    if (!username) {
      this.translate.get('profiles.no-profile-message').subscribe((text: string) => this.error = text);
      this.ref.markForCheck();
      this.showLoadingSpinner = false;
    } else {
      try {
        this.showLoadingSpinner = true;
        await this.onSelectProfile(username);
        await this.profilesService.onProfileSignIn();
      } catch (e) {
        if (profileLookupResult.stored) {
          // We need to wipe out the stored username and try again
          this.cookieService.delete(`last-profile-${profileLookupResult.email}`);
          profileLookupResult = await this.onDetermineProfileToUser();
          username = profileLookupResult.username;
          if (!username) {
            this.translate.get('profiles.no-profile-message').subscribe((text: string) => this.error = text);
            this.ref.markForCheck();
            this.showLoadingSpinner = false;
          } else {
            try {
              this.showLoadingSpinner = true;
              await this.onSelectProfile(username);
              await this.profilesService.onProfileSignIn();
            } catch (e2) {
              this.errorHandler.handleError(e2);
              this.authHelper.onLogout();
            }
          }
        } else {
          this.errorHandler.handleError(e);
          this.authHelper.onLogout();
        }
      } finally {
        this.showLoadingSpinner = false;
      }
    }
  }

  public async onSelectProfile(username: string): Promise<void> {
    this.showLoadingSpinner = true;
    // Stored token will not be undefined at this point
    await this.profilesService.onSelectProfile(username, this.storedToken!);

  }

  public onLogout(): void {
    this.authHelper.onLogout();
  }

  private async onListUserProfiles(): Promise<ApiUserProfile[] | undefined> {
    try {
      // Stored token will not be undefined at this point
      return await this.profilesService.onListUserProfiles(this.storedToken!);
    } catch (e) {
      this.error = e as string;
      this.showLoadingSpinner = false;
      this.ref.markForCheck();
    }
    return;
  }

  private async onDetermineProfileToUser(): Promise<{ username: string, stored: boolean, email: string }> {
    let email;
    try {
      email = await this.authGenericService.onGetEmail();
    } catch (e) {
      throw new Error('Unable to determine email.');
    }

    let stored = true;

    // We have an email, now see if there is a username in cookie storage
    let username = this.cookieService.get(`last-profile-${email}`);
    if (!username) {
      // Look up the profiles and pick the first alphabetically
      const users = await this.onListUserProfiles()
      if (users && users.length > 0) {
        username = users[0].username;
      }
      stored = false;
    }
    return {
      username: username || '',
      stored,
      email
    };
  }

}
