<ng-container *ngIf="showLoadingSpinner">
  <div id="loading">
    <mat-spinner class="loading--spinner"></mat-spinner>
  </div>
</ng-container>
<div class="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
  <div class="mat-elevation-z8 p-5 text-align-center profiles-prompt" *ngIf="error">
    <h4>{{error}}</h4>
    <button id="tag-profiles-logout" mat-flat-button color="accent" (click)="onLogout()">
      {{ 'profiles.logout' | translate }}
    </button>
  </div>
</div>