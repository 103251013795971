import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AttachmentData {
  name: string;
  url: string;
}

@Component({
  selector: 'app-popup-image',
  templateUrl: './popup-image.component.html',
  styleUrls: ['./popup-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupImageComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AttachmentData) { }
}
