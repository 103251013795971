import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CallDetails } from '../models/call-details.model';
@Injectable()
export class CallService {
  public callNotice: Subject<CallDetails> = new Subject<CallDetails>();

  public triggerCall(callDetails: CallDetails): void {
    this.callNotice.next(callDetails);
  }

}
