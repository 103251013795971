import { Injectable } from '@angular/core';
import { API, graphqlOperation } from 'aws-amplify';
import { Customer } from 'src/app/services/graphql.service';

import { UserStoreService } from '../store/user-store.service';

@Injectable()
export class CustomerService {

  constructor(private userStore: UserStoreService) { }

  public async onLoadMonitoringCenter(): Promise<void> {
    const monitoringCenterQuery = await API.graphql(graphqlOperation(`
          query GetCustomer($id: ID!) {
            getCustomer(id: $id) {
              id
              name
              invoicePrefix
              softphoneEnabled
              softphoneNumber
              callbackNumber
              callbackNumberExt
              zendeskWidgetScript
              vmsMCWebhookId
              vmsUrl
              vmsAlarmUrl
              vmsAdminUrl
              rapidSOSIntegration {
                webhookEnabled
              }
              boldBIIntegration {
                id
                siteUrl
                siteDefaultDashboardId
              }
            }
          }
        `, { id: this.userStore.user!.monitoringCenterId }));
    const monitoringCenter = monitoringCenterQuery.data.getCustomer;

    if (monitoringCenter) {
      this.userStore.monitoringCenter = monitoringCenter as Customer;
    }
  }

  public async onLoadIntegrator(): Promise<void> {
    if (!this.userStore.user!.integratorId) {
      return;
    }
    const integratorQuery = await API.graphql(graphqlOperation(`
          query GetCustomer($id: ID!) {
            getCustomer(id: $id) {
              id
              name
            }
          }
        `, { id: this.userStore.user!.integratorId }));
    const integrator = (integratorQuery).data.getCustomer;

    if (integrator) {
      this.userStore.integrator = integrator as Customer;
    }
  }
}
