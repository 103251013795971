export class CognitoCustomerUser {
  public username!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public phone?: string;
  public enabled!: boolean;
  public readonly!: boolean

  constructor(init?: Partial<CognitoCustomerUser>) {
    Object.assign(this, init);
  }
}