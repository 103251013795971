import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { AuditActions } from '../models/audit-actions.constants';
import { UserGroup } from '../models/user-groups.model';
import { PopUpService } from '../pop-up/pop-up.service';
import { UserStoreService } from '../store/user-store.service';
import { AuditLoggerService } from './audit-logger.service';
import { AuthHelperService } from './auth-helper.service';
import { ErrorService } from './error.service';
import { SpinnerService } from './spinner.service';

import { APIService, AudioMessageType, ObjectGroup, GroupsBySiteTypeQuery, Camera, AudioCustomMessageType, ObjectGroupType, AudioMessageTypesBySiteQuery } from 'src/app/services/graphql.service';
import { environment } from 'src/environments/environment';
import { Camera as CameraModel, DeviceType, LambdaResponse } from 'src/models';
import { getVMSAdminUrl } from '../utils/vms.utils';
import { CameraAccessDetails } from '../models/camera-access-details.model';
import { PlayMessageResult } from '../interfaces/play-message-result.interface';
import { CxDevice } from '../models/cx-incident.model';

@Injectable()
export class CameraService {

  constructor(private api: APIService, private errorHandler: ErrorService,
    private auditLogger: AuditLoggerService, private spinner: SpinnerService,
    private translate: TranslateService, private popupService: PopUpService,
    private authHelper: AuthHelperService, private userStore: UserStoreService) { }

  public async onRunHealthCheck(camera: Camera | CameraModel): Promise<void> {
    if (this.authHelper.userInGroup([UserGroup.Operators, UserGroup.SuperOperators, UserGroup.IntegratorAdmin])) {
      return;
    }
    this.spinner.loading();
    this.auditLogger.logClickAction(`${AuditActions.SEND} health check requested. VMS Camera Id: ${camera.vmsCameraId}`);
    this.api.VmsDeviceHealthCheck(camera.id).then(rsp => {
      if (rsp.statusCode !== 200) {
        this.errorHandler.handleError(rsp);
      }
    }).catch(e => this.errorHandler.handleError(e)).finally(() => this.spinner.done());
  }

  public onViewInVMS(camera: Camera | CameraModel | CxDevice): void {
    let url = getVMSAdminUrl(this.userStore.monitoringCenter);
    void this.auditLogger.logClickAction(`${AuditActions.VIEW} camera in VMS. VMS Camera Id: ${camera.vmsCameraId}`);
    if (camera.vmsCameraId && camera.vmsCustomerId) {
      url = `${getVMSAdminUrl(this.userStore.monitoringCenter)}${environment.vmsCameraAdminUrlParams}`
        .replace('{customerId}', camera.vmsCustomerId)
        .replace('{cameraId}', camera.vmsCameraId);
    }
    window.open(url, '_blank');
  }


  public async onResetCamera(camera: Camera | CameraModel): Promise<void> {
    if (this.authHelper.userInGroup([UserGroup.Operators, UserGroup.SuperOperators, UserGroup.IntegratorAdmin])) {
      return;
    }
    if (camera.lastPowerCycle && moment(camera.lastPowerCycle).isAfter(moment().subtract(1, 'hour'))) {
      this.translate.get('camera-list.power-cycle-too-soon').subscribe(text => {
        text = text.replace('{lastCycle}', moment().diff(moment(camera.lastPowerCycle), 'minutes').toString());
        this.popupService.openDialog(text, '');
      });
    } else {
      this.spinner.loading();
      this.api.VmsPowerCycleCamera(camera.id!).then(rsp => {
        if (rsp.statusCode !== 200) {
          this.errorHandler.handleError(rsp);
        } else {
          this.translate.get('camera-list.device-reset-in-process').subscribe((text: string) => {
            this.popupService.openDialog(text, '');
          });
        }
      }).catch(e => this.errorHandler.handleError(e)).finally(() => this.spinner.done());
    }
  }

  public async onToggleSignalTest(camera: Camera | CameraModel): Promise<void> {
    if (this.authHelper.userInGroup([UserGroup.Operators, UserGroup.SuperOperators, UserGroup.IntegratorAdmin])) {
      return;
    }

    // Determine if camera is currently in signal test
    const isTesting = camera.testingExpiry && moment(camera.testingExpiry).isAfter(moment());
    let testingExpiry;
    if (isTesting) {
      testingExpiry = moment().toISOString();
    } else {
      testingExpiry = moment().add(2, 'hours').toISOString();
    }
    this.spinner.loading();
    await this.api.UpdateCamera({
      id: camera.id,
      testingExpiry,
      modifiedBy: this.userStore.user?.username
    }).catch(e => this.errorHandler.handleError(e));
    this.spinner.done();
  }

  public onDetermineDeviceType(deviceModel?: string, pnpCode?: string): DeviceType {
    if (deviceModel === 'IP Speaker') {
      return DeviceType.AUDIO;
    }

    if (pnpCode?.startsWith('RASPI') && pnpCode.length === 'RASPI____________'.length) {
      return DeviceType.GATEWAY;
    }

    return DeviceType.CAMERA;
  }

  public async onGetTroubleshootingUrl(camera: Camera | CameraModel): Promise<CameraAccessDetails | void> {
    return await this.api.VmsGetTroubleShootingUrl(camera.id!).then((rsp: LambdaResponse) => {
      if (rsp.statusCode !== 200 || !rsp.body) {
        this.errorHandler.handleError(rsp);
        return;
      } else {
        const creds = JSON.parse(rsp.body);
        return new CameraAccessDetails(creds);
      }

    }).catch(e => this.errorHandler.handleError(e));
  }


  public async onGetAudioGroupsBySite(siteId: string): Promise<ObjectGroup[] | void> {
    return this.api.GroupsBySiteType(siteId, { eq: ObjectGroupType.Audio }, undefined, { archived: { ne: true } }).then((res: GroupsBySiteTypeQuery) => {
      return res.items ? res.items as unknown as ObjectGroup[] : [];
    }).catch(e => this.errorHandler.handleError(e))
  }

  public async onGetAudioCustomMessageTypesBySite(siteId: string): Promise<AudioCustomMessageType[] | void> {
    return this.api.AudioMessageTypesBySite(siteId, undefined, { archived: { ne: true } }).then((res: AudioMessageTypesBySiteQuery) => {
      return res.items ? res.items as unknown as AudioCustomMessageType[] : [];
    }).catch(e => this.errorHandler.handleError(e))
  }

  public async onListDevicesBySite(siteId: string): Promise<Camera[]> {
    let nextToken;
    const devices: Camera[] = [];
    do {
      const result: any = await this.api.CameraBySite(siteId, undefined, { archived: { ne: true } }, 20, nextToken)
        .catch(e => this.errorHandler.handleError(e));
      if (result?.items) {
        devices.push(...result.items as unknown as Camera[]);
      }
      nextToken = result?.nextToken;
    } while (nextToken);
    return devices;
  }

  public async onPlayTalkdownMessage(siteId: string, audioGroupIds: string[], deviceIds: string[],
    messageType: AudioMessageType, messageId?: string, incidentId?: string): Promise<PlayMessageResult[]> {
    return await this.api.AudioPlayMessage(messageType, siteId, deviceIds, audioGroupIds, messageId, incidentId)
      .then((res: LambdaResponse) => {
        if (res.statusCode !== 200 || !res.body) {
          this.errorHandler.handleError(res);
          return [];
        }
        const result: PlayMessageResult[] = JSON.parse(res.body);
        return result;
      }).catch(e => {
        this.errorHandler.handleError(e);
        return [];
      });
  }
}