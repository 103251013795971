import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable } from 'rxjs';
import { AG_GRID_LOCALE_EN } from 'src/assets/i18n/ag-grid/en-us';
import { AG_GRID_LOCALE_ES } from 'src/assets/i18n/ag-grid/es-419';
import { AG_GRID_LOCALE_FR } from 'src/assets/i18n/ag-grid/fr-ca';
import { AG_GRID_LOCALE_NL } from 'src/assets/i18n/ag-grid/nl';

import { LocaleService } from './locale.service';
@Injectable()
export class LanguageService {

  constructor(private localeService: LocaleService, private translate: TranslateService) { }

  public getLocalizedInput(value: string, translatedValues?: any, sanitizedValues?: any): string {
    const locale = this.localeService.locale.toLowerCase();
    let outputValue = value;
    if (typeof sanitizedValues === 'string') {
      try {
        sanitizedValues = JSON.parse(sanitizedValues);
      } catch (e) {
        // ignore
      }
    }
    if (typeof translatedValues === 'string') {
      try {
        translatedValues = JSON.parse(translatedValues);
      } catch (e) {
        // ignore
      }
    }
    if (sanitizedValues && sanitizedValues[locale]) {
      outputValue = sanitizedValues[locale];
    } else if (translatedValues && translatedValues[locale]) {
      outputValue = translatedValues[locale];
    }
    return outputValue;
  }

  public getTranslatedEventTypes(): Observable<any> {
    return forkJoin([
      this.translate.get('EventType.Suspicious'),
      this.translate.get('EventType.Loitering'),
      this.translate.get('EventType.Vandalism'),
      this.translate.get('EventType.Trespass'),
      this.translate.get('EventType.LifeInDanger'),
      this.translate.get('EventType.Emergency'),
      this.translate.get('EventType.CameraDisconnect'),
      this.translate.get('EventType.CameraReconnect'),
      this.translate.get('EventType.Disarmed'),
      this.translate.get('EventType.Misidentification'),
      this.translate.get('EventType.Uncategorized'),
      this.translate.get('EventType.MedicalDuress'),
      this.translate.get('EventType.HoldUp'),
      this.translate.get('EventType.CheckIn'),
      this.translate.get('EventType.CheckOut'),
      this.translate.get('EventType.ClockIn'),
      this.translate.get('EventType.ClockOut'),
      this.translate.get('EventType.ParkingStart'),
      this.translate.get('EventType.ParkingEnd'),
      this.translate.get('EventType.ParkingViolation'),
      this.translate.get('EventType.GateAccess'),
      this.translate.get('EventType.DoorAccess'),
      this.translate.get('EventType.TemperatureCheck'),
      this.translate.get('EventType.IDCheck'),
      this.translate.get('EventType.PPECheck'),
      this.translate.get('EventType.WorkingAloneCheck'),
      this.translate.get('EventType.WelfareCheck'),
      this.translate.get('EventType.Normal'),
      this.translate.get('EventType.ErroneousAlert'),
      this.translate.get('EventType.Other'),
      this.translate.get('EventType.VehicleBreakIn'),
      this.translate.get('EventType.DrugTrafficking'),
      this.translate.get('EventType.Assault'),
      this.translate.get('EventType.PackageDelivery'),
      this.translate.get('EventType.Muted'),
      this.translate.get('EventType.Snoozed'),
      this.translate.get('EventType.DeviceNotice'),
    ]).pipe(map((res: string[]): any => {
      // Return an object with the translated dispositions
      return {
        Suspicious: res[0],
        Loitering: res[1],
        Vandalism: res[2],
        Trespass: res[3],
        LifeInDanger: res[4],
        Emergency: res[5],
        CameraDisconnect: res[6],
        CameraReconnect: res[7],
        Disarmed: res[8],
        Misidentification: res[9],
        Uncategorized: res[10],
        MedicalDuress: res[11],
        HoldUp: res[12],
        CheckIn: res[13],
        CheckOut: res[14],
        ClockIn: res[15],
        ClockOut: res[16],
        ParkingStart: res[17],
        ParkingEnd: res[18],
        ParkingViolation: res[19],
        GateAccess: res[20],
        DoorAccess: res[21],
        TemperatureCheck: res[22],
        IDCheck: res[23],
        PPECheck: res[24],
        WorkingAloneCheck: res[25],
        WelfareCheck: res[26],
        Normal: res[27],
        ErroneousAlert: res[28],
        Other: res[29],
        VehicleBreakIn: res[30],
        DrugTrafficking: res[31],
        Assault: res[32],
        PackageDelivery: res[33],
        Muted: res[34],
        Snoozed: res[35],
        DeviceNotice: res[36],
      };
    }));
  }

  public getTranslatedDispositions(): Observable<{ [key: string]: string }> {
    return forkJoin([
      this.translate.get('EventDisposition.' + 'L0 Camera Disconnect'),
      this.translate.get('EventDisposition.' + 'L0 Camera Reconnect'),
      this.translate.get('EventDisposition.' + 'L0 Disarmed'),
      this.translate.get('EventDisposition.' + 'L0 Erroneous Alert'),
      this.translate.get('EventDisposition.' + 'L0 Misidentification'),
      this.translate.get('EventDisposition.' + 'L0 Muted'),
      this.translate.get('EventDisposition.' + 'L0 Other'),
      this.translate.get('EventDisposition.' + 'L0 Package Delivery'),
      this.translate.get('EventDisposition.' + 'L0 Snoozed'),
      this.translate.get('EventDisposition.' + 'L0 Uncategorized'),
      this.translate.get('EventDisposition.' + 'L1 Check In'),
      this.translate.get('EventDisposition.' + 'L1 Check Out'),
      this.translate.get('EventDisposition.' + 'L1 Clock In'),
      this.translate.get('EventDisposition.' + 'L1 Clock Out'),
      this.translate.get('EventDisposition.' + 'L1 Door Access'),
      this.translate.get('EventDisposition.' + 'L1 Gate Access'),
      this.translate.get('EventDisposition.' + 'L1 ID Check'),
      this.translate.get('EventDisposition.' + 'L1 Normal'),
      this.translate.get('EventDisposition.' + 'L1 Parking End'),
      this.translate.get('EventDisposition.' + 'L1 Parking Start'),
      this.translate.get('EventDisposition.' + 'L1 PPE Check'),
      this.translate.get('EventDisposition.' + 'L1 Temperature Check'),
      this.translate.get('EventDisposition.' + 'L1 Welfare Check'),
      this.translate.get('EventDisposition.' + 'L1 Working Alone Check'),
      this.translate.get('EventDisposition.' + 'L2 Device Notice'),
      this.translate.get('EventDisposition.' + 'L2 Loitering'),
      this.translate.get('EventDisposition.' + 'L2 Parking Violation'),
      this.translate.get('EventDisposition.' + 'L2 Suspicious'),
      this.translate.get('EventDisposition.' + 'L3 Assault'),
      this.translate.get('EventDisposition.' + 'L3 Drug Trafficking'),
      this.translate.get('EventDisposition.' + 'L3 Emergency'),
      this.translate.get('EventDisposition.' + 'L3 Trespass'),
      this.translate.get('EventDisposition.' + 'L3 Vandalism'),
      this.translate.get('EventDisposition.' + 'L3 Vehicle Break In'),
      this.translate.get('EventDisposition.' + 'L4 Fire'),
      this.translate.get('EventDisposition.' + 'L4 Hold Up'),
      this.translate.get('EventDisposition.' + 'L4 Life In Danger'),

    ]).pipe(map((res: string[]): { [key: string]: string } => {
      // Return an object with the translated dispositions
      return {
        'L0 Camera Disconnect': res[0],
        'L0 Camera Reconnect': res[1],
        'L0 Disarmed': res[2],
        'L0 Erroneous Alert': res[3],
        'L0 Misidentification': res[4],
        'L0 Muted': res[5],
        'L0 Other': res[6],
        'L0 Package Delivery': res[7],
        'L0 Snoozed': res[8],
        'L0 Uncategorized': res[9],
        'L1 Check In': res[10],
        'L1 Check Out': res[11],
        'L1 Clock In': res[12],
        'L1 Clock Out': res[13],
        'L1 Door Access': res[14],
        'L1 Gate Access': res[15],
        'L1 ID Check': res[16],
        'L1 Normal': res[17],
        'L1 Parking End': res[18],
        'L1 Parking Start': res[19],
        'L1 PPE Check': res[20],
        'L1 Temperature Check': res[21],
        'L1 Welfare Check': res[22],
        'L1 Working Alone Check': res[23],
        'L2 Device Notice': res[24],
        'L2 Loitering': res[25],
        'L2 Parking Violation': res[26],
        'L2 Suspicious': res[27],
        'L3 Assault': res[28],
        'L3 Drug Trafficking': res[29],
        'L3 Emergency': res[30],
        'L3 Trespass': res[31],
        'L3 Vandalism': res[32],
        'L3 Vehicle Break In': res[33],
        'L4 Fire': res[34],
        'L4 Hold Up': res[35],
        'L4 Life In Danger': res[36],
      };
    }));
  }

  public getTranslateAnalyticTypes(): Observable<any> {
    return forkJoin([
      this.translate.get('CameraEventObjectType.Car'),
      this.translate.get('CameraEventObjectType.Animal'),
      this.translate.get('CameraEventObjectType.Person'),
      this.translate.get('CameraEventObjectType.Boat'),
      this.translate.get('CameraEventObjectType.Luggage'),
      this.translate.get('CameraEventObjectType.Bicycle'),
      this.translate.get('CameraEventObjectType.HardHat'),
      this.translate.get('CameraEventObjectType.NoHardHat'),
      this.translate.get('CameraEventObjectType.Smoke'),
      this.translate.get('CameraEventObjectType.Fire'),
      this.translate.get('CameraEventObjectType.Unknown'),
      this.translate.get('CameraEventObjectType.DeviceNotice'),
    ]).pipe(map((res: string[]): any => {
      return {
        Car: res[0],
        Animal: res[1],
        Person: res[2],
        Boat: res[3],
        Luggage: res[4],
        Bicycle: res[5],
        HardHat: res[6],
        NoHardHat: res[7],
        Smoke: res[8],
        Fire: res[9],
        Unknown: res[10],
        DeviceNotice: res[11],
      }
    }));
  }

  public getAgGridKey(key: string): string {
    const locale = this.localeService.locale.toLowerCase();
    let translations = AG_GRID_LOCALE_EN;
    switch (locale) {
      case 'es-419':
        translations = AG_GRID_LOCALE_ES;
        break;
      case 'nl':
        translations = AG_GRID_LOCALE_NL;
        break;
      case 'fr-ca':
        translations = AG_GRID_LOCALE_FR;
        break;
    }
    return translations[key];
  }
}
