import {
  CameraEventObjectType,
  CameraEventTriggerType,
  Disposition,
  EventType,
  HealthCheckStatus,
  PhoneType,
  SiteContactType,
  SiteType,
  VMSSourceType
} from 'src/app/services/graphql.service';
import {
  DeviceType,
  DataEntryStatus
} from 'src/models';

export class CxIncident {
  id!: string;
  status!: string
  incidentSiteId!: string;
  incidentCustomerId!: string;
  dispositionLevel!: string;
  primaryDisposition!: string;
  dispositionList!: string[];
  closedTime?: Date;
  resolvedTime?: Date;
  expectedResolvedTime!: Date;
  createdAt!: Date;
  hasShareExternal?: boolean;
  resolution?: string;
  resolutionTranslated?: any;
  resolutionSanitized?: any;
  events!: CxEvent[];
  site!: CxSite;
  customer!: CxCustomer;
  monitoringCenterId!: string;
  thumbnails?: { displayName: string, url: string }[];
  timeline!: CxIncidentTimeline[];
  shareCodes?: CxIncidentShareCode[];

  constructor(init?: Partial<CxIncident>) {
    Object.assign(this, init);
  }
}

export class CxEvent {
  id!: string
  vmsEventId?: string;
  vmsNotes?: string;
  vmsNotesTranslated?: any;
  vmsNotesSanitized?: any;
  disposition!: string;
  eventType!: string;
  thumbnailFilename?: string;
  triggerType?: CameraEventTriggerType;
  objectsFound?: CameraEventObjectType[]
  incidentId!: string;
  incidentPriority!: number;
  cameraEventCameraId!: string;
  siteId!: string;
  customerId!: string;
  startTime!: Date;
  endTime?: Date;
  resolvedTime?: Date;
  instantaneous?: boolean
  cameraEventUrl!: string
  cameraEventUrlPublic!: string;
  createdAt!: Date;
  vmsSourceType?: VMSSourceType;
  secondaryEvents?: CxSecondaryEvent[];
  camera!: CxDevice;
  ackTimestamp?: Date;

  constructor(init?: Partial<CxEvent>) {
    Object.assign(this, init);
  }
}

export class CxSecondaryEvent {
  id!: string;
  vmsNotes?: string;
  vmsNotesTranslated?: any;
  vmsNotesSanitized?: any;
  disposition!: Disposition;
  eventType!: EventType;
  vmsSourceType!: VMSSourceType;
  ackTimestamp!: Date;

  constructor(init?: Partial<CxSecondaryEvent>) {
    Object.assign(this, init);
  }
}

export class CxDevice {
  id!: string;
  name!: string;
  deviceType!: DeviceType;
  status!: DataEntryStatus;
  vmsCameraName!: string;
  description?: string;
  descriptionTranslated?: any;
  descriptionSanitized?: any;
  vmsDescription?: string;
  criticalNotes?: string;
  criticalNotesTranslated?: any;
  criticalNotesSanitized?: any;
  latitude?: number;
  longitude?: number;
  vmsCameraId!: string;
  vmsCustomerId!: string;
  archived?: boolean;
  zoneNumber?: string;
  zoneDescription?: string;
  zoneDescriptionTranslated?: any;
  zoneDescriptionSanitized?: any;
  isOnvif?: boolean;
  videoStreamState?: string;
  vmsConnect?: boolean;
  deviceUrlOnline?: boolean;
  isP2P?: boolean;
  isAnalyticsEnabled?: boolean;
  isMotionEnabled?: boolean;
  testEventReceived?: boolean;
  healthCheckEnabled?: boolean;
  healthCheckStatus?: HealthCheckStatus;
  baselineThumbnailFilename?: string;
  currentThumbnailFilename?: string;
  passingSceneDetection = false;
  hasSpeaker?: boolean;
  hasMic?: boolean;
  monitoringCenterId!: string;
  customerId!: string;
  siteId!: string;

  constructor(init?: Partial<CxDevice>) {
    Object.assign(this, init);
  }
}

export class CxSite {
  id!: string;
  name!: string;
  customerId!: string;
  monitoringCenterId!: string;
  status!: DataEntryStatus;
  externalCriticalNotes?: string;
  externalCriticalNotesTranslated?: any;
  externalCriticalNotesSanitized?: any;
  archived?: boolean;
  timezone!: string;
  type!: SiteType;
  alarmsCentralStation?: string;
  alarmsCentralStationPhone?: string;
  alarmsCentralStationPhoneExt?: string;
  alarmsVerbalPasscode?: string;
  alarmsCSID?: string;
  alarmsAR?: string;
  alarmsNotes?: string;
  alarmsNotesTranslated?: any;
  alarmsNotesSanitized?: any;
  alarmsPermitNum?: string;
  alarmsPermitExpires?: Date;
  hasSiteMap?: boolean
  address!: CxAddress;
  contacts!: CxContact[];
  cameras!: CxDevice[];

  constructor(init?: Partial<CxSite>) {
    Object.assign(this, init);
  }
}

export class CxAddress {
  lineOne!: string;
  lineTwo?: string;
  city!: string;
  state!: string;
  zipCode!: string;
  country!: string;

  constructor(init?: Partial<CxAddress>) {
    Object.assign(this, init);
  }
}

export class CxContact {
  name!: string;
  type?: SiteContactType[];
  email?: string;
  priority!: number;
  archived?: boolean;
  notes?: string;
  notesTranslated?: any;
  notesSanitized?: any;
  phones?: CxContactPhone[];

  constructor(init?: Partial<CxContact>) {
    Object.assign(this, init);
  }
}

export class CxContactPhone {
  type?: PhoneType;
  'number'?: string;
  ext?: string;

  constructor(init?: Partial<CxContactPhone>) {
    Object.assign(this, init);
  }
}

export class CxCustomer {
  id!: string;
  name!: string;
  externalCriticalNotes?: string;
  externalCriticalNotesTranslated?: any;
  externalCriticalNotesSanitized?: any;
}

export class CxIncidentTimeline {
  time!: Date;
  action!: CxIncidentTimelineAction;
  message!: string;
}

export enum CxIncidentTimelineAction {
  CameraEvent = 'CameraEvent',
  ActionItem = 'ActionItem',
  Resolution = 'Resolution',
  Note = 'Note'
}

export class CxIncidentShareCode {
  code!: string;
  expiration!: Date;
}