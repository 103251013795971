export abstract class GlobalStrings {
  public static readonly LS_MONITORING_CENTER = 'local-security';
  public static readonly LOCAL_SECURITY = 'Local Security';
  public static readonly NA_PHONE_NUMBER_VALIDATOR = '^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$';
  public static readonly WHOLE_NUMBER_VALIDATOR = '[1-9][0-9]*';
  public static readonly INTL_PHONE_NUMBER_VALIDATOR
    = new RegExp('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})');
  public static readonly ALL_MONITORING_CLIENTS = 'all';
  public static readonly URL_VALIDATOR
    = new RegExp(/^[A-Za-z][A-Za-z\d.+-]*:\/\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/);
  public static readonly URL_WITH_SUBDOMAIN_NO_HTTP = new RegExp(/^([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/);
  public static readonly RSPNDR_TENANT_ID = 'localsec';

  // install-pictures constants
  public static readonly FRONT_PLACEHOLDER = '/assets/front.svg';
  public static readonly LEFT_PLACEHOLDER = '/assets/left.svg';
  public static readonly RIGHT_PLACEHOLDER = '/assets/right.svg';
  public static readonly OPTIONAL_PLACEHOLDER = '/assets/upload.svg';
  public static readonly FRONT_IMAGE_KEY = 'front.jpg';
  public static readonly LEFT_IMAGE_KEY = 'left.jpg';
  public static readonly RIGHT_IMAGE_KEY = 'right.jpg';
  public static readonly OPTIONAL_1_IMAGE_KEY = 'optional_1.jpg';
  public static readonly OPTIONAL_2_IMAGE_KEY = 'optional_2.jpg';
  public static readonly OPTIONAL_3_IMAGE_KEY = 'optional_3.jpg';
  public static readonly OPTIONAL_4_IMAGE_KEY = 'optional_4.jpg';
  public static readonly OPTIONAL_5_IMAGE_KEY = 'optional_5.jpg';

  // auth constants
  public static readonly AUTH_COGNITO_IDENTITY_SERVICE_PROVIDER = 'CognitoIdentityServiceProvider';
  public static readonly AUTH_LAST_AUTH_USER = 'LastAuthUser';
  public static readonly AUTH_LAST_OAUTH = 'last-oauth';
  public static readonly AUTH_COGNITO_ACCESS_TOKEN = 'accessToken'
  public static readonly AUTH_COGNITO_ID_TOKEN = 'idToken'
  public static readonly AUTH_COGNITO_REFRESH_TOKEN = 'refreshToken'
  public static readonly AUTH_COGNITO_USER_DATA = 'userData';
  public static readonly AUTH_COGNITO_CLOCK_DRIFT = 'clockDrift';
  public static readonly AUTH_EXTERNAL_USER_GROUP = 'ExternalUser';
}