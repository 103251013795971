import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { LocaleService } from '../../services/locale.service';
import { onGetHtml } from '../../utils/string.utils';

@Component({
  selector: 'app-localize-user-input',
  templateUrl: './localize-user-input.component.html',
  styleUrls: ['./localize-user-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalizeUserInputComponent implements OnChanges {
  @Input() public value!: string;
  @Input() public translatedValues?: any;
  @Input() public sanitizedValues?: any;
  public showTranslateIcon = false;
  public outputValue: string;
  public originalValue: string;

  private locale: string;

  constructor(localeService: LocaleService, private ref: ChangeDetectorRef) {
    this.locale = localeService.locale.toLowerCase();
    this.outputValue = this.value;
    this.originalValue = this.value;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.translatedValues || changes.sanitizedValues) {
      this.onParseValues();
      this.onGetValueToDisplay()
    }
  }

  public onGetHtml(text?: string): string {
    return onGetHtml(text);
  }

  private onParseValues(): void {
    if (typeof this.sanitizedValues === 'string') {
      try {
        this.sanitizedValues = JSON.parse(this.sanitizedValues);
      } catch (e) {
        // ignore
      }
    }
    if (typeof this.translatedValues === 'string') {
      try {
        this.translatedValues = JSON.parse(this.translatedValues);
      } catch (e) {
        // ignore
      }
    }
  }

  private onGetValueToDisplay(): void {
    this.originalValue = this.value;
    if (this.sanitizedValues && this.sanitizedValues[this.locale]) {
      this.showTranslateIcon = true;
      this.outputValue = this.sanitizedValues[this.locale];
    } else if (this.translatedValues && this.translatedValues[this.locale]) {
      this.showTranslateIcon = true;
      this.outputValue = this.translatedValues[this.locale];
    } else {
      this.showTranslateIcon = false;
      this.outputValue = this.value;
    }

    this.ref.markForCheck();
  }

}
