import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import { APIService, IncidentCodeDetail, IncidentShareSettings, IncidentShareType } from 'src/app/services/graphql.service';
import { AuditActions } from 'src/app/shared/models/audit-actions.constants';
import { CxIncident } from 'src/app/shared/models/cx-incident.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { IncidentAuditService } from 'src/app/shared/services/incident-audit.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { UserStoreService } from 'src/app/shared/store/user-store.service';
import { environment } from 'src/environments/environment';
import { Incident } from 'src/models';

import { IncidentDetailComponent } from '../../../../incident-detail.component';

@Component({
  selector: 'app-incident-share-pg-mgmt',
  templateUrl: './incident-share-pg-mgmt.component.html',
  styleUrls: ['./incident-share-pg-mgmt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentSharePgMgmtComponent implements OnInit {
  public additionalDataForm?: UntypedFormGroup;
  public attachments: string[] = [];
  public shareSettings: IncidentShareSettings[] = [];
  public incidentCodeDetail?: IncidentCodeDetail;
  public addingOrEditing = false;
  public addingOrEditingIncidentCode = false;
  public shareType = IncidentShareType;
  public minDate = moment();
  public maxDate = moment().add(3, 'days');
  public incidentShareForm?: UntypedFormGroup;
  public incidentCodeShareForm?: UntypedFormGroup;
  public incidentCodeEnabled: boolean;

  private incident: Incident | CxIncident;
  private storageFolder!: string;
  private archivedFileName: string;

  constructor(private spinner: SpinnerService, private api: APIService,
    private errorService: ErrorService, private ref: ChangeDetectorRef,
    private fb: UntypedFormBuilder, private userStore: UserStoreService,
    private incidentAudit: IncidentAuditService, private dialogRef: MatDialogRef<IncidentDetailComponent>,
    @Inject(MAT_DIALOG_DATA) data: { incident: Incident, incidentCodeEnabled: boolean }, private errorHandler: ErrorService
  ) {
    this.archivedFileName = '.archived';
    this.incident = data.incident
    this.incidentCodeEnabled = data.incidentCodeEnabled;
  }

  public async ngOnInit(): Promise<void> {
    // Look to see if there are any existing share pages for this incident
    this.api.IncidentShareSettingsByIncident(this.incident.id).then((rsp) => {
      this.shareSettings = (rsp.items as unknown as IncidentShareSettings[]).sort((a, b) => new Date(a.expiration).getTime()
        - new Date(b.expiration).getTime());
      this.onConfigureIncidentShareSendCtrls();
      this.ref.markForCheck();
    }).catch(e => this.errorService.handleError(e));

    this.api.IncidentCodeDetailByIncident(this.incident.id).then((rsp) => {
      if (rsp && rsp.items && rsp.items.length > 0) {
        this.incidentCodeDetail = (rsp.items as unknown as IncidentCodeDetail[])[0]
        this.onConfigureIncidentCodeSendCtrls();
        this.ref.markForCheck();
      }
    }).catch(e => this.errorService.handleError(e));

    this.storageFolder = `${this.incident.monitoringCenterId}/${this.incident.incidentCustomerId}/${this.incident.id}/`;
    await this.onGetAttachments();
  }

  public onGetAttachmentsCtrl(): UntypedFormArray {
    return this.additionalDataForm?.get('attachments') as UntypedFormArray;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onEditShareSettings(shareSettings: IncidentShareSettings): void {
    this.onCreateIncidentShareForm(shareSettings);
  }

  public onShowExpireButton(index: number): boolean {
    const shareSettings = this.shareSettings[index];
    return moment(shareSettings.expiration).isAfter(moment());
  }

  public async onDisableShare(index: number): Promise<void> {
    this.spinner.loading();
    void this.incidentAudit.logAction(`Expire incident share page ${this.shareSettings[index].code}`, this.incident.id)
    await this.api.IncidentShareExpirePage(this.incident.id, this.shareSettings[index].code).then(rsp => {
      if (rsp.statusCode === 200) {
        this.shareSettings[index].expiration = rsp.body!
        this.ref.markForCheck();
      } else {
        this.errorHandler.handleError(rsp);
      }
    }).catch(e => this.errorHandler.handleError(e))
      .finally(() => this.spinner.done());
  }

  public onGetSendDisabledIncidentShare(index: number): boolean {
    const shareDetails = this.onGetSendFormCtrl().controls[index].value;
    if (shareDetails.type !== 'EMAIL' && shareDetails.type !== 'SMS') {
      return true;
    }
    if (shareDetails.type === 'EMAIL') {
      if (this.onGetSendFormCtrl().controls[index].get('email')?.invalid) {
        return true;
      }
      return false;
    }
    if (this.onGetSendFormCtrl().controls[index].get('phone')?.invalid) {
      return true;
    }
    return false;
  }

  public onGetSendDisabledIncidentCode(): boolean {
    const shareDetails = this.incidentCodeShareForm?.value;
    if (shareDetails.type !== 'EMAIL' && shareDetails.type !== 'SMS') {
      return true;
    }
    if (shareDetails.type === 'EMAIL') {
      if (this.incidentShareForm?.get('email')?.invalid) {
        return true;
      }
      return false;
    }
    if (this.incidentShareForm?.get('phone')?.invalid) {
      return true;
    }
    return false;
  }


  public onGetLink(code: string): string {
    return `${environment.sharePageBaseUrl}${code}`;
  }

  public onCreateIncidentCodeForm(): void {
    if (this.incidentCodeDetail) {
      return;
    }
    this.addingOrEditing = true;
    this.addingOrEditingIncidentCode = true;

    this.additionalDataForm = this.fb.group({
      incidentId: this.incident.id,
      externalIncidentNotes: this.fb.control(false),
      externalSiteCriticalNotes: this.fb.control(false),
      alarmStationInfo: this.fb.control(false),
      attachments: this.fb.array([])
    });

    for (const att of this.attachments) {
      this.onGetAttachmentsCtrl().controls.push(this.fb.control(null));
    }
  }

  public onCreateIncidentShareForm(item?: IncidentShareSettings): void {
    this.addingOrEditing = true;
    this.additionalDataForm = this.fb.group({
      id: item?.incidentDataToShare?.id,
      incidentId: this.incident.id,
      incidentShareSettingsId: item?.id,
      shareType: this.fb.control({
        value: item?.incidentDataToShare?.shareType ? item.incidentDataToShare?.shareType : this.shareType.OTHER,
        disabled: item?.incidentDataToShare?.shareType ? true : false
      }, Validators.required),
      date: this.fb.control({
        value: item?.expiration ? moment(item?.expiration).toDate() : moment().add(3, 'days').toDate(),
        disabled: false
      }, Validators.required),
      hour: this.fb.control({
        value: item?.expiration ? moment(item?.expiration).hours() : moment().add(3, 'days').hours(),
        disabled: false
      }, [Validators.required, Validators.min(0), Validators.max(23)]),
      minute: this.fb.control({
        value: item?.expiration ? moment(item?.expiration).minutes() : moment().add(3, 'days').minutes(),
        disabled: false
      }, [Validators.required, Validators.min(0), Validators.max(59)]),
      externalIncidentNotes: this.fb.control(item?.incidentDataToShare?.externalIncidentNotes
        ? item?.incidentDataToShare?.externalIncidentNotes : false),
      externalSiteCriticalNotes: this.fb.control(item?.incidentDataToShare?.externalSiteCriticalNotes
        ? item?.incidentDataToShare?.externalSiteCriticalNotes : false),
      externalClientCriticalNotes: this.fb.control(item?.incidentDataToShare?.externalClientCriticalNotes
        ? item?.incidentDataToShare?.externalClientCriticalNotes : false),
      completedActionItems: this.fb.control(item?.incidentDataToShare?.completedActionItems
        ? item?.incidentDataToShare?.completedActionItems : false),
      alarmStationInfo: this.fb.control(item?.incidentDataToShare?.alarmStationInfo
        ? item?.incidentDataToShare?.alarmStationInfo : false),
      objectsFound: this.fb.control(item?.incidentDataToShare?.objectsFound
        ? item?.incidentDataToShare?.objectsFound : false),
      customerContactInfo: this.fb.control(item?.incidentDataToShare?.customerContactInfo
        ? item?.incidentDataToShare?.customerContactInfo : false),
      videoFeedUrls: this.fb.control(item?.incidentDataToShare?.videoFeedUrls
        ? item?.incidentDataToShare?.videoFeedUrls : false),
      // modifiedBy: this.userStore.user?.username,
      attachments: this.fb.array([])
    });

    for (const att of this.attachments) {
      this.onGetAttachmentsCtrl().controls.push(this.fb.control(
        !!item && !!item.incidentDataToShare && !!item.incidentDataToShare.attachmentsList
        && item.incidentDataToShare.attachmentsList.length > 0
        && item.incidentDataToShare.attachmentsList?.includes(att),
      ));
    }
  }

  public onCancelIncidentShareForm(): void {
    this.additionalDataForm = undefined;
    this.addingOrEditing = false;
    this.addingOrEditingIncidentCode = false;
  }

  public async onSendShareLinkIncidentShare(index: number): Promise<void> {
    const shareDetails = this.onGetSendFormCtrl().controls[index].value;
    let to;
    if (shareDetails.type === 'EMAIL') {
      to = shareDetails.email;
    } else {
      to = shareDetails.phone;
    }
    this.spinner.loading();
    void this.incidentAudit.logAction(`${AuditActions.SEND} ${this.onGetLink(this.shareSettings[index].code)} to ${to}`, this.incident.id);
    await this.api.IncidentShareSendLink(to, this.onGetLink(this.shareSettings[index].code), this.incident.id, shareDetails.type)
      .then((rsp) => {
        if (rsp.statusCode !== 200) {
          this.errorHandler.handleError(rsp);
        } else {
          this.onGetSendFormCtrl().controls[index].reset();
        }
      }).catch(e => this.errorHandler.handleError(e))
      .finally(() => this.spinner.done());
  }

  public async onSendShareLinkIncidentCode(): Promise<void> {
    const shareDetails = this.incidentCodeShareForm?.value;
    let to;
    if (shareDetails.type === 'EMAIL') {
      to = shareDetails.email;
    } else {
      to = shareDetails.phone;
    }
    this.spinner.loading();
    void this.incidentAudit.logAction(`${AuditActions.SEND} ${this.onGetLink(this.incidentCodeDetail!.publicCode!)} to ${to}`,
      this.incident.id);
    await this.api.IncidentShareSendLink(to, this.incidentCodeDetail!.shareUrl!, this.incident.id, shareDetails.type)
      .then((rsp) => {
        if (rsp.statusCode !== 200) {
          this.errorHandler.handleError(rsp);
        } else {
          this.incidentCodeShareForm?.reset();
        }
      }).catch(e => this.errorHandler.handleError(e))
      .finally(() => this.spinner.done());
  }

  public onSaveForm(): void {
    if (this.addingOrEditingIncidentCode) {
      this.onSaveIncidentCodeForm();
    } else {
      this.onSaveIncidentShareForm();
    }
  }

  public onGetSendFormCtrl(): UntypedFormArray {
    return this.incidentShareForm?.get('sendSettings') as UntypedFormArray;
  }

  private onSaveIncidentCodeForm(): void {
    this.spinner.loading();
    const data = this.additionalDataForm?.getRawValue();
    let idx = 0;
    const toAttach = this.onGetAttachmentsCtrl().controls
      .map(x => ({ filename: this.attachments[idx++], selected: x.value }))
      .filter(x => x.selected)
      .map(x => x.filename);
    delete data.attachments;
    data.attachmentsList = toAttach;

    void this.incidentAudit.logAction(`${AuditActions.CREATE} incident code form`, this.incident.id);
    this.api.IncidentCodeCreate(data).then(rsp => {
      if (rsp.statusCode !== 200 || !rsp.body) {
        this.errorHandler.handleError(rsp);
      } else {
        this.incidentCodeDetail = JSON.parse(rsp.body);
        this.onConfigureIncidentCodeSendCtrls();
        this.additionalDataForm = undefined;
        this.addingOrEditing = false;
        this.addingOrEditingIncidentCode = false;
        this.ref.markForCheck();
      }
    }).catch(e => this.errorHandler.handleError(e))
      .finally(() => this.spinner.done());
  }

  private onSaveIncidentShareForm(): void {
    this.spinner.loading();
    const data = this.additionalDataForm?.getRawValue();
    let expireDay = moment(new Date(data.date));
    expireDay = expireDay.set({
      hour: data.hour,
      minute: data.minute,
    });
    delete data.date;
    delete data.hour;
    delete data.minute;
    let idx = 0;
    const toAttach = this.onGetAttachmentsCtrl().controls
      .map(x => ({ filename: this.attachments[idx++], selected: x.value }))
      .filter(x => x.selected)
      .map(x => x.filename);
    delete data.attachments;
    data.attachmentsList = toAttach;
    if (!data.id) {
      delete data.id;
      delete data.incidentShareSettingsId;
      void this.incidentAudit.logAction(`${AuditActions.CREATE} incident share form`, this.incident.id);
      this.api.IncidentShareCreate(data, expireDay.toISOString()).then(rsp => {
        if (rsp.statusCode !== 200 || !rsp.body) {
          this.errorHandler.handleError(rsp);
        } else {
          this.shareSettings.push(JSON.parse(rsp.body));
          this.shareSettings = this.shareSettings.sort((a, b) => new Date(a.expiration).getTime()
            - new Date(b.expiration).getTime());
          this.onConfigureIncidentShareSendCtrls();
          this.additionalDataForm = undefined;
          this.addingOrEditing = false;
          this.ref.markForCheck();
        }
      }).catch(e => this.errorHandler.handleError(e))
        .finally(() => this.spinner.done());
    } else {
      void this.incidentAudit.logAction(`${AuditActions.EDIT} incident share form.`, this.incident.id);
      const incidentShareSettingsId = data.incidentShareSettingsId;
      delete data.incidentShareSettingsId;
      this.api.IncidentShareUpdate(data, incidentShareSettingsId, expireDay.toISOString()).then(rsp => {
        if (rsp.statusCode !== 200 || !rsp.body) {
          this.errorHandler.handleError(rsp);
        } else {
          const parsedRsp = JSON.parse(rsp.body);
          const foundIdx = this.shareSettings.findIndex(x => x.id === parsedRsp.id);
          if (idx >= 0) {
            this.shareSettings[foundIdx] = parsedRsp;
          } else {
            this.shareSettings.push(parsedRsp);
          }
          this.shareSettings = this.shareSettings.sort((a, b) => new Date(a.expiration).getTime()
            - new Date(b.expiration).getTime());
          this.onConfigureIncidentShareSendCtrls();
          this.additionalDataForm = undefined;
          this.addingOrEditing = false;
          this.ref.markForCheck();
        }
      }).catch(e => this.errorHandler.handleError(e))
        .finally(() => this.spinner.done());
    }
  }

  private async onGetAttachments(): Promise<void> {
    let list = await Storage.list(this.storageFolder);
    if (list.filter((a: any) => a.key.endsWith(this.archivedFileName)).length > 0) {
      const archivedFile = await Storage.get(`${this.storageFolder}${this.archivedFileName}`, { download: true });
      let archivedAttachments: string[] = [];
      if (archivedFile) {
        archivedAttachments = (await (archivedFile as any).Body.text()).split(',');
      }
      list = list.filter((a: any) => archivedAttachments.indexOf(a.key) === -1
        && !a.key.endsWith(this.archivedFileName));
    }
    for (const item of list) {
      if (!item.key!.endsWith('mp3')) {
        const itemName = this.getAttachmentName(item.key!);
        this.attachments.push(itemName);
      }
    }
    this.ref.markForCheck();
  }

  private getAttachmentName(key: string): string {
    return key.replace(this.storageFolder, '');
  }

  private onConfigureIncidentShareSendCtrls(): void {
    this.incidentShareForm = this.fb.group({
      sendSettings: this.fb.array([])
    });

    for (const _settings of this.shareSettings) {
      this.onGetSendFormCtrl().push(this.fb.group({
        type: this.fb.control(null),
        email: this.fb.control(null, Validators.email),
        phone: this.fb.control(null)
      }));
    }
  }

  private onConfigureIncidentCodeSendCtrls(): void {
    this.incidentCodeShareForm = this.fb.group({
      type: this.fb.control(null),
      email: this.fb.control(null, Validators.email),
      phone: this.fb.control(null)
    });
  }

}
