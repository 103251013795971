import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pop-up-confirm',
  templateUrl: './pop-up-confirm.component.html',
  styleUrls: ['./pop-up-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopUpConfirmComponent {
  public cancelText = '';
  public confirmText = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message?: string, htmlMessage?: string, title: string, cancelText: string, confirmText: string },
    private dialogRef: MatDialogRef<any>, private translate: TranslateService
  ) {
    if (!data.cancelText) {
      this.translate.get('popup.cancel').subscribe(text => this.cancelText = text);
    } else {
      this.cancelText = data.cancelText;
    }
    if (!data.confirmText) {
      this.translate.get('popup.confirm').subscribe(text => this.confirmText = text);
    } else {
      this.confirmText = data.confirmText;
    }
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

}
