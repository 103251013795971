import { Injectable } from '@angular/core';

import { APIService } from '../../services/graphql.service';
import { UserStoreService } from '../store/user-store.service';

import { AuditLoggerService } from './audit-logger.service';

@Injectable()
export class IncidentAuditService {

  constructor(private auditLogger: AuditLoggerService, private userStore: UserStoreService,
    private api: APIService) { }

  public logAction(actionDescrp: string, incidentId: string, skipAuditLog?: boolean): void {
    if (!skipAuditLog) {
      void this.auditLogger.logClickAction(actionDescrp);
    }
    void this.api.CreateIncidentActionDetail({
      incidentId,
      modifiedBy: this.userStore.user!.username,
      monitoringCenterId: this.userStore.monitoringCenterId ?? 'cx-user',
      description: actionDescrp
    });
  }
}
